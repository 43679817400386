// App.vue
// ToDo:
// - Datenschutz Cookie Hinweis
// - Login nur für Vereinsmitglieder
// - News für Vereinsmitglieder
// - Vereinswebsite in internen Bereich
<template>
  <v-app id="metalcrew">
    <notification />
    <navigation v-if="isAuthenticated" />
    <!-- START MOBILE MENU - ToDo: Own module -->
    <v-navigation-drawer v-model="drawer" app clipped>
      <v-list nav dense>
        <v-list-item-group
          v-for="(item, i) in navigation"
          :key="i"
          :to="item.url"
          no-action="true"
        >
          <v-list-group
            :href="localeLink(item.link, item.linkEN)"
            :target="item.target"
            v-if="item.submenu"
            @click="console.log('hi')"
            :append-icon="item.icon"
          >
            <!-- v-if="gotALink(item)" -->

            <template v-slot:activator>
              <v-list-item-title>
                {{ $t("menu." + item.text) }}</v-list-item-title
              >
            </template>

            <v-list-item :to="item.url">
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon v-text="item.icon"></v-icon>
                  {{ $t("menu." + item.mobile) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-for="(sub, index) in item.submenu"
              :key="index"
              :to="sub.url"
            >
              <v-list-item-content>
                <v-list-item-title>
                  <v-icon v-text="sub.icon"></v-icon>
                  {{ $t("menu." + sub.text) }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item
            v-else
            :to="item.url"
            :href="localeLink(item.link, item.linkEN)"
            :target="item.target"
          >
            <v-list-item-content>
              <v-list-item-title>
                <v-icon v-text="item.icon" v-if="item.icon"></v-icon>
                {{ $t("menu." + item.text) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- END MOBILE MENU-->
    <v-app-bar app clipped-left v-if="isAuthenticated">
      <v-app-bar-nav-icon
        class="d-md-none"
        v-if="isAuthenticated"
        aria-label="Menü"
        @click.stop="setNavigationBar()"
      >
        <v-icon>more_vert</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="orange--text"
        >MetalCrew Kultur e.V. {{ $t("menu.login") }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <div class="d-none d-md-block" v-if="!isAuthenticated">
        <!-- btn fab-->
        <v-btn
          dark
          v-for="(item, i) in navigation"
          :key="i"
          :to="item.url"
          :title="item.text"
          :href="item.link"
          :target="item.target"
          class="ml-4"
        >
          <v-icon v-text="item.icon"></v-icon>
          {{ $t("menu." + item.text_short) }} }}
        </v-btn>
      </div>
      <v-btn fab dark @click="refreshData()" v-if="isAuthenticated">
        <v-icon dark>refresh</v-icon>
      </v-btn>
      <v-btn fab dark @click="logout()" v-if="isAuthenticated">
        <v-icon dark>logout</v-icon>
      </v-btn>
      <v-progress-linear
        :active="this.$store.state.loading"
        :indeterminate="this.$store.state.loading"
        absolute
        bottom
        color="deep-purple accent-4"
        v-on="on"
      ></v-progress-linear>
    </v-app-bar>

    <v-container fluid class="mt-2" v-if="!isAuthenticated">
      <v-row v-if="!isAuthenticated" justify="space-around">
        <v-col
          align="right"
          vertical-align="middle"
          class="offset-lg-1 pl-5"
          cols="8"
          sm="4"
          lg="2"
          xl="3"
          xxl="4"
        >
          <router-link :to="{ name: 'home' }">
            <v-img
              contain
              height="150px"
              position="center"
              src="@/assets/logo_white.svg"
              title="MetalCrew Kultur e.V."
              alt="MetalCrew Kultur e.V."
            />
          </router-link>
        </v-col>
        <v-col
          align="left"
          vertical-align="middle"
          class="d-none d-lg-block mt-5 mt-lg-6 mt-xl-7"
        >
          <!--
          <v-btn
            dark
            v-for="(item, i) in navigation"
            :key="i"
            :to="item.url"
            :title="item.text"
            :href="item.link"
            :target="item.target"
            class="ml-4 mt-4"
          >
            <v-icon v-text="item.icon"></v-icon>
            {{ item.text_short }}
          </v-btn>-->
          <langSwitcher class="mt-6 ml-6 ml-lg-6 ml-xl-0 mr-xl-10" />
          <v-menu
            v-for="(item, i) in navigation"
            :key="i"
            offset-y
            open-on-hover
            class="mt-8"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-4 mt-6"
                :class="activeCheck(i)"
                :color="item.color"
                dark
                v-bind="attrs"
                v-on="on"
                :to="item.url"
                :href="localeLink(item.link, item.linkEN)"
                :target="item.target"
              >
                <v-icon class="mr-1" v-text="item.icon"></v-icon>
                {{ $t("menu." + item.text_short) }}
              </v-btn>
            </template>
            <v-list
              v-for="(sub, index) in item.submenu"
              :key="index"
              style="padding: 0px"
            >
              <v-list-item v-if="gotALink(sub)" :to="sub.url">
                <v-list-item-title>
                  <v-icon v-text="sub.icon" style="font-size: 17px"></v-icon>
                  {{ $t("menu." + sub.text) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col
          align="center"
          vertical-align="middle"
          class="d-lg-none"
          style="padding-top: 80px"
          sm="3"
          lg="4"
          md="4"
          cols="4"
        >
          <langSwitcher class="mr-2 mb-1 mr-sm-12" />
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="white"
                v-bind="attrs"
                v-on="on"
                @click="drawer = !drawer"
              >
                <v-icon size="55">mdi-menu</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
    <v-main>
      <router-view />

      <v-footer
        style="background-color: #121212"
        class="text-white text-center d-flex flex-column"
        v-if="!isAuthenticated"
      >
        <h2 class="headline font-weight-bold mb-5" v-if="!isFestival()">
          {{ $t("footer.organize") }}
        </h2>
        <v-row justify="center" class="pb-6" v-if="!isFestival()">
          <router-link
            v-for="(link, i) in importantLinks"
            :key="i"
            :to="link.to"
            class="subheading mx-3"
          >
            <v-img :src="link.img" :alt="link.text" width="200px"></v-img>
          </router-link>
        </v-row>
        <div class="white--text">
          {{ $t("footer.desc") }}
        </div>
        <GNewsletter class="mt-6"></GNewsletter>
        <div>
          <v-btn
            v-for="(icon, i) in social"
            :key="i"
            :href="icon.url"
            :title="icon.title"
            icon
            large
            target="_blank"
          >
            <v-icon>{{ icon.icon }}</v-icon>
          </v-btn>
        </div>
        <div class="white--text pt-1">
          Copyright © {{ new Date().getFullYear() }}
          <a href="https://www.rammrocker.de" target="_blank">Rammrocker</a>
        </div>
        <div class="pb-6">
          <a
            :href="
              localeLink(
                'https://www.metalcrew.de/community/datenschutzerklaerung/',
                'https://community.metalcrew.net/privacy-policy/'
              )
            "
            target="_blank"
            >{{ $t("general.dataPolicy") }}</a
          >
          |
          <a
            :href="
              localeLink(
                'https://community.metalcrew.de/legal-notice/',
                'https://community.metalcrew.net/legal-notice/'
              )
            "
            target="_blank"
            >{{ $t("general.imprint") }}</a
          >
        </div>
        <!-- Not needed. Zitat Marcel Werk: https://www.woltlab.com/community/thread/293839-cookie-banner/
        <cookie-law theme="dark-lime" :buttonText="$t('cookie.accept')">
      
  <div slot="message">
    {{ $t("cookie.details") }} <a :href='localeLink("https://community.metalcrew.de/privacy-policy/", "https://community.metalcrew.net/privacy-policy/")'
          target="_blank">{{
              $t("general.dataPolicy")
          }}</a>
  </div>

</cookie-law>-->
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import GNewsletter from "@/components/global/g-newsletter";
import axios from "axios";
import navigation from "@/components/global/g-navigation";
import notification from "@/components/global/g-snackbar";
import langSwitcher from "@/components/global/g-langSwitcher";
//import CookieLaw from 'vue-cookie-law'

export default {
  components: {
    navigation: navigation,
    notification: notification,
    GNewsletter: GNewsletter,
    langSwitcher: langSwitcher,
    //CookieLaw,
  },
  props: {
    source: String,
  },
  data: () => ({
    drawer: false,
    group: null,
    items: [
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me 2" },
    ],
    navigation: [
      /*{
        icon: "mdi-flag",
        text: "Sprache",
        text_short: "",
        link: "{{ $i18n.locale = 'en' }}",
        target: "_blank",
      },*/
      {
        text: "metalCrew",
        text_short: "metalCrew",
        url: { name: "home", path: "/" },
        mobile: "overview",
        submenu: [
          { text: "leader", url: { name: "leader" } },
          { text: "statute", url: { name: "statute" } },
          { text: "donation", url: { name: "donation" } },
          { text: "extremism", url: { name: "extremism" } },
          { text: "aboutUs", url: { name: "aboutUs" } },
          { text: "login", url: { name: "login" } },
        ],
      },
      /*     { icon: 'mdi-crown', text: 'Vorstand', text_short: 'Vorstand', url: '/vorstand' },
           { icon: 'mdi-information', text: 'Über uns', text_short: 'Info', url: '/ueber-uns' },*/
      {
        text: "community",
        text_short: "community",
        link: "https://community.metalcrew.de",
        linkEN: "https://community.metalcrew.net",
        target: "_blank",
      },
      /*{ icon: 'mdi-comment-multiple', text: 'Community-Forum', text_short: 'Forum', link: 'https://metalcrew.de/f', target: '_blank' },*/
      {
        /* icon: 'mdi-music',*/ text: "festivals",
        text_short: "festivals",
        url: { name: "festivals" },
        mobile: "overview",
        submenu: [
          /*{ text: 'Festivals', url: '/festival' },*/ {
            text: "crewsade",
            url: { name: "crewsade-of-metal" },
          },
          { text: "mcLive", url: { name: "metalcrew-live" } },
          { text: "openPark", url: { name: "open-park-pfahofa" } },
        ],
      },
      {
        /*icon: 'mdi-calendar',*/ text: "events",
        text_short: "events",
        url: { name: "event" },
        mobile: "coming",
        submenu: [
          { text: "oldEvents", url: { name: "oldEvents" } },
          { text: "raffles", url: { name: "raffles" } },
          { text: "support", url: { name: "eventSupport" } },
        ],
      },
      {
        icon: "mdi-shopping",
        text: "shop",
        text_short: "shop",
        link: "https://shop.metalcrew.de/",
        linkEN: "https://shop.metalcrew.net/",
        target: "_blank",
      },
      {
        /* icon: 'mdi-account-multiple',*/ text: "beMember",
        text_short: "beMember",
        url: { name: "membership" },
        color: "orange",
      },
    ],
    importantLinks: [
      {
        text: "Crewsade of Metal",
        to: "/crewsade-of-metal",
        img: "/img/crewsade_xs.png",
      },
      {
        text: "MetalCrew Live",
        to: "/metalcrew-live",
        img: "/img/mc_live_xs.png",
      },
      {
        text: "Open Park PFA'HOFA",
        to: "/open-park-pfahofa",
        img: "/img/openpark_xs.png",
      },
    ],
    social: [
      {
        icon: "mdi-mail",
        title: "E-Mail",
        url: "mailto:verein@metalcrew.de",
      },
      {
        icon: "mdi-discord",
        title: "Discord Server",
        url: "https://discord.com/invite/a3tJ9nwUZh",
      },
      {
        icon: "mdi-steam",
        title: "Steam Gaming",
        url: "https://steamcommunity.com/groups/MetalCrewKultur",
      },
      {
        icon: "mdi-facebook",
        title: "Facebook",
        url: "https://www.facebook.com/metalcrew.eu",
      },
      {
        icon: "mdi-instagram",
        title: "Instagram",
        url: "https://instagram.com/metalcrew.eu",
      },
      {
        icon: "mdi-twitter",
        title: "Twitter",
        url: "https://twitter.com/MetalCrewKultur",
      },
      {
        icon: "mdi-spotify",
        title: "Spotify",
        url: "https://open.spotify.com/user/ujqo9el79aeoknz6ebjav2mqi?si=02f7be83524a4131",
      },
      {
        icon: "mdi-soundcloud",
        title: "Soundcloud",
        url: "https://on.soundcloud.com/QujSWFjNPg5vQVVB7",
      },
      {
        icon: "mdi-youtube",
        title: "YouTube",
        url: "https://www.youtube.com/@metalcrew",
      },
      {
        icon: "mdi-twitch",
        title: "Twitch",
        url: "https://www.twitch.tv/metalcrew",
      },
      {
        icon: "mdi-telegram",
        title: "Telegram",
        url: "https://t.me/metalcrew",
      },
    ],
  }),
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
  },
  created() {
    axios.interceptors.request.use(
      (config) => {
        this.$store.commit("loading");
        return config;
      },
      (error) => {
        this.$store.commit("loaded");
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        this.$store.commit("loaded");
        return response;
      },
      (error) => {
        this.$store.commit("loaded");
        return Promise.reject(error);
      }
    );
  },
  methods: {
    beMember() {
      return "hi";
    },
    isFestival() {
      const path = this.$router.currentRoute.path;
      if (
        path == "/festivals" ||
        path == "/open-park-pfahofa" ||
        path == "/metalcrew-live" ||
        path == "/crewsade-of-metal"
      ) {
        return true;
      } else {
        return false;
      }
    },
    activeCheck(i) {
      if (this.navigation[i].submenu) {
        for (let i2 = 0; i2 < this.navigation[i].submenu.length; i2++) {
          if (
            this.navigation[i].submenu[i2].url.name ==
            this.$router.currentRoute.name
          ) {
            return "v-btn--active";
          }
        }
      }
      return "";
    },
    setNavigationBar() {
      this.$store.commit("toggleNavigationBar", {
        navigation: true,
      });
    },
    refreshData() {
      this.$store.commit("initLoad");
    },
    logout() {
      this.$router.push("login");
      this.$store.commit("logout");
      this.$store.commit("showNotification", {
        snackbarColor: "success",
        snackbarTitle: this.$i18n.t("login.logout"),
      });
    },
    gotALink(item) {
      return item.link || item.url;
    },
    localeLink(itemDE, itemEN) {
      if (this.$i18n.locale == "en") {
        return itemEN;
      } else {
        return itemDE;
      }
    },
    changeLocale() {
      if (this.$i18n.locale === "de") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "de";
      }
    },
    onResize() { // Hide on mobile view simulation #dev
      this.isMobile = window.innerWidth < 600;
      if (this.isMobile === false && this.drawer === true) this.drawer = false;
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
};
</script>

<style scoped>
.logo {
  width: 800px;
}

.v-btn__content i {
  font-size: 17px;
  margin-bottom: 5px;
}

.v-list-item__title i {
  font-size: 17px;
  margin-bottom: 5px;
}

.v-alert {
  padding: 0px;
}
</style>

<style>
.v-application .orange--text {
  color: #fcaf16 !important;
  caret-color: #fcaf16 !important;
}

.v-btn.v-btn--is-elevated.v-btn--has-bg.v-btn--router.theme--dark.v-size--default.orange {
  /*transform: scale(1.15); LOL */
  background-color: #fcaf16 !important;
}

.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--dark.v-size--default.orange {
  background-color: #fcaf16 !important;
}

.v-btn.v-btn--is-elevated.v-btn--has-bg.v-btn--router.theme--dark.v-size--default.orange:hover {
  /*transform: scale(1.15); LOL */
  background-color: #fdb813 !important;
}

.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--dark.v-size--default.orange:hover {
  /*transform: scale(1.15); LOL */
  background-color: #fdb813 !important;
}

.Cookie--dark-lime {
  background-color: #000 !important;
}
.Cookie--dark-lime .Cookie__button {
  background-color: #fcaf16 !important;
}
.Cookie--dark-lime div.Cookie__button:hover {
  background-color: #fdb813 !important;
}
</style>