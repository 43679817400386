<template>
  <v-container>
    <v-container style="margin-left: -0.7%; margin-bottom: -12px">
      <v-btn class="rounded-bl-0 rounded-br-0" dark :to="{ name: 'event' }">{{ $t('event.upcoming') }}</v-btn>
      <v-btn class="rounded-bl-0 rounded-br-0 v-btn--active" dark>{{ $t('event.past') }}</v-btn>
    </v-container>
    <hr />
    <div align="center" class="mt-10" v-if="loading">
      <v-col cols="6">
        {{ $t('event.loading') }}...<br><br>
        <v-progress-linear color="deep-purple accent-4" indeterminate rounded height="6"></v-progress-linear>
      </v-col>
    </div>
    <div align="center" class="mt-10" v-if="loadError" v-html="$t('event.loadingError')">
    </div>
    <v-card v-for="(event, n) in items" :key="n" class="mb-5" dark>
      <div class="d-flex flex-no-wrap justify-space-between">
        <v-avatar class="d-none d-md-block" style="height: none; min-width: 350px; width: 350px;" v-if="evenNumber(n)"
          tile>
          <v-img :src="generateImage(event.name, event.details)" style="height: 100% !important"></v-img>
        </v-avatar>
        <div>
          <v-card-title class="text-h6" :class="isCommunity(event.name)" v-text="event.name"></v-card-title>
          <v-card-subtitle class="text-h9">
            <font class="subtitle-1">{{
                makeReadDate(event.start, event.end,
                  event.isFullDay)
            }}</font><br>
            <font class="subtitle-2">{{ event.location }}</font>
          </v-card-subtitle>
          <v-card-text v-html="cutEventDetail(event.details)"></v-card-text>
          <v-card-text>
            <GBtnGen :href="markupEventDetails(event.details)" disabled="true"
              v-if="markupEventDetails(event.details)" :text="makeButtonText(event.details)" width="250px">
            </GBtnGen>
            <GBtnGen :href="makeCalenderLink(event.eventID)"  disabled="true"
              v-if="markupEventDetails(event.details)" :text="$t('calendar.addTo')" width="250px">
            </GBtnGen>
          </v-card-text>
        </div>
        <v-avatar class="d-none d-md-block" style="height: none; min-width: 350px; width: 350px;" v-if="!evenNumber(n)"
          tile>
          <v-img :src="generateImage(event.name, event.details)" style="height: 100% !important"></v-img>
        </v-avatar>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import GBtnGen from "@/components/global/g-btnGen";

export default {
  components: {
    GBtnGen: GBtnGen,
  },
  data() {
    return {
      items: [],
      loading: true,
      loadError: false,
      upcoming: true,
    };
  },
  methods: {
    makeButtonText(Text) {
      let discord = Text.toUpperCase().indexOf("DISCORD.COM");
      let member = Text.toUpperCase().indexOf("METALCREW.DE/ASSOCIATION");
      let mailTo = Text.toUpperCase().indexOf("MAILTO:");
      let cloud = Text.toUpperCase().indexOf("CLOUD.METALCREW.DE");
      let eventim = Text.toUpperCase().indexOf("eventim.com");
      let eventimLight = Text.toUpperCase().indexOf("eventim-light.com");
      let mcTicket = Text.toUpperCase().indexOf("ticket.metalcrew");
      if (discord != -1) {
        return this.$i18n.t('event.inChat');
      } else if (member != -1) {
        return this.$i18n.t('event.inMemberarea');
      } else if (mailTo != -1 || cloud != -1) {
        return this.$i18n.t('event.registerEvent');
      } else if (eventim != -1 || eventimLight != -1 || mcTicket != -1) {
        return this.$i18n.t('event.buyTicket');
      } else {
        return this.$i18n.t('event.moreInfo');
      }
    },

    isCommunity(Text) {
      let index = Text.toUpperCase().indexOf("COMMUNITY");
      if (index != -1) {
        return;
      } else {
        return "orange--text";
      }
    },

    cutEventDetail(Text) {
      let firstImgIndex = Text.indexOf("<img src=\"");
      if (firstImgIndex != -1) {
        // let firstImg = Details.indexOf('<img src="');
        let lastPart = Text.substr(firstImgIndex + 10);
        let endImg = lastPart.indexOf("<br>") + 4;//lastPart.indexOf('"/>');
        let output = lastPart.substr(endImg);
        let noBR = false;
        while (noBR == false) {
          endImg = output.indexOf("<br>") + 4;
          if (endImg == 4) {
            output = lastPart.substr(endImg);
          }
          else noBR = true;
        }
        if (output.indexOf("/smilies/") != -1) {
          return Text;
        }
        Text = output;
      }
      let lastLinkIndex = Text.lastIndexOf("<a");
      if (lastLinkIndex != -1) {
        let lastPart = Text.substr(0, lastLinkIndex);
        return lastPart.replace("<a", "<a target='_blank'");
      } else {
        return Text;
      }
    },
    markupEventDetails(Text) {
      let lastLinkIndex = Text.lastIndexOf("href");
      let linkStart = Text.substr(lastLinkIndex + 6);
      let lastLinkIndexA = linkStart.indexOf('"');
      if (
        (lastLinkIndex != -1 && lastLinkIndexA != -1) ||
        this.upcoming == true
      ) {
        let lastPart = linkStart.substr(0, lastLinkIndexA);
        return this.decode(lastPart);
      } else {
        return false;
      }
    },
    makeCalenderLink(id) {
      return "https://www.metalcrew.de/community/calendar/event-export/" + id + "/";
    },
    /**
     * Converts a string to its html characters completely.
     *
     * @param {String} str String with unescaped HTML characters
     **/
    encode: function (str) {
      var buf = [];

      for (var i = str.length - 1; i >= 0; i--) {
        buf.unshift(["&#", str[i].charCodeAt(), ";"].join(""));
      }

      return buf.join("");
    },
    /**
     * Converts an html characterSet into its original character.
     *
     * @param {String} str htmlSet entities
     **/
    decode: function (str) {
      return str.replace(/&#(\d+);/g, function (match, dec) {
        return String.fromCharCode(dec);
      });
    },
    activeClass(Comp) {
      if (
        (Comp == 1 && this.upcoming == true) ||
        (Comp == 2 && this.upcoming == false)
      ) {
        return "v-btn--active";
      } else return "";
    },
    generateImage(Name, Details) {
      let firstImg = Details.indexOf(' src="');
      if (firstImg != -1) {
        //firstImg = Details.indexOf('<img src="');
        let lastPart = Details.substr(firstImg + 6);
        let endImg = lastPart.indexOf('"');
        let output = lastPart.substr(0, endImg);
        return output;
      } else {
        let upperName = Name.toUpperCase();
        if (upperName.includes("CREWSADE OF METAL")) {
          let rand = Math.floor(Math.random() * 3) + 17;
          return require("@/assets/event/crewsade-" + rand + ".jpg");
        } else if (
          upperName.includes("METAL ARMAGEDDON") ||
          upperName.includes("METALCREW LIVE")
        ) {
          let rand = Math.floor(Math.random() * 5) + 1;
          return require("@/assets/event/mc-live-" + rand + ".jpg");
        } else if (upperName.includes("OPEN PARK")) {
          let rand = Math.floor(Math.random() * 2) + 1;
          return require("@/assets/event/open-park-" + rand + ".jpg");
        } else if (upperName.includes("VOLLVERSAMMLUNG")) {
          let rand = Math.floor(Math.random() * 2) + 1;
          return require("@/assets/event/meeting-" + rand + ".jpg");
        } else if (upperName.includes("WICHTEL")) {
          let rand = Math.floor(Math.random() * 6) + 2016;
          return require("@/assets/event/wichtel-" + rand + ".jpg");
        } else if (upperName.includes("HORROR NIGHTS")) {
          let rand = Math.floor(Math.random() * 1);
          return require("@/assets/event/horror-nights-" + rand + ".jpg");
        } else if (upperName.includes("KART")) {
          let rand = Math.floor(Math.random() * 2) + 1;
          return require("@/assets/event/kart-" + rand + ".jpg");
        } else if (upperName.includes("KINO")) {
          return require("@/assets/event/kino-1.jpg");
        } else if (upperName.includes("LORD & SCHLUMPFI")) {
          return require("@/assets/event/lord-schlumpfi.png");
        } else if (
          upperName.includes("EUROPA-PARK") ||
          upperName.includes("EUROPAPARK")
        ) {
          let rand = Math.floor(Math.random() * 6) + 1;
          return require("@/assets/event/europapark-" + rand + ".jpg");
        } else if (
          upperName.includes("PEN & PAPER") ||
          upperName.includes("PEN AND PAPER")
        ) {
          let rand = Math.floor(Math.random() * 3) + 1;
          return require("@/assets/event/pen-and-paper-" + rand + ".jpg");
        } else if (upperName.includes("SCHRIFT")) {
          return require("@/assets/event/pen.jpg");
        } else if (
          upperName.includes("GARTEN") ||
          upperName.includes("CAMPING")
        ) {
          let rand = Math.floor(Math.random() * 2) + 1;
          return require("@/assets/event/camping-" + rand + ".jpg");
        } else if (upperName.includes("PILS PUB")) {
          let rand = Math.floor(Math.random() * 2) + 1;
          return require("@/assets/event/pils-pub-" + rand + ".jpg");
        } else if (upperName.includes("ZOMBRÄU")) {
          let rand = Math.floor(Math.random() * 2) + 1;
          return require("@/assets/event/zombraeu-" + rand + ".jpg");
        } else if (
          upperName.includes("SAURIER") ||
          upperName.includes("DINO")
        ) {
          let rand = Math.floor(Math.random() * 2) + 1;
          return require("@/assets/event/dino-" + rand + ".jpg");
        } else if (
          upperName.includes("ANNIVERSARY") ||
          upperName.includes("PARTY") ||
          upperName.includes("BIER")
        ) {
          let rand = Math.floor(Math.random() * 7) + 1;
          return require("@/assets/event/party-" + rand + ".jpg");
        } else {
          let rand = Math.floor(Math.random() * 8) + 1;
          return require("@/assets/event/community-" + rand + ".jpg");
        }
      }
    },
    evenNumber(Num) {
      if (Num % 2 == 0) return true;
      else return false;
    },
    generateLink(ID) {
      return "https://www.metalcrew.de/f/calendar/event/" + ID;
    },
    makeReadDate(start, end, fullDay) {
      let outStart = this.format(start, fullDay),
        outEnd = this.format(end, fullDay, true);
      if (outStart == outEnd) {
        return outStart;
      } else {
        return outStart + " " + this.$i18n.t('event.dateTo') + " " + outEnd;
      }
    },
    timezoneOffset(inputDate, end) {
      //const dateFromServer = new Date("2022-04-12 02:00:00"); // California midnight
      const dateFromServer = new Date(inputDate);
      //console.log(inputDate);
      //const serverOffset = 0; // in minutes, from that API call
      //const serverOffsetMillis = 60 * 1000 * serverOffset;
      //const localOffset = new Date().getTimezoneOffset(); // in minutes
      //const localOffsetMillis = 60 * 1000 * localOffset;
      //console.log(localOffset);
      //const localMidnight = new Date(
      //  dateFromServer.getTime() - serverOffsetMillis + localOffsetMillis
      //);
      //return localMidnight;
      //console.log(localMidnight.toString());
      const hours = dateFromServer.getHours();
      const minutes = dateFromServer.getMinutes();
      if (end == true && hours <= 2) {
        const minutesOffsetMillis = 60 * 1000 * (minutes + 1);
        const hoursOffsetMillis = 60 * 1000 * 60 * hours;
        const newDate = new Date(
          dateFromServer.getTime() - minutesOffsetMillis - hoursOffsetMillis
        );
        return newDate;
      } else return dateFromServer;
    },
    format(inputDate, fullDay, end = false) {
      //var date = new Date(inputDate);
      inputDate = inputDate.replace(/[-]/g, '/'); // Fix: Safari No Date
      var date = this.timezoneOffset(inputDate, end);
      if (!isNaN(date.getTime())) {
        // Months use 0 index.
        let baseMonth = date.getMonth() + 1,
          baseDay = date.getDate();
        let month = baseMonth < 10 ? "0" + baseMonth : baseMonth,
          day = baseDay < 10 ? "0" + baseDay : baseDay;
        // Minutes
        let baseHour = date.getHours(),
          baseMin = date.getMinutes();
        let hour = baseHour < 10 ? "0" + baseHour : baseHour,
          min = baseMin < 10 ? "0" + baseMin : baseMin;
        // Output
        if (fullDay == 1) {
          this.timezoneOffset();
          //console.log("Servus Event");
          /*console.log(
            day +
            "." +
            month +
            "." +
            date.getFullYear() +
            " - " +
            hour +
            ":" +
            min
          );*/
          return day + "." + month + "." + date.getFullYear();
        } else {
          return (
            day +
            "." +
            month +
            "." +
            date.getFullYear() +
            " " +
            hour +
            ":" +
            min +
            " Uhr"
          );
        }
      }
    },
  },
  created() {
  },
  mounted() {
    axios
      .get("https://" + window.location.hostname + "/app/post.php?calendarPublicPast")
      .then((response) => {
        this.items = [...response.data];
        this.loading = false;
      })
      .catch(() => {
        this.$store.commit("showNotification", {
          snackbarColor: "error",
          snackbarTitle: "Die Events können gerade nicht angezeigt werden.",
        });
        this.loading = false;
        this.loadError = true;
        // Manage the state of the application if the request has failed
        //console.log(err);
      });
  }
};
</script>

<style scoped>
.flexCont {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 960px) {
  .flexCont {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 600px) {
  .flexCont {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
</style>