/***********************************************
 * 
 * MetalCrew Community App
 * by Mihe
 * 
 * TODO:
 * Notifications für Posts / Kalender / Highscores (In Settings aktivierbar)
 * Offline Fähigkeit
 * Chat Komponente
 * Text Material Load
 * Likes for Posts
 * Kalender Kommentare
 * Eigene Posts erstellen
 * Lyrics + Alben Cover einreichen
 * 
 ***********************************************/


import vue from 'vue'
import app from './app.vue'
import vuetify from './plugins/vuetify';
import store from './store'
import router from './router'
import i18n from './translations'

vue.config.productionTip = false

document.ontouchmove = function (event) {
  event.preventDefault();
}

new vue({
  i18n,
  store,
  router,
  vuetify,
  render: h => h(app)
}).$mount('#app')

// Workaround for anoying calendar warning
vue.config.warnHandler = function (msg) {
  if (msg === 'The .native modifier for v-on is only valid on components but it was used on <div>.') {
    msg = null;
  }
}

// Enable dev tools
vue.config.devtools = true

// Register serviceworker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/sw.js').then(function(registration) {
      registration.update(); // Needed for update check
      // Registration was successful
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, function(err) {
      // registration failed :(
      console.log('ServiceWorker registration failed: ', err);
    });
  });
}
