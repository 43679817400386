<template>
  <v-btn @click="changeLocale()" :title="changeLang" class="mr-5" icon>
  <country-flag :country='icon' size='big'/>
 <!--   <v-icon>{{ icon }}</v-icon>-->
  </v-btn>
</template>

<script>
import CountryFlag from 'vue-country-flag'

export default {
  name: "SwitchLocale",
  components: {
      CountryFlag
  },
  methods: {
    changeLocale() {
      if (this.$i18n.locale === "de") {
        //this.$i18n.locale = "en";
        //this.$router.push("/en/" + this.$router.currentRoute.path.slice(4))
        let link = window.location.href.replace(".de",".net");
        window.location.href = link;
      } else {
        //this.$i18n.locale = "de";
        //this.$router.push("/de/" + this.$router.currentRoute.path.slice(4))
        let link = window.location.href.replace(".net",".de");
        window.location.href = link;
      }
    },
  },
  computed: {
    changeLang() {
      return this.$t("general.changeLocale");
    },
    icon() {
      if (this.$i18n.locale === "de") {
        //return "mdi-flag-checkered";
        return "us";
      } else {
        //return "mdi-flag";
        return "de";
      }
    },
  },
};
</script>

<style scoped>
.flag {
  height: 36px !important;
  padding-top: -5px;
}
.f-de, .f-deu {
  background-position: 0 -2379px;
}
.f-us, .f-usa {
  background-position: 0 -9883px;
}

/* For Galaxy Fold and small devices */
@media screen and (max-width: 398px) {
  .v-btn {
    margin-top: -50px;
    margin-left: 19px;
}
}

/* For Galaxy Fold and small devices */
@media screen and (max-width: 300px) {
  .v-btn {
    display: none;
}
}

</style>