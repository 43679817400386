import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"
import notifications from './notifications.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isSubscribed: false,
    loading: false,
    userid: null,
    username: null,
    avatar: null,
    navigation: null,
    snackbar: {
      'content': '',
      'color': ''
    },
    news: [],
    association: [],
    reviews: [],
    songMonday: [],
    songTuesday: [],
    songWednesday: [],
    songThursday: [],
    songFriday: [],
    songSunday: [],
    calendar: []
  },
  mutations: {
    loading(state) {
      state.loading = true
    },
    loaded(state) {
      state.loading = false
    },
    initLoad(state) {
      axios
        .get("https://" + window.location.hostname + "/app/post.php?initload&timestamp=" + Date.now())
        .then(response => {
          formatRespond(state, response)
          this.commit("showNotification", {
            snackbarColor: "success",
            snackbarTitle:
              "Ladevorgang erfolgreich abgeschlossen"
          });
        })
        .catch(() => {
          this.commit("showNotification", {
            snackbarColor: "error",
            snackbarTitle:
              "Ladevorgang ist fehlgeschlagen"
          });
          // Manage the state of the application if the request has failed
          //console.log(err);
        });
    },
    login(state, payload) {
      state.userid = payload.userid
      state.username = payload.username
      state.avatar = payload.avatar
      // Notification
      notifications.init()
      // Initial Load
      axios
        .get("https://" + window.location.hostname + "/app/post.php?initload&timestamp=" + Date.now())
        .then(response => {
          formatRespond(state, response)
        })
        .catch(() => {
          this.commit("showNotification", {
            snackbarColor: "error",
            snackbarTitle:
              "Ladevorgang ist fehlgeschlagen"
          });
          // Manage the state of the application if the request has failed
          //console.log(err);
        });
    },
    logout(state) {
      state.userid = null
      state.username = null
      state.avatar = null
    },
    toggleNavigationBar(state, payload) {
      state.navigation = payload.navigation;
    },
    showNotification(state, payload) {
      state.snackbar['color'] = payload.snackbarColor
      state.snackbar['content'] = payload.snackbarTitle
    },
    setSubscribed(state, payload) {
      state.isSubscribed = payload
    }
  },
  actions: {
  },
  getters: {
    isAuthenticated(state) {
      return state.username !== null;
    }
  }
})

function formatRespond(state, response) {
  state.association = [...response.data.association];
  state.news = [...response.data.news];
  state.reviews = [...response.data.reviews];
  state.songMusictip =[...response.data.songMusictip];
  state.songMonday = [...response.data.songMonday];
  state.songTuesday = [...response.data.songTuesday];
  state.songWednesday = [...response.data.songWednesday];
  state.songThursday = [...response.data.songThursday];
  state.songFriday = [...response.data.songFriday];
  state.songSunday = [...response.data.songSunday];
  state.calendar = [...response.data.calendar];
}