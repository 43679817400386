const titleBase = "MetalCrew Kultur e.V. Pfaffenhofen a. d. Ilm";

export default {
    general: {
        association: 'Verein',
        calendar: 'Kalender',
        songsOfTheWeek: 'Songs der Woche',
        musictip: 'Musiktipp',
        entertainment: 'Unterhaltung',
        guessGame: 'Ratespiel',
        highscore: 'Bestenliste',
        beMember: 'Mitglied werden',
        beHelper: 'Helfer werden',
        subscribe: 'Abonnieren',
        dataPolicy: 'Datenschutzerklärung',
        imprint: 'Impressum',
        changeLocale: 'Change the language to english',
        toEvent: 'Zum Event',
        close: 'Schließen',
        hint: 'Hinweis',
        comments: 'Kommentare',
        say: 'sagt',
        loading: 'Wird geladen',
        share: 'Teilen',
        externalLinks: 'Externe Links',
        settings: 'Einstellungen'
    },
    menu: {
        leader: 'Vorstand',
        statute: 'Satzung',
        donation: 'Spende',
        extremism: 'Gewalt',
        aboutUs: 'Über uns',
        login: 'Vereinsbereich',
        crewsade: 'Crewsade of Metal',
        mcLive: 'MetalCrew Live',
        openPark: 'Open Park PFA\'HOFA',
        beMember: 'Werde Mitglied!',
        shop: 'E-Shop',
        events: 'Events',
        oldEvents: 'Vergangenes',
        festivals: 'Festivals',
        community: 'Community',
        metalCrew: 'MetalCrew',
        overview: 'Übersicht',
        coming: 'Kommendes',
        support: 'Unterstützung',
        raffles: 'Gewinnspiele'
    },
    home: {
        description: 'True Home For Metal! Der einzig wahre gemeinnützige Verein für harte Klänge im wunderschönen Pfaffenhofen a. d. Ilm.',
        h1: 'MetalCrew - True Home For Metal!',
        h2: 'Der Verein und die Community',
        subheading: 'Aus der 2 Jahre länger bestehenden bayrischen Metalheads Community entstand der Traum Festivals zu veranstalten. Dies leistet nun der dafür eingetragene Verein, welcher bis heute eng mit der Community zusammenarbeitet.',
        associationDesc: 'Das Rückgrat',
        communityDesc: 'Das Herzstück',
        associationDetail1: 'Der MetalCrew Kultur e.V. ist ein 2017 im Amtsgericht Ingolstadt\
            eingetragener Verein. Als Ziel wurde der Verein ins Leben gerufen, um\
            die Community vollständig zu unterstützen. Dies äußert sich als\
            rechtliches Organ für die Veranstaltung von Festivals und sonstigen\
            Events. Auch finanziell und organisatorisch für den Betrieb der MetalCrew Community sind wir standhaft.',
        communityDetail1: 'Die MetalCrew Community ist eine 2015 gegründete Gemeinschaft von Rock- und Metal-Fans im 3-stelligen Bereich mit Ursprung in Bayern und Mitgliedern aus ganz Deutschland und Österreich, deren Ideal es ist, durch Aktionen wie Treffen bei örtlichen Konzerten, gemeinsame Aktivitäten und einem Internet-Forum Musikfans miteinander zu verbinden.',
        associationDetail2: 'Teil von uns kann jeder engagierte Mensch sein, der bereit ist\
            auch einen kleinen Beitrag zu leisten. Es bestehen dazu viele Möglichkeiten.',
        communityDetail2: 'Der Eintritt in die Gemeinschaft ist für jedermann kostenfrei möglich. Wir freuen uns stets über einen regen Austausch zu aktuellen Themen der Szene.',
        associationDetail3: 'Siehe dir die Bedingungen für den Mitgliedsbeitritt über obere\
            Schaltfläche an.',
        communityDetail3: 'Besuche einfach unser Community Website über den oberen Button.',
    },
    login: {
        title: 'Vereinsbereich - ' + titleBase,
        description: 'Der Bereich für alle Vereinsmitglieder des MetalCrew Kultur e.V. haben hier die Möglichkeit in den internen Backstage Bereich zuzugreifen.',
        toolbarTitle: 'Backstage Bereich für Vereinsmitglieder',
        wrongLogin: 'Deine Logindaten sind fehlerhaft',
        username: 'Benutzername',
        password: 'Passwort',
        welcome: 'Servus VIP! Schön dich zu sehen!',
        networkError: 'Der Login konnte aufgrund eines Netzwerkfehlers nicht durchgeführt werden. Bitte versuche es später nochmal.',
        drunkSpoke0: "Wie wärs heute mit einem Jacky aus dem Backstage?",
        drunkSpoke1: "Jägermeister!! Heute frisch im Backstage eingetroffen!",
        drunkSpoke2: "Captain Morgan! Wecke den Captain in dir! Proost!",
        drunkSpoke3: "Heute schon Zähne geputzt? Zeit für nen Pfeffi!!",
        drunkSpoke4: "Heute trinken wir erstmal nen Willy auf dich!",
        drunkSpoke5: "Die letzte Party hier im Backstage war hart.. Schmerzen im Kopf",
        logout: 'Erfolgreich ausgeloggt. Bis bald & Stay Heavy!',
        logoutBtn: 'Verlassen',
        lostPassword: 'Passwort vergessen?',
        changePassword: 'Passwort ändern'
    },
    calendar: {
        start: 'Start',
        end: 'Ende',
        location: 'Ort',
        details: 'Details',
        today: 'Heute',
        day: 'Tag',
        week: 'Woche',
        month: 'Monat',
        days4: '4 Tage',
        addTo: 'Zum Kalender hinzufügen'
    },
    guessGame: {
        highscore: 'Top 50 Ratespiel Bestenliste',
        place: 'Platz',
        points: 'Punkte',
        statistics: 'MC Spielerstatistik',
        statsRight: 'Richtige Antworten',
        statsWrong: 'Falsche Antworten',
        statsPoints: 'Punktezahl',
        vote: 'Abstimmen',
        next: 'Weiter',
        exit: 'Beenden',
        questionMode1: "Von welchem Album ist dieses Cover?",
        questionMode2: "Aus welchem Song stammt dieser Liedtext?",
        rightAnswer: "Brutal! Deine Antwort war richtig!",
        wrongAnswer: "Sorry, das war keine würdige Antwort für einen Metalhead.."
    },
    settings: {
        notifications: 'Benachrichtigungen',
        notificationDesc: 'Hiermit können Benachrichtigungen bei Neuigkeiten aktiviert werden',
        notificiationsActive: 'Die Benachrichtungen sind für dich aktiviert!',
        notificationsBlocked: 'Benachrichtigungen sind auf deinem System blockiert!',
        notificationsNotSupported: "Benachrichtigungen sind auf deinem System nicht unterstützt!"
    },
    internal: {
        today: 'Heute ist der',
        home: `
        <h7
        >Hier befindest du dich im exklusiven MetalCrew Backstage Bereich für
        Vereinsmitglieder. Alle wichtigen Artikel des Vereins und der Community können hier schnell eingesehen werden. Außerdem gibt es neben dem Veranstaltungskalender auch
        die Möglichkeit beim fordernden Lyrics- und Alben Ratespiel das
        persönliche Können unter Beweis zu stellen.</h7
        >
        <br /><br />
        <h7 class="mt-5">🥳 Wir danken dir hiermit herzlich für deine Mitgliedschaft im MetalCrew Kultur e.V. Pfaffenhofen! Du hast ein starkes Herz! 🥳</h7>
        <br /><br />
        <h7 class="mt-5"
            >Durch dich werden neue Festivals für Metal, Rock, Punk und anderen
            alternativen Genres stattfinden. Junge Künstler und Newcomer erhalten durch dich Auftrittsmöglichkeiten. Außerdem unterstützt du eine
            einzigartige Community von Metalheads und Rockern. Ein Ort der Verbindung und des Seins. </h7
        >
        <br /><br />
        <h7 class="mt-5"
            >Falls Fragen bestehen ist dein Vorstand des MetalCrew Kultur e.V. stets
            unter der E-Mail
            <a href="mailto:verein@metalcrew.de">verein@metalcrew.de</a> für dich
            erreichbar.</h7
        >
        <br /><br />
        <h7 class="mt-5">🤘🤘🤘 Stay Heavy! 🤘🤘🤘</h7>
        <br /><br />
        <h7 class="mt-5">- Vanessa, Joe, Simon & \\M/ihe</h7>
        `,



    },
    leader: {
        title: 'Vorstand - ' + titleBase,
        description: 'Wer steckt hinter dem MetalCrew Kultur e.V.? Findet es heraus und lerne den Vorstand auf dieser Seite kennen.',
        character: 'Charakter',
        class: 'Klasse',
        class1: '1. Vorstand',
        class2: '2. Vorstand',
        class3: 'Schriftführer',
        class4: 'Schatzmeister',
        description1: 'Das letzte aktive Gründungsmitglied der Community und seit Beginn an im Vorstand. Stets mit vollem Herzen beim Verein und seinen Zielen dabei. Ein Heavy Rocker!',
        description2: 'Seit 2018 dabei und auf den Tag genau 3 Jahre später auch im Vorstand. Wenn sie nicht gerade auf Gigs und Mittelaltermärkten unterwegs ist, ist sie hauptberuflich als Heilerziehungspflegerin tätig. Vertritt die Core- und Stoner-Rock-Fraktion.',
        description3: 'Ein Urgestein und Admin der Community. Ebenfalls Gründungs- und bis dato bestehendes Vorstandsmitglied als Schriftführer. Musikalisch gibts nur Trve Metal!',
        description4: ' Wenn es ums Geld geht, Joe! Black Metal Liebhaber und Kenner des Undergrounds der Szene. Seit 2019 tätig als Schatzmeister.'
    },
    event: {
        description: 'Alle Vereinsausflüge und sonstigen Events werden hier angekündigt. Erlebe etwas mit uns und bleibe auf dem Laufenden!',
        descriptionOld: 'Alle vergangenen Vereinsausflüge und sonstigen Events werden hier angekündigt. Erlebe etwas mit uns und bleibe auf dem Laufenden!',
        descriptionRaffles: 'Alle Gewinnspiele werden hier angekündigt. Erlebe etwas mit uns und bleibe auf dem Laufenden!',
        descriptionSupport: 'Wir suchen stets Helfer für unsere Veranstaltungen. Bist du engagiert und magst alternative Musik? Schau vorbei!',
        titleUpcoming: 'Kommende Events - ' + titleBase,
        titlePast: 'Vergangene Events - ' + titleBase,
        titleRaffles: 'Gewinnspiele - ' + titleBase,
        inChat: "In den Chat",
        buyTicket: "Ticket kaufen",
        inMemberarea: "In den Vereinsbereich",
        registerEvent: "Zum Event anmelden",
        moreInfo: "Mehr Informationen",
        dateTo: "bis",
        upcoming: "Kommendes",
        past: "Vergangenes",
        loading: "Event-Daten werden geladen",
        loadingError: `
        Event-Daten können derzeit nicht angezeigt werden.
        <br />
        <br />Bitte versuche es später nochmals oder kontaktiere den Vorstand
        unter <a href="mailto:vorstand@metalcrew.de">vorstand@metalcrew.de</a>.
        `
    },
    footer: {
        organize: 'Wir organisieren das',
        desc: 'Der MetalCrew Kultur e.V. ist gemeinnützig und unterstützt leidenschaftlich gerne die alternative Musikszene',
        mailto: 'Mail an den Vereinsvorstand' // not used
    },
    donation: {
        title: 'Spende - ' + titleBase,
        description: 'Der MetalCrew Kultur e.V. ist gemeinnützig und auf freiwillige Spenden angewiesen. Sehe hier weitere Informationen ein.',
        topDesc: 'Jeder gemeinnützige Verein ist auf Spenden angewiesen. Auch wir.',
        lowerDesc: 'Spenden für gemeinnützige Vereine haben einen positiven und nachhaltigen Einfluss auf unsere Gesellschaft. Durch eine Spenden können wir Kultur fördern, bei Integration helfen, Künstler unterstützen und vieles mehr. Wir als gemeinnützige Organisationen sehen unseren Auftrag auch dabei, soziale Probleme anzugehen und positive Veränderungen zu bewirken.',
        bottomDesc: 'Durch eine Spende wird beigetragen, eine bessere Zukunft für uns alle zu schaffen. Jeder Betrag zählt und kann einen großen Unterschied machen.',
        bottomAction: 'Lasst uns gemeinsam Gutes tun und einen Beitrag leisten, um die Welt zu einem besseren Ort zu machen. Rocken mit Stil und Niveau!',
        payPalDonation: 'Spende per PayPal',
        bankConnection: 'Bankverbindung',
        owner: 'Inhaber'
    },
    extremism: {
        title: 'Gewalt - ' + titleBase,
        discription: 'Der MetalCrew Kultur e.V. ist für ein freundliches rocken mitteinander ohne extremistische Gewalt und Ausgrenzung.',
        fulltext: `
        <p>Extremismus in jeder Form schadet unserer Gesellschaft und bedroht grundlegende Werte wie Toleranz und Vielfalt.</p>
        <p>Es ist entscheidend, gemeinsam eine gesellschaftliche Atmosphäre des Respekts und Verständnisses zu fördern.</p>
        <div style="background-color: #1E1E1E;">
            <p>"Gewalt ist die Angst vor den Idealen anderer"<br><small><i>Mohandas Karamchand Gandhi * 1869 † 1948</i></small></p>
        </div>
        <p>Der MetalCrew Kultur e.V. vertritt die Ansicht, dass sämliche Formen von Extremismus nicht hinnehmbar sind.</p>
        <p><b>Rocken ohne extremistische Gewalt! Miteinander, Füreinander und Liebevoll!</b></p>
        <p>Als Verein setzen wir die vo­r­an­ge­hend genannten Werte als oberste Priorität im Alltag und Vereinsleben um.</p>
        <p>Durch unser gemeinnütziges und mildtätiges Handeln setzen wir ein Zeichen zur positiven Veränderung.</p>
        `
    },
    statute: {
        title: 'Satzung - ' + titleBase,
        description: 'Alle Grundsätze und Satzungsregelungen sind auf dieser Seite zusammengefasst. Sie dir alles gerne an, bevor du Mitglied wirst.',
        fulltext: `

            <h1>Vereinssatzung</h1>
            <h3>MetalCrew Kultur e.V.</h3>
            <br>
            <p><strong>§ 1 Name, Sitz, Eintragung, Geschäftsjahr</strong></p>
            <p>1. Der Verein trägt den Namen Metalcrew Kultur e.V.<br>2. Er hat den Sitz in 85276 Pfaffenhofen
                a.d. Ilm.<br>3. Er soll in das Vereinsregister eingetragen werden.<br>4. Geschäftsjahr ist das
                Kalenderjahr.</p>
            <p><strong>§ 2 Vereinszweck</strong></p>
            <p>Zweck des Vereins ist die Förderung der Kunst und Kultur, insbesondere die Förderung alternativer
                Musikrichtungen und die Förderung jugendlicher Newcomer-Bands.</p>
            <p>Der Satzungszweck wird insbesondere verwirklicht durch:</p>
            <p>· Unterstützung des Austauschs zwischen Fans alternativer Musik über eine Internet-Plattform sowie
                eine Chat-Plattform für Smartphones</p>
            <p>· Organisation von Benefiz-Konzerten. Hierbei wird speziell die starke Inklusion behinderter
                Mitmenschen gefördert. </p>
            <p>· Angebot von Konzerten &amp; Festivals mit freiem Eintritt</p>
            <p>· Unterstützung und Zusammenarbeit mit Hilfsorganisationen</p>
            <p></p>
            <p><strong>§ 3 Selbstlosigkeit</strong></p>
            <p>Der Verein verfolgt ausschließlich und unmittelbar gemeinnützige, mildtätige und kirchliche Zwecke
                (im Sinne des Abschnitts &#8220;Steuerbegünstigte Zwecke der Abgabenordnung).<br> Der Verein ist
                selbstlos tätig; er verfolgt nicht in erster Linie eigenwirtschaftliche Zwecke. Mittel des Vereins
                dürfen nur für die satzungsmäßigen Zwecke verwendet werden. Die Mitglieder erhalten in ihrer
                Eigenschaft als Mitglieder keine Zuwendungen aus Mitteln des Vereins. Es darf keine Person durch
                Ausgaben, die dem Zweck der Körperschaft fremd sind, oder durch unverhältnismäßig hohe Vergütungen
                begünstigt werden.</p>
            <p><strong>§ 4 Mitgliedschaft</strong></p>
            <p>(1) Mitglied des Vereins kann jede natürliche (und juristische) Person werden, die seine Ziele
                unterstützt.</p>
            <p>(2) Über den Antrag auf Aufnahme in den Verein entscheidet der Vorstand.</p>
            <p>(3) Die Mitgliedschaft endet durch Austritt, Ausschluss oder Tod.</p>
            <p>(4) Der Austritt eines Mitgliedes ist nur zum Ende eines Monats möglich. Er erfolgt durch
                schriftliche Erklärung gegenüber dem Vorstand unter Einhaltung einer Frist von 1 Monat.</p>
            <p>(5) Wenn ein Mitglied gegen die Ziele und Interessen des Vereins schwer verstoßen hat oder trotz
                Mahnung mit dem Beitrag für 1 Monat im Rückstand bleibt, so kann es durch den Vorstand mit
                sofortiger Wirkung ausgeschlossen werden.</p>
            <p>Dem Mitglied muss vor der Beschlussfassung Gelegenheit zur Rechtfertigung bzw. Stellungnahme gegeben
                werden.<br> Gegen den Ausschließungsbeschluss kann innerhalb einer Frist von 2 Wochen nach
                Mitteilung des Ausschlusses Berufung eingelegt werden, über den die nächste Mitgliederversammlung
                entscheidet.</p>
            <p>(6) Wenn ein Mitglied dem Verein entweder einen Betrag von mindestens 1 000,- Euro spendet oder
                diesem über einen Zeitraum von 10 Jahren Spenden zukommen lässt, deren Gesamtwert in der Summe 1
                000,- Euro erreicht, wird diesem Mitglied der Rang als Ehrenmitglied zugestanden. Ehrenmitglieder
                sind für die Dauer ihrer Mitgliedschaft von sämtlichen Mitgliedsbeiträgen befreit.</p>
            <p>(7) Vollbringt ein Mitglied herausragende Leistungen für den Verein, so kann der Vorstand darüber
                abstimmen, dieses Mitglied für eine Erhebung in den Rang des Ehrenmitglieds vorzuschlagen. Spricht
                sich der Vorstand einstimmig für einen solchen Vorschlag aus, so wird das entsprechende Mitglied bei
                der nächsten Vollversammlung des Vereins den dort anwesenden Mitgliedern zur Ernennung als
                Ehrenmitglied vorgeschlagen. Stimmt eine Mehrheit der auf der Vollversammlung anwesenden Mitglieder
                für die Ernennung, so wird dem vorgeschlagenen Mitglied den Rang als Ehrenmitglied verliehen.</p>
            <p></p>
            <p><strong>§ 5 Beiträge</strong></p>
            <p>Die Mitglieder zahlen Beiträge nach Maßgabe eines Beschlusses der Mitgliederversammlung. Zur
                Festlegung der Beitragshöhe und -fälligkeit ist eine einfache Mehrheit der in der
                Mitgliederversammlung anwesenden stimmberechtigten Vereinsmitglieder erforderlich.</p>
            <p><strong>§ 6 Organe des Vereins</strong></p>
            <p>Organe des Vereins sind<br> a) der Vorstand<br> b) die Mitgliederversammlung</p>
            <p><strong>§ 7 Der Vorstand</strong></p>
            <p>(1) Der Vorstand besteht aus 4 Mitgliedern:</p>
            <p>· 1. Vorstand</p>
            <p>· 2. Vorstand</p>
            <p>· Schriftführer</p>
            <p>· Kassenwart</p>
            <p>Er vertritt den Verein gerichtlich und außergerichtlich. Jedes Vorstandsmitglied ist einzeln vertretungsberechtigt.</p>
            <p>(2) Der Vorstand wird von der Mitgliederversammlung für die Dauer von 1 Jahr gewählt. Die Wiederwahl
                der Vorstandsmitglieder ist möglich. Der Vorsitzende wird von der Mitgliederversammlung in einem
                besonderen Wahlgang bestimmt. Die jeweils amtierenden Vorstandsmitglieder bleiben nach Ablauf ihrer
                Amtszeit im Amt, bis Nachfolger gewählt sind.</p>
            <p>(3) Dem Vorstand obliegt die Führung der laufenden Geschäfte des Vereins. Er hat insbesondere
                folgende Aufgaben: Der Vorstand kann für die Geschäfte der laufenden Verwaltung einen
                Geschäftsführer bestellen. Dieser ist berechtigt, an den Sitzungen des Vorstandes mit beratender
                Stimme teilzunehmen.</p>
            <p>(4) Vorstandssitzungen finden jährlich mindestens 2 mal statt. Die Einladung zu Vorstandssitzungen
                erfolgt durch ein Mitglied des Vorstands schriftlich unter Einhaltung einer Einladungsfrist von
                mindestens 30 Tagen.</p>
            <p>(5) Der Vorstand fasst seine Beschlüsse mit einfacher Mehrheit.</p>
            <p>(6) Beschlüsse des Vorstands können bei Eilbedürftigkeit auch schriftlich oder fernmündlich gefasst
                werden, wenn alle Vorstandsmitglieder ihre Zustimmung zu diesem Verfahren schriftlich oder
                fernmündlich erklären. Schriftlich oder fernmündlich gefasste Vorstandsbeschlüsse sind schriftlich
                niederzulegen und von zu unterzeichnen.</p>
            <p>(7) Der Vorstand kann für seine Tätigkeit eine angemessene Vergütung erhalten.</p>
            <p><strong>§ 8 Mitgliederversammlung</strong></p>
            <p>(1) Die Mitgliederversammlung ist einmal jährlich einzuberufen.</p>
            <p>(2) Eine außerordentliche Mitgliederversammlung ist einzuberufen, wenn es das Vereinsinteresse
                erfordert oder wenn die Einberufung von 20% der Vereinsmitglieder schriftlich und unter Angabe des
                Zweckes und der Gründe verlangt wird.</p>
            <p>(3) Die Einberufung der Mitgliederversammlung erfolgt schriftlich durch ein Mitglied des Vorstands
                unter Wahrung einer Einladungsfrist von mindestens 4 Wochen bei gleichzeitiger Bekanntgabe der
                Tagesordnung. Die Frist beginnt mit dem auf die Absendung des Einladungsschreibens folgenden Tag. Es
                gilt das Datum des Poststempels. Das Einladungsschreiben gilt dem Mitglied als zugegangen, wenn es
                an die letzte vom Mitglied des Vereins schriftlich bekannt gegebene Adresse gerichtet ist.</p>
            <p>(4) Die Mitgliederversammlung als das oberste beschlussfassende Vereinsorgan ist grundsätzlich für
                alle Aufgaben zuständig, sofern bestimmte Aufgaben gemäß dieser Satzung nicht einem anderen
                Vereinsorgan übertragen wurden.<br> Ihr sind insbesondere die Jahresrechnung und der Jahresbericht
                zur Beschlussfassung über die Genehmigung und die Entlastung des Vorstandes schriftlich vorzulegen.
                Sie bestellt zwei Rechnungsprüfer, die weder dem Vorstand noch einem vom Vorstand berufenen Gremium
                angehören und auch nicht Angestellte des Vereins sein dürfen, um die Buchführung einschließlich
                Jahresabschluss zu prüfen und über das Ergebnis vor der Mitgliederversammlung zu berichten.<br> Die
                Mitgliederversammlung entscheidet z. B. auch über<br> a) Gebührenbefreiungen,<br> b) Aufgaben des
                Vereins,<br> c) An- und Verkauf sowie Belastung von Grundbesitz,<br> d) Beteiligung an
                Gesellschaften,<br> e) Aufnahme von Darlehen ab EUR 5000,<br> f) Genehmigung aller
                Geschäftsordnungen für den Vereinsbereich,<br> g) Mitgliedsbeiträge,<br> h) Satzungsänderungen,<br>
                i) Auflösung des Vereins.</p>
            <p>(5) Jede satzungsmäßig einberufene Mitgliederversammlung wird als beschlussfähig anerkannt ohne
                Rücksicht auf die Zahl der erschienenen Vereinsmitglieder. Jedes Mitglied hat eine Stimme.</p>
            <p>(6) Die Mitgliederversammlung fasst ihre Beschlüsse mit einfacher Mehrheit. Bei Stimmengleichheit
                gilt ein Antrag als abgelehnt.</p>
            <p>(7) Die Mitgliederversammlung kann auch auf dem Wege der elektronischen Kommunikation (z.B. als Telephon- oder Videokonferenz) 
            oder in einer gemischten Versammlung aus Anwesenden und Videokonferenz / anderen Medien / Telephon durchgeführt werden. Ob die Mitgliederversammlung
            in einer Sitzung oder auf dem Wege der elektronischen Kommunikation oder in einer gemischten Versammlung aus Anwesenden und Videokonferenz / anderen Medien / Telephon durchgeführt wird,
            entscheidet der Vorstand.</p>
            <p>(8) Der Vorstand kann Beschlüsse der Mitgliederversammlung auch im schriftlichen Verfahren einholen. Beschlüsse im schriftlichen Verfahren sind angenommen, wenn eine einfache Mehrheit vorliegt.</p>
            <p><strong>§ 9 Aufwandsersatz</strong></p>
            <p>(1) Mitglieder – soweit sie vom Vorstand beauftragt wurden – und Vorstandsmitglieder haben einen
                Anspruch auf Ersatz der Aufwendungen, die ihnen im Rahmen ihrer Tätigkeit für den Verein entstanden
                sind. Dazu gehören insbesondere Reisekosten, Verpflegungsmehraufwendungen, Porto und
                Kommunikationskosten. </p>
            <p>(2) Der Nachweis erfolgt über entsprechende Einzelbelege und ist spätestens 6 Wochen nach Ende des
                jeweiligen Quartals geltend zu machen. </p>
            <p>(3) Soweit für den Aufwandsersatz steuerliche Pauschalen und steuerfreie Höchstgrenzen bestehen,
                erfolgt ein Ersatz nur in dieser Höhe.</p>
            <p><strong>§ 10 Satzungsänderung</strong></p>
            <p>(1) Für Satzungsänderungen ist eine 2/3-Mehrheit der erschienenen Vereinsmitglieder erforderlich. Für
                Änderungen des Satzungszwecks ist eine Mehrheit von 2/3 der erschienenen Vereinsmitglieder
                erforderlich. Über Satzungsänderungen kann in der Mitgliederversammlung nur abgestimmt werden, wenn
                auf diesen Tagesordnungspunkt bereits in der Einladung zur Mitgliederversammlung hingewiesen wurde
                und der Einladung sowohl der bisherige als auch der vorgesehene neue Satzungstext beigefügt worden
                waren.</p>
            <p></p>
            <p>(2) Satzungsänderungen, die von Aufsichts-, Gerichts- oder Finanzbehörden aus formalen Gründen
                verlangt werden, kann der Vorstand von sich aus vornehmen. Diese Satzungsänderungen müssen allen
                Vereinsmitgliedern alsbald schriftlich mitgeteilt werden.</p>
            <p><strong>§ 11 Beurkundung von Beschlüssen</strong></p>
            <p>Die in Vorstandssitzungen und in Mitgliederversammlungen erfassten Beschlüsse sind schriftlich
                niederzulegen und vom Vorstand zu unterzeichnen.</p>
            <p></p>
            <p><strong>§ 12 Auflösung des Vereins und Vermögensbindung</strong></p>
            <p>(1) Für den Beschluss, den Verein aufzulösen, ist eine 3/4-Mehrheit der in der Mitgliederversammlung
                anwesenden Mitglieder erforderlich. Der Beschluss kann nur nach rechtzeitiger Ankündigung in der
                Einladung zur Mitgliederversammlung gefasst werden.</p>
            <p>(2) Bei Auflösung oder Aufhebung des Vereins oder bei Wegfall steuerbegünstigter Zwecke fällt das
                Vermögen des Vereins an eine juristische Person des öffentlichen Rechts oder eine andere
                steuerbegünstigte Körperschaft zwecks Verwendung für die Förderung von Kunst und Kultur. </p>
            `
    },
    aboutUs: {
        title: 'Über uns - ' + titleBase,
        description: 'Die Geschichte der Entstehung des MetalCrew Kultur e.V. und allen weiteren Vorkommnissen. Kompakt und knallhart!',
        timeline: [
            {
                year: '23.09.2023',
                text: '5. Festival',
                description: 'Unser fünftes Crewsade Vereinsfestival hat einen Gast aus Israel begrüßt.',
                img: 'https://www.crewsade.de/wp-content/uploads/2023/09/383732758_697335105755298_1251950368504844542_n.jpg'
            },
            {
                year: '08.07.2023',
                text: 'Zweites Open Air',
                description: 'Unser zweites Open Park PFA\'HOFA Open Air erreichte 10.000 Besucher.',
                img: 'https://www.open-park.de/wp-content/uploads/2023/07/e881f8b6d00e-29-107658105.jpg'
            },
            {
                year: '24.09.2022',
                text: '4. Festival',
                description: 'Unser viertes Vereinsfestival ging ein zweites Mal in Pfaffenhofen a. d. Ilm an den Start.',
                img: 'https://www.crewsade.de/wp-content/uploads/2022/10/crewsade_2022-e1665997826296.jpg'
            },
            {
                year: '10.07.2022',
                text: '50 Mitglieder',
                description: 'Wir haben erstmalig 50 Vereinsmitglieder im MetalCrew Kultur e.V. erreicht.',
                img: 'https://www.metalcrew.de/community/gallery/userImages/a4/1425-a490e7a3-large.jpg'
            },
            {
                year: '09.07.2022',
                text: 'Erstes Open Air',
                description: 'Premiere des 2-tägigen Open Park Festivals im Freibad unter Zusammenarbeit mit der AWO und dem Oroborus.',
                img: 'https://www.open-park.de/wp-content/uploads/2022/03/1.jpeg'
            },
            {
                year: '25.09.2020',
                text: 'MetalCrew Live',
                description: 'Wir starteten unsere eigene Underground Konzertreihe in pandemischen Zeiten.',
                img: 'https://www.metalcrew.de/f/attachment/187-25-26-09-band-announcement-jpg/'
            },
            {
                year: '28.09.2019',
                text: '3. Festival',
                description: 'Unser erstes Festival in der Heimatstadt Pfaffenhofen a. d. Ilm in Zusammenarbeit mit der Stadtjugendpflege.',
                img: 'https://www.crewsade.de/wp-content/uploads/2019/08/Plakat-Balken-476x476.jpg'
            },
            {
                year: '05.05.2018',
                text: '2. Festival',
                description: 'Die Fortsetzung des Crewsade of Metal Festivals mit 7 Bands in Zusammenarbeit mit der Stadtjugendpflege Ebersberg.',
                img: 'https://www.crewsade.de/wp-content/uploads/2019/05/28514747_1606525662771812_3732989265661303777_o-1-476x476.jpg'
            },
            {
                year: '06.05.2017',
                text: '1. Festival',
                description: 'In Zusammenarbeit mit der katholischen Jugendfürsorge München fand unser erstes karitatives Crewsade of Metal Festival mit 8 Bands statt.',
                img: 'https://www.crewsade.de/wp-content/uploads/2019/05/412-b5801043-476x476.jpg'
            },

            /*{
                year: '> 2018',
                text: 'Lebenslauf des Vereins',
                description: 'Die Geschichte ab Vereinsgründung wird noch geschrieben und bald veröffentlicht.',
                img: 'https://www.crewsade.de/wp-content/uploads/2019/05/20-e1655bc8bc130fc6e074dcf71d964e5e68f1a28f-125x108.jpg'
            },*/
            {
                year: '12.04.2017',
                text: 'Gründung des MetalCrew Kultur e.V.',
                description: 'Durch die Gründungssitzung in München wird der MetalCrew  Kultur e.V. ins Leben gerufen. Der erste Vorstand besteht aus Mick, Miche, Flo und Simon.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/d1/419-d1eb6857-small.jpeg'
            },
            {
                year: '01.12.2016',
                text: 'Erster Crew Redakteur',
                description: 'Der Posten des Redakteurs wird geschaffen und mit Flo besetzt.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/7d/421-7d7fe89c-small.jpeg'
            },
            {
                year: '22.09.2016',
                text: 'Namen der Website',
                description: 'Die Internet-Adresse www.metalcrew.de wird erworben und das Forum darüber erreichbar.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/18/10-1807d267-small.jpg'
            },
            {
                year: '12.09.2016',
                text: '1. Vereinsverlosung',
                description: 'Erstmals wird in der MetalCrew eine Verlosung organisiert. Diese werden später wiederholt getätigt.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/29/1081-29b0e81c-small.jpeg'
            },
            {
                year: '17.08.2016',
                text: '1. Festival Camping',
                description: 'Auf dem Summerbreeze treffen sich erstmals im großen Maßstab gezielt Mitglieder der MetalCrew auf einem Festival. Derartige Treffen werden später zur Tradition.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/0c/274-0c3688ec-small.jpg'
            },
            {
                year: '14.08.2016',
                text: 'Team für Event-Planung',
                description: 'Eine eigene Abteilung zur Event-Planung wird gegründet. Als ihr Kopf wird Mick kurz darauf mit dem Posten als Organisator ausgestattet.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/da/591-dacf2d16-small.jpg'
            },
            {
                year: '19.06.2016',
                text: 'Eintritt von Joe ins Team',
                description: 'Chris tritt endgültig als Admin zurück und wird durch Joe ersetzt.',
                img: 'https://www.crewsade.de/wp-content/uploads/2019/05/39-99b650adf62f2db4a66126657ab3be83e7a9db05-125x125.jpg'
            },
            {
                year: '11.06.2016',
                text: '1. Crew Event',
                description: 'Mit einem Geocaching findet erstmals ein gemeinsames Event in der MetalCrew unter Leitung von Mick statt.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/fb/179-fb0c7dd1-small.jpg'
            },
            {
                year: '11.05.2016',
                text: '1. Merchandise-Produkt',
                description: 'Erstmals werden Fan-Shirts der MetalCrew veröffentlicht. Später folgt weiteres Merchandise.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/7c/414-7ceebd55-small.jpeg'
            },
            {
                year: '07.03.2016',
                text: 'Neuer MC Schriftzug',
                description: 'Der Schriftzug der MetalCrew wird überarbeitet und erneuert. Zudem wird das erste von mehreren MetalCrew-Wallpapers veröffentlicht.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/86/406-864cd395-small.jpg'
            },
            {
                year: '14.01.2016',
                text: 'Eintritt des Teammitglieds Marco',
                description: 'Durch die Hinzunahme von Marco sind die Admins wieder vollständig.',
                img: 'https://www.metalcrew.de/community/images/avatars/d5/36-d5437fb572e86321cb110c6a4017ae5f2687530d.jpg'
            },
            {
                year: '10.01.2016',
                text: 'Tatsächliche Einführung des Regelwerks',
                description: 'Nach ausführlicher Überarbeitung wird eine zweite Version des Regelwerkes in der MetalCrew in Kraft gesetzt.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/7d/421-7d7fe89c-small.jpeg'
            },
            {
                year: '28.12.2015',
                text: 'Abtritt Taistos',
                description: 'Im Zuge der Verwirrungen um die Einführung des Regelwerks tritt Taisto zurück.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/d3/366-d3a97cfa-small.png'
            },
            {
                year: '27.12.2015',
                text: 'Entstehung des 1. Regelwerks',
                description: 'Durch das Wachstum der Gruppe nötig geworden, wird für die  MetalCrew ein  Regelwerk erstellt und in Kraft gesetzt. Wegen massiver Verstimmungen innerhalb der Gemeinschaft wird dieses jedoch noch am  selben Tag wieder vorläufig außer Kraft gesetzt.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/7d/421-7d7fe89c-small.jpeg'
            },
            {
                year: '26.12.2015',
                text: 'Wer kommt denn da?',
                description: 'Chris kehrt erneut als Admin zurück.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/05/32-057627cc-small.jpg'
            },
            {
                year: '06.12.2015',
                text: 'Austritt von Nathi',
                description: 'Nathi tritt als Admin zurück.',
                img: 'https://www.metalcrew.de/community/images/avatars/8c/6-8c9d3499f20e175c0c54ddace7e6c9181c94b69f.jpg'
            },
            {
                year: '02.11.2015',
                text: 'Entstehung der Wichtel',
                description: 'Die erste Weihnachtswichtel-Aktion der MetalCrew wird gestartet. Sie wird später alljährlich abgehalten.',
                img: 'https://i.scdn.co/image/ab67706c0000bebbbf82f60e33358592da5d2bf3'
            },
            {
                year: ' 31.10.2015',
                text: 'Windows Phone App Veröffentlichung',
                description: 'Die MetalCrew-App für Windows Phone wird veröffentlicht.',
                img: 'https://www.metalcrew.de/img/touch-icon-iphone-retina.png'
            },
            {
                year: '31.08.2015',
                text: 'Eintritt von Taisto',
                description: 'Chris tritt erneut zurück. Sein Ersatz wird Taisto.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/31/43-31f5ba20-small.jpg'
            },
            {
                year: '30.08.2015',
                text: 'Android App Veröffentlichung',
                description: 'Die MetalCrew-App für Android wird veröffentlicht. Windows Phone App ist noch in Entwicklung.',
                img: 'https://www.metalcrew.de/img/touch-icon-iphone-retina.png'
            },
            {
                year: '17.06.2015',
                text: 'Erster Online Auftritt',
                description: 'Nach längeren Planungen geht die Gruppe als Forum ins Internet. Hier wird auch  erstmals die Bezeichnung als „MetalCrew“ verwendet.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/18/10-1807d267-small.jpg'
            },
            {
                year: '08.06.2015',
                text: 'Einstieg von Nathi',
                description: 'Manu tritt als Admin zurück und wird durch Nathi ersetzt.',
                img: 'https://www.metalcrew.de/community/images/avatars/8c/6-8c9d3499f20e175c0c54ddace7e6c9181c94b69f.jpg'
            },
            {
                year: '08.05.2015',
                text: 'Erster Rücktritt eines Gründungsmitglieds',
                description: 'Chris tritt als Admin zurück, steigt jedoch am 22.5. wieder ein.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/11/42-11fcd66f-small.jpg',
            },
            {
                year: '28.03.2015',
                text: 'Einstieg von Simon Hauke',
                description: 'Jojo tritt als Admin zurück und wird durch Simon ersetzt.',
                img: 'https://www.crewsade.de/wp-content/uploads/2019/05/20-e1655bc8bc130fc6e074dcf71d964e5e68f1a28f-125x108.jpg',
            },
            {
                year: '12.03.2015',
                text: 'Erste Erweiterung der Führungskräfte',
                description: 'Durch die Hinzunahme von Jojo wird die Anzahl der Admins auf vier erhöht.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/86/371-861aab87-small.jpg',
            },
            {
                year: '09.03.2015',
                text: 'Gründung der Community',
                description: 'Die WhatsApp-Gruppe wird von Mihe und Chris gegründet. Admin ist außerdem Manu.',
                img: 'https://www.crewsade.de/wp-content/uploads/2020/08/WhatsApp-Image-2020-05-31-at-20.22.48-e1597698556410.jpeg',
            }
        ]
    },
    support: {
        topDesc: 'Du bist social engagiert und freust dich auch einmal hinter die Kulissen zu sehen?',
        subLine: 'Dann bewirb dich jetzt als Helfer für die nächsten MetalCrew Kultur e.V. Veranstaltungen. Die MetalCrew freut sich auf deine Verstärkung!',
        success: 'Dein Hilfsangebot wurde gesendet. Wir melden uns bei dir. Danke!',
        error: 'Dein Hilfsangebot konnte aufgrund eines Netzwerkfehler nicht erfolgreich gesendet werden.'
    },
    membership: {
        description: 'Hier besteht die Möglichkeit ein Teil des besten gemeinnützigen Vereins für alternative Musikrichtungen zu werden.',
        title: 'Mitgliedschaft - ' + titleBase,
        top: 'Mitgliedschaft im MetalCrew Kultur e.V.',
        topDesc: 'Eine einzigartige Gemeinschaft mit wahren Freundschaften.',
        details: `
          Gemeinsam macht das Leben mehr Spaß, besonders unter
          Gleichgesinnten. Sichere dir den Zugang zu einer neuen Familie.
          Werde Mitglied und erhalte für günstige 6,66€ im Quartal Zutritt zu
          einzigartigen Veranstaltungen, Treffen und Szenen-Informationen.
        `,
        labelName: 'Dein Vor- und Nachname',
        labelMail: 'Deine E-Mail Adresse',
        labelCommunity: 'Community-Benutzername',
        labelPolicy: 'Ich aktzeptiere die',
        success: 'Dein Mitgliedsformular wurde erfolgreich verschickt. Prüfe bitte deine E-Mails.',
        error: 'Die Mitgliedsanfrage konnte aufgrund eines Netzwerkfehler nicht erfolgreich gesendet werden.',
        statsError: 'Statistische Daten können gerade nicht angezeigt werden',
        facebookError: 'Facebook Daten können gerade nicht angezeigt werden.'
    },
    message: {
        error: 'Es ist ein Fehler aufgetreten. Bitte später nochmals versuchen.',
        networkError: 'Die Daten konnten aufgrund eines Netzwerkfehlers nicht geladen werden. Bitte versuche es später nochmal.',
        noNetwork: 'Keine Netzwerkverbindung',
        noSelection: 'Auswahl nicht verfügbar',
        copyClipboard: 'Erfolgreich in Zwischenablage kopiert'
    },
    festivals: {
        description: 'Alle Festivals des MetalCrew Kultur e.V. kurz und kompakt auf einer Seite aufgelistet. Wir freuen uns über jeden Besucher!'
    },
    crewsade: {
        fullTextHeadline: 'Crewsade of Metal Festival Pfaffenhofen a. d. Ilm',
        description: '1-tägige Festivalstimmung mit Metal, Rock & Punk Musik im September. 12 Stunden Vollgas!',
        fullText: `
        <p>Einzigartiges Metal, Rock & Punk Festival in familiärer Kulisse: Crewsade of Metal! Ein kostenfreies und gemeinnütziges Festivals zur Förderung von alternativen Musikrichtungen.</p>
        <p>Die Liebhaber der alternativen Musikrichtungen können sich freuen, denn das "Crewsade of Metal" Festival bietet jährlich eine unwiderstehlichen Mischung aus großartiger Musik, Gemeinschaftsgeist und unvergesslichen Erlebnissen. Das kleine, aber feine Festival findet am Ende September in der malerischen Kulisse der Vereinsheimat Pfaffenhofen a. d. Ilm statt und verspricht den Besuchern ein unvergessliches Wochenende voller guter Musik und einer großartigen Festivalatmosphäre.</p>
        <p>Das Crewsade of Metal Festival, kurz einfach Crewsade, präsentiert eine vielfältige Auswahl an talentierten und energiegeladenen Künstlern, die von aufstrebenden Lokalgrößen bis hin zu bekannten Szene-Acts reichen. Die Besucher können sich auf stektakuläre Live-Auftritte, mitreißende Gitarrenriffs und pulsierende Drums freuen, die die Luft zum Vibrieren bringen. Von klassischem Rock über Alternative bis hin zu Modern Metal bietet das Festival für jeden Geschmack und jedes Alter etwas.</p>
        <p>Doch das Crewsade ist mehr als nur eine Ansammlung großartiger Bands. Das Festival legt großen Wert auf eine einladende und familiäre Atmosphäre, bei der jeder Besucher sich willkommen und wohl fühlt. Es gibt eine Vielzahl von liebevoll aus eigener Hand zubereiteten Speisen. Getränke von alkoholfrei bis alkohollisch sind selbstverständlich. Diverse Merchandise Artikel unserer Künstler werden präsentiert und angeboten. Campingmöglichkeiten sind ebenfalls vorhanden, so dass die Gäste das Festivalerlebnis voll auskosten können.</p>
        <p>Wir binden  Mitmenschen mit Handicap in die Organisation und auch in die konkrete Vor-Ort-Unterstützung unseres Festivals ein. Ziel der Aktion ist zum einen, der Gemeinschaft und den eben genannten Idealen in dem Festival Ausdruck zu verleihen, andererseits aber auch, Leuten außerhalb der Gruppe einen unvergesslichen Tag zu bereiten und in der lokalen Musiklandschaft etwas Abwechslung zu schaffen.</p>
        <p>Entstanden ist das Format 2017 in einer wundervollen Zusammenarbeit mit der EVS Steinhöring. Mittlerweile sind wir stolz unser familliäres Festival im Herzen Pfaffenhofens a. d. Ilm in der Zusammenarbeit mit der Stadt Jugendpflege veranstalten zu können. Wir bieten hierbei ein 1-tägiges Festival mit 6 - 8 alternativen Bands und kostenfreiem Eintritt.</p>
        <p>Weitere Informationen zur Veranstaltungen können unter der eigenen Festival-Website <a href="https://www.crewsade.de" target="_blank">www.crewsade.de</a> eingesehen werden. Merchandise zu diesem Event gibt es im oberen Shop.</p>
        `
    },
    openPark: {
        description: '2-tägiges Sommer Open Air im Juli mit Picknick-Atmosphäre! Kooperation mit AWO und Oroborus.',
        fullText: `
        <h1>Open Park PFA'HOFA Festival</h1>
        <h3> 🏕️🌱🌲Mehr als ein Open Air! Heiß, laut und erfrischend: Das PFA'HOFA Festival bringt den Rock zum Freibad! 🌲🌱🏕️</h3><br>
        <p>Der Sommer wird noch heißer mit dem Open Park PFA'HOFA Festival, einem einzigartigen Rock-Erlebnis, das in diesem Jahr im idyllischen Pfaffenhofener Freibad stattfindet. Die Kombination aus mitreißender Musik, erfrischenden Poolpartys und sommerlichem Flair verspricht den Besuchern ein unvergessliches Rock-Festival der besonderen Art.</p>
        <p>Das Open Park Festival präsentiert eine beeindruckende Auswahl an talentierten Künstlern, die mit energiegeladenen Auftritten und mitreißenden Rhythmen das Publikum begeistern werden. Von heißen Geheimtipps bis hin zu aufstrebenden Bands  aus der Region bietet das Festival eine breite Palette an Genres, die die Herzen aller Musikfans höherschlagen lassen. Die Besucher können sich auf unvergessliche Live-Auftritte, pulsierende Gitarrenriffs und eine Atmosphäre voller Energie und Freude freuen.</p>
        <p>Doch das Open Park ist nicht nur ein rockiges Festival, sondern auch eine sommerliche Pool-Party. Das Festivalgelände erstreckt sich rund um das Pfaffenhofener Freibad, sodass die Besucher zwischen den Auftritten eine erfrischende Abkühlung im Pool genießen können. Ob beim Schwimmen, Relaxen auf den Liegestühlen oder Tanzen zu den Klängen der Bands - das Open Park bietet das ultimative Sommererlebnis für Musik- und Pool-Liebhaber gleichermaßen.</p>
        <p>"Wir setzen uns lautstark für ein abenteuerliches und soziales Leben mit bester Musikuntermalung ein", sagt Mitorganisator Michael. "Wir wollen den Menschen die Möglichkeit geben, in einer einzigartigen Umgebung zu feiern und sich gleichzeitig in den Wellen des Freibads abzukühlen. Das Open Park PFA'HOFA verspricht unvergessliche Momente und eine ganz besondere Festivalatmosphäre."</p>
        <p>Ziel der Veranstaltung ist einen konfessions- und parteipolitisch neutralen Platz mit unterschiedlichsten Ausstellungsständen, überregionalen Essensangebot und wunderbarer Picknick Atmosphäre im schönen Pfaffenhofener Ilmbad zu bieten.</p>
        <p>Festivalflair und gleichzeitig Schwimmen, Rutschen und vom 5 Meter Brett Springen. Das alles wirst du hier bekommen. Einzige Gegenleistung: Freibad-Ticket zum kleinen Betrag kaufen und abgehen!</p>
        <p>Eine Zusammenarbeit unsererseits seit dem Jahre 2020 mit der Arbeiterwohlfahrt (AWO), dessen Jugendwerk und dem Oroboros Verein.</p>
        <p>Weitere Informationen zur Veranstaltungen können unter der eigenen Festival-Website <a href="https://www.open-park.de" target="_blank">www.open-park.de</a> eingesehen werden. Merchandise zu diesem Event gibt es im oberen Shop.</p>
        `
    },
    mcLive: {
        description: 'Konzerttage mit lokalem Underground Metal, Rock & Punk vom Feinsten mehrfach über das Jahr verteilt!',
        fullText: `
        <h1>💣 MetalCrew Live 💣</h1>
        <h3>Underground Metal, Rock & Punk in seiner besten Form: Die lokale MetalCrew Konzertreihe</h3>
        <br>
        <p>Musikliebhaber und Rockfans aufgepasst: Die MetalCrew Live Konzertreihe verspricht einzigartige Live-Konzerte in intimer Atmosphäre. Diese kleine, aber feine Konzertreihe bringt lokale Metal-, Rock & Punkbands aus Pfaffenhofen a. d. Ilm und Umgebung auf die Bühne und bietet den Besuchern die Möglichkeit, hautnah die energiegeladene und mitreißende Musikszene der Community zu erleben.</p>
        <p>Die MetalCrew Live Konzertreihe präsentiert eine Vielfalt an talentierten Bands, die mit Leidenschaft und Hingabe ihre Musik zum Leben erwecken. Von kraftvollen Gitarrenriffs bis hin zu kraftvollen Gesangseinlagen bietet die Reihe ein breites Spektrum an Genres, die die vielseitige lokale Musikszene widerspiegeln. Jedes Konzert ist eine einzigartige Gelegenheit, die authentische und unverfälschte Musik der aufstrebenden Talente aus der Region zu entdecken und zu feiern.</p>
        <p>Die MetalCrew Live Konzertreihe ist unsere Art, die lokale Künstlerszene zu unterstützen und den Menschen in unserer Gemeinschaft einzigartige musikalische Erlebnisse zu bieten. Es geht darum, eine enge Verbindung zwischen den Künstlern und dem Publikum herzustellen und die Leidenschaft für Musik in unserer eigenen Nachbarschaft zu fördern. Die Konzerte sind intim, energetisch und unvergesslich.</p>
        <p>Die Konzerte der MetalCrew Live Reihe finden in gemütlichen Locations statt, die eine intime und familiäre Atmosphäre bieten. Diese Konzertreihe schafft eine einladende Umgebung, in der Musikliebhaber und Fans der harten Klänge aller Altersgruppen zusammenkommen, um die lokale Rockmusikszene zu unterstützen und zu feiern.</p>
        <p>Entstanden ist das Konzept im Krisenjahr 2020 in enger Zusammenarbeit mit dem Musikklub Pfaffenhofen. Klein und fein mit bester Underground Metal, Rock und Punk Mischung für dicke Felle. Der Geheimtipp für heiße Newcomer!</p>
        <p>Weitere Informationen zu kommenden Veranstaltung können im <a href="/events/upcoming" target="_blank">Event-Bereich</a> der MetalCrew eingesehen werden. Merchandise zu diesem Event gibt es im oberen Shop.</p>
        `
    },
    wbbPosts: {
        newComment: 'Neuen Kommentar erstellen',
        postDisplay: 'Beitragsanzeige',
        successComment: "Kommentar wurde erfolgreich erstellt",
        errorComment: "Ein Fehler ist beim Erstellen des Kommentars aufgetreten. Bitte versuche es erneut!"
    },
    cookie: {
        details: 'Diese Internetseite verwendet Cookies, um die Nutzererfahrung zu verbessern und den Benutzern bestimmte Dienste und Funktionen bereitzustellen.',
        accept: 'Akzeptieren'
    }
}