<!--
TODO: Big Team Together Picture
-->

<template>
  <v-container>
    <v-row>
      <v-img
        src="@/assets/about-us.jpg"
        width="100"
        title="MetalCrew Kultur e.V. Mitglieder"
        alt="MetalCrew Kultur e.V. Mitglieder"
        class="fill-height"
      />
    </v-row>
    <v-timeline class="mt-3">
      <v-timeline-item v-for="(entry, n) in $t('aboutUs.timeline')" :key="n" large>
        <template v-slot:icon>
          <v-avatar>
            <img :src="entry.img" style="border: 2px solid orange;" />
          </v-avatar>
        </template>
        <template v-slot:opposite>
          <span class="orange--text text-h5" v-text="entry.year"></span>
        </template>
        <v-card class="elevation-2">
          <v-card-title class="text-h5" v-text="entry.text"></v-card-title>
          <v-card-text v-text="entry.description"></v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-container>
</template>

<script>
export default {
  data: () => ({
  }),
}
</script>
