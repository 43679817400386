<template>
  <v-container fluid>
    <v-card class="mt-4 mx-auto mt-5" style="background-color: #121212; -webkit-box-shadow: none; box-shadow: none;">
      <v-container>
        <v-row style="background-color: black">
          <v-img width="1%" aspect-ratio="0.9" src="@/assets/black_metal_man.jpg"
            class="black darken-4 d-none d-sm-block"></v-img>
          <v-col align="center" align-self="center" :cols="contCols" class="pa-10">
            <div class="mb-5">
              <h1>{{ $t('membership.top') }}</h1>
              <h3>{{ $t('membership.topDesc') }}</h3>
              <br />
              <br />
              <h5>
                {{ $t('membership.details') }}
              </h5>
            </div>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field v-model="name" :rules="nameRules" :label="$t('membership.labelName') + '*'" @keydown.enter.prevent="send" required>
              </v-text-field>
              <v-text-field v-model="email" :rules="emailRules" :label="$t('membership.labelMail') + '*'" @keydown.enter.prevent="send" required>
              </v-text-field>
              <v-text-field v-model="username" :label="$t('membership.labelCommunity') + ' (Optional)'" @keydown.enter.prevent="send" ></v-text-field>
              <v-checkbox class="mb-5" v-model="policy" :rules="[(v) => !!v]" :label="$t('membership.labelPolicy')"
                required>
                <template v-slot:label>
                  <div>
                    {{ $t('membership.labelPolicy') }}
                    <a target="_blank" :href="changePolicyLink()" @click.stop v-on="on">{{
                        $t('general.dataPolicy')
                    }}</a>
                  </div>
                </template>
              </v-checkbox>
              <v-row no-gutters>
                <v-col align-self="left" align="left" cols="1" md="2" sm="1" class="mr-5">
                  <v-img width="50px" src="@/assets/metalhand.png"></v-img>
                </v-col>
                <v-col align="left" align-self="center" vertical-align="middle">
                  <v-btn :disabled="!valid" color="orange" @click="send" width="200px">{{ $t('general.beMember') }}</v-btn>
                </v-col>
              </v-row>
              <!--<v-btn color="error" class="mr-4" @click="reset">Zurücksetzen</v-btn>-->
            </v-form>
          </v-col>
        </v-row>
        <v-row style="height: 100px; background-color: #1E1E1E" class="text-md-h6">
          <v-col align="center" align-self="top">
            <v-icon class="mr-2 d-none d-md-block" x-large>mdi-account-group</v-icon>
            <v-icon class="mr-2 d-md-none">mdi-account-group</v-icon>
            <div class="d-md-none">
              <br />
            </div>
            {{ $t('general.association') }}: {{ this.club }}
          </v-col>
          <v-col align="center" align-self="top">
            <v-icon class="mr-2 d-none d-md-block" x-large>mdi-account-multiple</v-icon>
            <v-icon class="mr-2 d-md-none">mdi-account-multiple</v-icon>
            <div class="d-md-none">
              <br />
            </div>
            Community: {{ this.member }}
          </v-col>
          <v-col align="center" align-self="top" v-if="this.fbFans != 0">
            <v-icon class="mr-2 d-none d-md-block" x-large>mdi-facebook</v-icon>
            <v-icon class="mr-2 d-md-none">mdi-facebook</v-icon>
            <div class="d-md-none">
              <br />
            </div>
            Facebook: {{ this.fbFans }}
          </v-col>
          <!--
      <v-col align="center" align-self="center">
        <v-icon class="mr-2" x-large>mdi-calendar-clock</v-icon>
        Stand:
        {{ currentDateTime() }}
          </v-col>-->
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    isMobile: false,
    contCols: 7,
    alignments: ["center"],
    labels: ["2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022"],
    value: [200, 675, 410, 390, 310, 460, 250, 240],
    valid: false,
    name: "",
    username: "",
    nameRules: [(v) => !!v],
    email: "",
    emailRules: [(v) => !!v, (v) => /.+@.+\..+/.test(v)],
    policy: false,
    club: '∞',
    member: '∞',
    fbFans: '0',
    /*
    select: null,
    items: [
      'Item 1',
      'Item 2',
      'Item 3',
      'Item 4',
    ]*/
  }),
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    changePolicyLink() {
      if (this.$i18n.locale === "de") {
        return "https://community.metalcrew.de/privacy-policy/";
      } else {
        return "https://community.metalcrew.net/privacy-policy/";
      }
    },
    onResize() {
      this.isMobile = window.innerWidth < 600;
      if (this.isMobile === true) this.contCols = 0;
      else this.contCols = 7;
    },
    send() {
      let valid = this.$refs.form.validate();
      if (valid) {
        const formData = new FormData();
        formData.append("name", this.name);
        formData.append("username", this.username);
        formData.append("email", this.email);
        formData.append("policy", this.policy);
        formData.append("lang", this.$i18n.locale);
        axios
          .post("https://" + window.location.hostname + `/app/club-member-signup.php`, formData)
          .then((response) => {
            if (response.data === "Sent") {
              this.$store.commit("showNotification", {
                snackbarColor: "success",
                snackbarTitle: this.$i18n.t('membership.success'),
              });
              this.reset();
            } else {
              this.$store.commit("showNotification", {
                snackbarColor: "error",
                snackbarTitle: this.$i18n.t('message.error'),
              });
            }
            //console.log(response);
          })
          .catch(() => {
            this.$store.commit("showNotification", {
              snackbarColor: "error",
              snackbarTitle: this.$i18n.t('membership.error'),
            });
          });
      }
    },
    reset() {
      this.$refs.form.reset();
    } /*
    resetValidation() {
      this.$refs.form.resetValidation()
    },*/,
    currentDateTime() {
      const current = new Date();
      const dd = (current.getDate() < 10 ? "0" : "") + current.getDate();
      const MM =
        (current.getMonth() + 1 < 10 ? "0" : "") + (current.getMonth() + 1);
      const yyyy = current.getFullYear();
      const date = dd + "." + MM + "." + yyyy;
      const hh = (current.getHours() < 10 ? "0" : "") + current.getHours();
      const mm = (current.getMinutes() < 10 ? "0" : "") + current.getMinutes();
      const ss = (current.getSeconds() < 10 ? "0" : "") + current.getSeconds();
      const time = hh + ":" + mm + ":" + ss;
      const dateTime = date + " " + time + " Uhr";
      return dateTime;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    //console.log(this.$vuetify.breakpoint.md);
    axios
      .get("https://" + window.location.hostname + "/app/post.php?member-count")
      .then((response) => {
        this.club = response.data[0].club;
        this.member = response.data[0].member;
      })
      .catch(() => {
        this.$store.commit("showNotification", {
          snackbarColor: "error",
          snackbarTitle: this.$i18n.t('membership.statsError'),
        });
        this.club = "∞";
        this.member = "∞";
        // Manage the state of the application if the request has failed
        //console.log(err);
      });
    axios
      .get("https://" + window.location.hostname + "/app/fb/graph-api.php")
      .then((response) => {
        //console.log(response.data);
        this.fbFans = response.data;
      })
      .catch(() => {
        /*this.$store.commit("showNotification", {
          snackbarColor: "error",
          snackbarTitle: this.$i18n.t('membership.facebookError'),
        });*/
        this.fbFans = "0";
        // Manage the state of the application if the request has failed
        //console.log(err);
      });
  },
};
</script>

<style scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}

.stats {
  font-size: 18px;
}
</style>