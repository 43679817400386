/* TODO: Translate Memberarea */

<template>
  <v-row class="mt-12 mx-auto mb-10" align="center" justify="center">
    <v-col cols="12" sm="8" lg="4">
      <v-card class="elevation-12">
        <v-toolbar color="orange" dark flat>
          <v-toolbar-title>{{ $t('login.toolbarTitle') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-progress-linear
            color="lime"
            indeterminate
            v-if="this.loading"
          ></v-progress-linear>
          <v-form>
            <v-text-field
              :label="$t('login.username')"
              name="login"
              prepend-icon="person"
              type="text"
              @keydown.enter="login()"
              :rules="usernameRules"
              v-model="loginUsername"
            />

            <v-text-field
              id="password"
              :label="$t('login.password')"
              name="password"
              prepend-icon="lock"
              type="password"
              @keydown.enter="login()"
              :rules="passwordRules"
              v-model="loginPassword"
            />
          </v-form>
          <v-card-actions>
            <facebook-login
              class="button"
              appId="641941559276361"
              loginLabel="Facebook Login"
              logoutLabel="Facebook Login"
              @login="onLogin"
              @logout="onLogin"
              @sdk-loaded="sdkLoaded"
            ></facebook-login>
            <v-spacer />
            <v-btn color="orange" :disabled="!dataAvailable" @click="login">Login</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
      <a :href="changeLostPWLink()" target="_blank">{{ $t('login.lostPassword') }}</a> - 
      <a :href="changeChangePWLink()" target="_blank">{{ $t('login.changePassword') }}</a>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import functions from "../../functions.js";
import facebookLogin from "facebook-login-vuejs";

export default {
  components: {
    facebookLogin
  },
  data() {
    return {
      // FB stuff
      isConnected: false,
      //name: "",
      loading: false,
      email: "",
      //personalID: "",
      //picture: "",
      FB: undefined,
      // Normal stuff
      color: "error",
      mode: "vertical",
      snackbar: false,
      text: "Allgemeiner Fehler",
      timeout: 6000,
      x: null,
      y: "top",
      loginUsername: "",
      loginPassword: "",
      usernameRules: [
        v => !!v || ""//"Der MetalCrew Community Benutzername ist erforderlich"
      ],
      passwordRules: [
        v => !!v || ""//"Das MetalCrew Community Passwort ist erforderlich"
      ]
    };
  },
  methods: {
    changeChangePWLink() {
      if (this.$i18n.locale === "de") {
        return "https://community.metalcrew.de/account-management/";
      } else {
        return "https://community.metalcrew.net/account-management/";
      }
    },
    changeLostPWLink() {
      if (this.$i18n.locale === "de") {
        return "https://community.metalcrew.de/lost-password/";
      } else {
        return "https://community.metalcrew.net/lost-password/";
      }
    },
    // FB stuff
    getUserData() {
      console.log("FB GET");
      this.FB.api("/me", "GET", { fields: "id,name,email,picture" }, user => {
        //this.personalID = user.id;
        this.email = user.email;
        //this.name = user.name;
        //this.picture = user.picture.data.url;
        const formData = new FormData();
        formData.append("fbLogin", user.email);
        axios
          .post("https://" + window.location.hostname + `/app/login_association.php`, formData)
          .then(response => {
            if (response.data.auth === true) {
              this.$store.commit("login", {
                userid: response.data.userid,
                username: response.data.username,
                avatar: response.data.avatar
              });
              this.$store.commit("showNotification", {
                snackbarColor: "orange",
                snackbarTitle:
                  this.$i18n.t('login.welcome') + " " +
                  this.drinkSpokeGenerator()
              });
              window.localStorage.setItem("wsc-1267309144-user-consent", "all") // Needed for cookie YouTube
              this.$router.push("internal");
            } else {
              this.$store.commit("showNotification", {
                snackbarColor: "error",
                snackbarTitle:
                  "Your Facebook login cannot be assigned to a MetalCrew membership"
              });
              this.isConnected = false;
            }
          })
          .catch(() => {
            this.$store.commit("showNotification", {
              snackbarColor: "error",
              snackbarTitle:
                "The login could not be performed due to a network error. Please try again later."
            });
            this.isConnected = false;
            // Manage the state of the application if the request has failed
            //console.log(err);
          });
      });
    },
    sdkLoaded(payload) {
      if (payload.isConnected) this.isConnected = payload.isConnected;
      if (payload.FB) this.FB = payload.FB;
      if (this.isConnected) this.getUserData();
    },
    onLogin() {
      this.loading = true
      this.isConnected = false;
      this.email = "";
      if (this.FB != undefined) {
        this.isConnected = true; // Set to false if logout -> Not needed
        this.getUserData();
        console.log("FB Login");
        //console.log(name);
      } else {
        this.$store.commit("showNotification", {
          snackbarColor: "error",
          snackbarTitle:
            "A new Facebook login must be initiated. The page is reloaded in 3 seconds."
        });
        setTimeout(function () { window.document.location.reload(true); }, 3000);
      }
    },
    // Normal functions
    login() {
      if (this.loginUsername != "" && this.loginPassword != "") {
        this.loading = true;
        const formData = new FormData();
        formData.append("loginUsername", this.loginUsername);
        formData.append("loginPassword", this.loginPassword);
        axios
          .post("https://" + window.location.hostname + `/app/login_association.php`, formData)
          .then(response => {
            if (response.data.auth === true) {
              this.$store.commit("login", {
                userid: response.data.userid,
                username: response.data.username,
                avatar: response.data.avatar
              });
              this.$store.commit("showNotification", {
                snackbarColor: "orange",
                snackbarTitle:
                  this.$i18n.t('login.welcome') + " " +
                  this.drinkSpokeGenerator()
              });
              window.localStorage.setItem("wsc-1267309144-user-consent", "all") // Needed for cookie YouTube
              this.$router.push("internal");
              this.loading = false
            } else {
              this.$store.commit("showNotification", {
                snackbarColor: "error",
                snackbarTitle: this.$i18n.t('login.wrongLogin')
              });
              this.loading = false
            }
          })
          .catch(() => {
            this.$store.commit("showNotification", {
              snackbarColor: "error",
              snackbarTitle:
               this.$i18n.t('login.networkError')
            });
            this.loading = false
            // Manage the state of the application if the request has failed
            //console.log(err);
          });
      }
    },
    drinkSpokeGenerator() {
      const rand = functions.getRandomInt(0, 4);
      return this.$i18n.t('login.drunkSpoke' + rand);
    }
  },
  computed: {
    dataAvailable() {
      return (
        this.loginUsername !== null &&
        this.loginUsername !== "" &&
        this.loginPassword !== null &&
        this.loginPassword !== ""
      );
    }
  },
  mounted() { }
};
</script>