<template>
  <v-alert style="background-color: #1E1E1E !important; border-radius: 4px;">
    <v-form ref="newsletter" v-model="valid" lazy-validation class="justify-center">
      <v-row class="ml-1">
        <v-col height="10px">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="Newsletter"
            placeholder="E-Mail*"
            required
            prepend-icon="mdi-mailbox"
            @keydown.enter.prevent="send"
          ></v-text-field>
        </v-col>
        <v-col class="mt-4">
          <v-btn :disabled="!valid" color="orange" @click="send" width="125px">{{ $t('general.subscribe') }}</v-btn>
        </v-col>
      </v-row>
      <!--<v-btn color="error" class="mr-4" @click="reset">Zurücksetzen</v-btn>-->
    </v-form>
  </v-alert>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    valid: false,
    isMobile: false,
    contCols: 7,
    email: "",
    emailRules: [(v) => !!v, (v) => /.+@.+\..+/.test(v)],
    alignments: ["center"],
  }),
  methods: {
    send() {
      let valid = this.$refs.newsletter.validate();
      if (valid) {
        const formData = new FormData();
        formData.append("email", this.email);
        axios
          .post("https://" + window.location.hostname + `/app/newsletter-signup.php`, formData)
          .then((response) => {
            if (response.data === "found") {
              this.$store.commit("showNotification", {
                snackbarColor: "success",
                snackbarTitle: "Anmeldung bereits durchgeführt.",
              });
              this.reset();
            } else {
              this.$store.commit("showNotification", {
                snackbarColor: "success",
                snackbarTitle: "Erfolgreich angemeldet. Dankeschön!",
              });
              this.reset();
            }
          })
          .catch(() => {
            this.$store.commit("showNotification", {
              snackbarColor: "error",
              snackbarTitle:
                "Newsletter konnte aufgrund eines Netzwerksfehler nicht abonniert werden.",
            });
          });
      }
    },
    reset() {
      this.$refs.newsletter.reset();
    } /*
    resetValidation() {
      this.$refs.form.resetValidation()
    },*/,
    currentDateTime() {
      const current = new Date();
      const dd = (current.getDate() < 10 ? "0" : "") + current.getDate();
      const MM =
        (current.getMonth() + 1 < 10 ? "0" : "") + (current.getMonth() + 1);
      const yyyy = current.getFullYear();
      const date = dd + "." + MM + "." + yyyy;
      const hh = (current.getHours() < 10 ? "0" : "") + current.getHours();
      const mm = (current.getMinutes() < 10 ? "0" : "") + current.getMinutes();
      const ss = (current.getSeconds() < 10 ? "0" : "") + current.getSeconds();
      const time = hh + ":" + mm + ":" + ss;
      const dateTime = date + " " + time + " Uhr";
      return dateTime;
    },
  },
};
</script>

<style scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
.stats {
  font-size: 18px;
}
</style>