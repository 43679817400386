<template>
  <v-container class="text-center">
    <h1 class="display-1 font-weight-bold mb-1">MetalCrew - True Home For Metal!</h1>
    <h2>{{ $t('home.h2') }}</h2>
    <p class="subheading font-weight-regular mt-10 mb-10">
      {{ $t('home.subheading') }}
    </p>
    <v-row class="text-center" justify="center">
      <v-col align="center">
        <h1 class="display-1 font-weight-bold mb-1">MetalCrew Kultur e.V.</h1>
        <h3 class="mb-10">{{ $t('home.associationDesc') }}</h3>
        <router-link :to="{name: 'membership'}" title="Werde Mitglied im MetalCrew Kultur e.V." class="noUnderscore">
          <v-img height="250px" width="500px" src="@/assets/be_member.jpg" align="center">
            <v-btn color="orange" :to="{name: 'membership'}" style="margin-top: 200px">{{ $t('general.beMember') }}</v-btn>
          </v-img>
        </router-link>
        <p class="subheading font-weight-regular mt-10 pl-5 pr-5">
          {{ $t('home.associationDetail1') }}
          <br />
          <br />{{ $t('home.associationDetail2') }}
        </p>
        <p>
          {{ $t('home.associationDetail3') }}
        </p>
      </v-col>
      <v-col align="center">
        <h1 class="display-1 font-weight-bold mb-1">MetalCrew Community</h1>
        <h3 class="mb-10">{{ $t('home.communityDesc') }}</h3>
        <a :href="localeLink('https://community.metalcrew.de', 'https://community.metalcrew.net')"
          title="Auf zur MetalCrew Community!" target="_blank" align-self="center" class="noUnderscore">
          <v-img height="250px" width="500px" src="@/assets/community.jpg" align="center">
            <v-btn color="orange" :href="localeLink('https://community.metalcrew.de', 'https://community.metalcrew.net')"
              target="_blank" style="margin-top: 200px">Community</v-btn>
          </v-img>
        </a>
        <p class="subheading font-weight-regular mt-10 pl-5 pr-5">
          {{ $t('home.communityDetail1') }}
          <br />
          <br />{{ $t('home.communityDetail2') }}
        </p>
        <p>{{ $t('home.communityDetail3') }}</p>
      </v-col>
    </v-row>
    <!--
    <v-row class="text-center">
      <v-col>
        <p class="subheading font-weight-regular mt-5 pl-5 pr-5">
          Schau auch gerne bei unserem Festival vorbei:
          <br />
          <a href="https://crewsade.de" target="_blank">
            <v-img
              src="https://www.crewsade.de/wp-content/uploads/2020/07/cropped-Crewsade-Logo.png"
              class="my-3"
              contain
              height="100"
              title="Crewsade of Metal"
              alt="Crewsade of Metal"
            />
          </a>
        </p>
      </v-col>
    </v-row>-->
  </v-container>
</template>

<script>
export default {
  data: () => ({
  }),
  methods: {
    localeLink(itemDE, itemEN) {
      if (this.$i18n.locale == "en") {
        return itemEN
      }
      else {
        return itemDE;
      }
    }
  }
};
</script>

<style scoped>
.noUnderscore {
  text-decoration: none !important;
}
</style>