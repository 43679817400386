<template>
    <v-container>
        <v-row class="text-center" style="margin-bottom: -30px">
            <v-col>
                <v-img src="@/assets/statute.jpg" class="grey darken-4"></v-img>
            </v-col>
            <v-col cols="12" v-html="$t('statute.fulltext')">
            </v-col>
        </v-row>
        <v-row>
            <v-col align="center">
                <v-alert style="background-color: #1E1E1E !important; border-radius: 4px;">
                    <a :href='localeLink("https://community.metalcrew.de/thread/12801/", "https://community.metalcrew.net/thread/12801/")' target="_blank">
                        <em>03.02.2024</em>
                     </a>
                </v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    data: () => ({
        overlay: false
    }),
    methods: {
        toggleFullscreen: function () {
            this.fullscreen = !this.fullscreen;
        },
        localeLink(itemDE, itemEN) {
            if (this.$i18n.locale == "en") {
                return itemEN
            }
            else {
                return itemDE;
            }
        }
    }
}
</script>