const titleBase = "MetalCrew Kultur e.V. Pfaffenhofen a. d. Ilm";

export default {
    general: {
        association: 'Association',
        calendar: 'Calendar',
        songsOfTheWeek: 'Songs of the week',
        musictip: 'Music tip',
        entertainment: 'Entertainment',
        guessGame: 'Guess Game',
        highscore: 'Highscore',
        beMember: 'Become a member',
        beHelper: 'Become a helper',
        subscribe: 'Subscribe',
        dataPolicy: 'Privacy policy',
        imprint: 'Imprint',
        changeLocale: 'Sprache auf Deutsch ändern',
        toEvent: 'To the event',
        close: 'Close',
        hint: 'Hint',
        comments: 'Comments',
        say: 'say',
        loading: 'Wird geladen',
        share: 'Share',
        externalLinks: 'External links',
        settings: 'Settings'
    },
    menu: {
        leader: 'Executive',
        statute: 'Statute',
        donation: 'Donation',
        extremism: 'Violence',
        aboutUs: 'About us',
        login: 'Association area',
        crewsade: 'Crewsade of Metal',
        mcLive: 'MetalCrew Live',
        openPark: 'Open Park PFA\'HOFA',
        beMember: 'Become a member!',
        shop: 'E-Shop',
        events: 'Events',
        oldEvents: 'Past',
        festivals: 'Festivals',
        community: 'Community',
        metalCrew: 'MetalCrew',
        overview: 'Overview',
        coming: 'Coming',
        support: 'Support',
        raffles: 'Raffles'
    },
    home: {
        description: 'True Home For Metal! The only true non-profit association for hard sounds in the beautiful Pfaffenhofen a. d. Ilm.',
        h1: 'MetalCrew - True Home For Metal!',
        h2: 'The association and the community',
        subheading: 'From the 2 years longer existing Bavarian Metalheads Community arose the dream to organize festivals. This is now done by the registered association, which works closely with the community until today.',
        associationDesc: 'The backbone',
        communityDesc: 'The heart',
        associationDetail1: 'The MetalCrew Kultur e.V. is a 2017 in the district court Ingolstadt\
        registered association. As a goal, the association was created to\
        fully support the community. This is expressed as\
        legal body for the organization of festivals and other\
        events. Also financially and organizationally for the operation of the MetalCrew Community we are steadfast.',
        communityDetail1: 'Founded in 2015, the MetalCrew Community is a community of rock and metal fans in the 3-digit range with origins in Bavaria and members from all over Germany and Austria, whose ideal is to connect music fans through actions such as meetings at local concerts, joint activities and an internet forum.',
        associationDetail2: 'Part of us can be every committed person who is willing to\
        to make even a small contribution. There are many opportunities to do so.',
        communityDetail2: 'The entry into the community is possible for everyone free of charge. We are always happy about a lively exchange on current topics of the scene.',
        associationDetail3: 'See the terms and conditions for joining the membership via upper\
        button.',
        communityDetail3: 'Just visit our community website via the button above.',
    },
    login: {
        title: 'Association area - ' + titleBase,
        description: 'The area for all club members of the MetalCrew Kultur e.V. have here the possibility to access the internal backstage area.',
        toolbarTitle: 'Backstage area for association members',
        wrongLogin: 'Your login data is incorrect',
        networkError: 'The login could not be performed due to a network error. Please try again later',
        username: 'Username',
        password: 'Password',
        welcome: 'Hello VIP! Good to see you!',
        drunkSpoke0: "How about a Jacky from Backstage today?",
        drunkSpoke1: "Jägermeister!!! Freshly arrived backstage today!",
        drunkSpoke2: "Captain Morgan! Awaken the captain in you! Proost!",
        drunkSpoke3: "Have you brushed your teeth today? Time for a peppi!!!",
        drunkSpoke4: "Today we drink first of all a Willy on you!",
        drunkSpoke5: "The last party here at Backstage was hard.... Pain in the head",
        logout: 'Successfully logged out. See you soon & Stay Heavy!',
        logoutBtn: 'Exit',
        lostPassword: 'Lost your password?',
        changePassword: 'Change password'
    },
    calendar: {
        start: 'Start',
        end: 'End',
        location: 'Location',
        details: 'Details',
        today: 'Today',
        day: 'Day',
        week: 'Week',
        month: 'Month',
        days4: '4 Days',
        addTo: 'Add to calendar'
    },
    guessGame: {
        highscore: 'Top 50 Guess Game Highscore',
        place: 'Place',
        points: 'Points',
        statistics: 'MC Player Statistics',
        statsRight: 'Right answers',
        statsWrong: 'Wrong answers',
        statsPoints: 'Score',
        vote: 'Vote',
        next: 'Next',
        exit: 'Quit',
        questionMode1: "What album is this cover from?",
        questionMode2: "What song are these lyrics from?",
        rightAnswer: "Brutal! You're answer was right!",
        wrongAnswer: "Sorry, that was not the correct answer for a Metalhead.."
    },
    settings: {
        notifications: 'Notifications',
        notificationDesc: 'Herewith notifications can be activated in case of news',
        notificiationsActive: 'The notifications are activated for you!',
        notificationsBlocked: 'Notifications are blocked on your system!',
        notificationsNotSupported: "Notifications are not supported on your system!"
    },
    internal: {
        today: 'Today is the',
        home: `
        <h7
        >Here you are in the exclusive MetalCrew Backstage area for
        assosication members. All important articles of the association and the community can be viewed quickly here. In addition to the calendar of events, there is also
        the opportunity with the challenging lyrics and album guessing game
        to prove personal skills.</h7
        >
        <br /><br />
        <h7 class="mt-5">🥳 We would like to thank you for your membership in the MetalCrew Kultur e.V. Pfaffenhofen! You've got a strong heart! 🥳</h7>
        <br /><br />
        <h7 class="mt-5"
            >You create new festivals for metal, rock, punk and others
            alternative genres take place. Young artists and newcomers get performance opportunities through you. Also, you support one
            unique community of metalheads and rockers. A place to connect and to be. </h7
        >
        <br /><br />
        <h7 class="mt-5"
            >If there are any questions, your board member of MetalCrew Kultur e.V. is always available
            under the email
            <a href="mailto:verein@metalcrew.de">verein@metalcrew.de</a> for you
            reachable.</h7
        >
        <br /><br />
        <h7 class="mt-5">Please understand that we currently offer the content only largely in german.</h7>
        <br /><br />
        <h7 class="mt-5">🤘🤘🤘 Stay Heavy! 🤘🤘🤘</h7>
        <br /><br />
        <h7 class="mt-5">- Vanessa, Joe, Simon & \\M/ihe</h7>
        `,
        


    },
    leader: {
        title: 'Executive - ' + titleBase,
        description: 'Who is behind the MetalCrew Kultur e.V.? Find out and get to know the board on this page.',
        character: 'Character',
        class: 'Class',
        class1: '1st board',
        class2: '2nd executive board',
        class3: 'Secretary',
        class4: 'Treasurer',
        description1: 'The last active founding member of the community and on the board since the beginning. Always wholeheartedly involved in the association and its goals. A heavy rocker!',
        description2: 'She joined the club in 2018 and exactly 3 years later she is also a member of the board. When she is not on gigs and medieval markets on the road,  she works full-time as a curative education nurse. Represents the core and stoner rock faction.',
        description3: 'An original member and admin of the community. Also founding and to date existing board member as secretary. Musically there is only Trve Metal!',
        description4: 'When it comes to money, Joe! Black metal lover and connoisseur of the underground of the scene. Since 2019 active as treasurer.'
    },
    event: {
        description: 'All club outings and other events are announced here. Experience something with us and stay up to date!',
        descriptionOld: 'All past club outings and other events will be announced here. Experience something with us and stay up to date!',
        descriptionRaffles: 'All sweepstakes will be announced here. Experience something with us and stay up to date!',
        descriptionSupport: 'We are always looking for helpers for our events. Are you dedicated and do you like alternative music? Come by!',
        titleUpcoming: 'Upcoming Events - ' + titleBase,
        titlePast: 'Past Events - ' + titleBase,
        titleRaffles: 'Raffles - ' + titleBase,
        inChat: "Into the chat",
        buyTicket: "Buy ticket",
        inMemberarea: "Into the memberarea",
        registerEvent: "Register for the event",
        moreInfo: "More informations",
        dateTo: 'to',
        upcoming: "Upcoming",
        past: "Past",
        loading: "Loading event data",
        loadingError: `
        Event data cannot be displayed at this time.
        <br />
        <br />Please try again later or contact the board of directors
        at <a href="mailto:vorstand@metalcrew.de">vorstand@metalcrew.de</a>.
        `
    },
    footer: {
        organize: 'We organize the',
        desc: 'The MetalCrew Kultur e.V. is non-profit and passionate about supporting the alternative music scene',
        mailto: 'Mail to the assocation team' // not used
    },
    donation: {
        title: 'Donation - ' + titleBase,
        description: 'MetalCrew Kultur e.V. is a non-profit organization and relies on voluntary donations. See here for more information.',
        topDesc: 'Every non-profit organization depends on donations. So do we.',
        lowerDesc: 'Donations to nonprofits have a positive and lasting impact on our society. Through a donation we can promote culture, help with integration, support artists and much more. As nonprofits, we also see our mission as addressing social problems and bringing about positive change.',
        bottomDesc: 'Making a donation helps create a better future for all of us. Every amount counts and can make a big difference.',
        bottomAction: 'Let\'s do good together and make a contribution to make the world a better place. Rocking with style and class!',
        payPalDonation: 'Donate via PayPal',
        bankConnection: 'Bank connection',
        owner: 'Owner'
    },
    extremism: {
        title: 'Violence - ' + titleBase,
        discription: 'The MetalCrew Kultur e.V. is for friendly rocking together without extremist violence and exclusion.',
        fulltext: `
        <p>Extremism in any form harms our society and threatens fundamental values such as tolerance and diversity.</p>
        <p>It is crucial to work together to promote a social atmosphere of respect and understanding.</p>
        <div style="background-color: #1E1E1E;">
            <p>"Violence is the fear of the ideals of others"<br><small><i>Mohandas Karamchand Gandhi * 1869 † 1948</i></small></p>
        </div>
        <p>The MetalCrew Kultur e.V. believes that all forms of extremism are unacceptable.</p>
        <p><b>Rock without extremist violence! With each other, for each other and with love!</b></p>
        <p>As an association, we implement the aforementioned values as our top priority in everyday life and club life.</p>
        <p>Through our charitable and benevolent actions, we set an example for positive change.</p>
        `
    },
    statute: {
        title: 'Statute - ' + titleBase,
        description: 'All policies and bylaws are summarized on this page. Feel free to look at everything before you become a member.',
        fulltext: `
        <h1>Association statute </h1>
        <h3>Metalcrew Kultur e.V. </h3>
        <br>
        <p> <strong> § 1 Name, seat, registration, financial year </strong> </p>
        <p>1. The association bears the name Metalcrew Kultur e.V. <br>2. It is based in 85276 Pfaffenhofen
            a.d. Ilm, Germany<br>3. It should be entered in the register of associations. <br>4. The financial year is the calendar year.</p>
        <p> <strong> § 2 Association of Club </strong> </p>
        <p> Purpose of the association is the promotion of art and culture, in particular the promotion of alternative
            Music styles and the promotion of young newcomer bands. </p>
        <p>The purpose of the articles of association is in particular realized by: </p>
        <p> · Support of the exchange between fans of alternative music via an internet platform and
            A chat platform for smartphones </p>
        <p>· Organization of benefit concerts. Here, the strong inclusion is particularly disabled
            Feders promoted. </p>
        <p>· offer of concerts & amp; Festivals with free admission </p>
        <p>· Support and cooperation with aid organizations </p>
        <p></p>
        <p><strong> § 3 Selflessness </strong> </p>
        <p>The association pursues exclusively and directly non -profit, charitable and church purposes
            (in the sense of the section &#8220; tax -privileged purposes of the tax code). <br> The association is
            selflessly active; He does not primarily pursue self -economic purposes. Means of the association
            may only be used for the statutory purposes. The members receive in their
            Property as members no benefits from the association's funds. No person is allowed through
            Expenditures that are foreign to the purpose of the corporation or by disproportionately high remuneration
            be favored. </p>
        <p> <strong> § 4 Membership </strong> </p>
        <p> (1) Member of the association can become any natural (and legal) person who
            supported. </p>
        <p> (2) The board decides on the application for admission to the association. </p>
        <p> (3) Membership ends through exit, exclusion or death. </p>
        <p> (4) A member leakage is only possible at the end of a month. He takes place through
            Written declaration to the board in compliance with a period of 1 month. </p>
        <p> (5) If a member has seriously violated the goals and interests of the association or despite
            Reminder with the contribution for 1 month remains in arrears, so it can be done by the board
            to be excluded immediately. </p>
        <p> The member must give the opportunity to justify or comment before the decision
            become. <br> against the exclusion decision can be made within a period of 2 weeks after
            Notification of the exclusion of an appeal, through which the next general meeting
            Decide. </p>
        <p> (6) If a member of the association either donates an amount of at least 1,000 euros or
            gives donations over a period of 10 years, whose total value in total 1
            000 euros reached, this member is granted the rank as an honorary member. Honorary members
            are freed from all membership fees for the duration of their membership. </p>
        <p> (7) If a member performs outstanding services for the association, the board can
            Vote to propose this member for a survey to the honorary member's rank. Speaks
            the board of directors unanimously for such a proposal, the corresponding member becomes at
            the next general assembly of the association for the members present there for the appointment as
            Honorary member proposed. If a majority of the members present at the general assembly are correct
            For the appointment, the proposed member is awarded the rank as an honorary member. </p>
        <p> </p>
        <p> <strong> § 5 Contributions </strong> </p>
        <p> The members pay contributions in accordance with a decision of the general assembly. To the
            Determination of the amount of contributions and the description is a simple majority of the in the
            General assembly of voting members who are entitled to vote required. </p>
        <p> <strong> § 6 Organs of the association </strong> </p>
        <p> organs of the association are <br> a) the board of directors <br> b) the general meeting </p>
        <p><strong>§ 7 The Executive Board</strong></p>
        <p>(1) The board consists of 4 members:</p>
        <p>· 1. Board of Directors</p>
        <p>· 2. Board of Directors</p>
        <p>· Secretary</p>
        <p>· Treasurer</p>
        <p>He represents the association in and out of court. Each member of the Management Board is authorized to represent the company individually.</p>
        <p>(2) The board of directors is elected by the general assembly for a period of 1 year. The re-election
            of board members is possible. The chairman is appointed by the general meeting in one
            special ballot determined. The incumbent members of the Board of Management remain after their expiration
            Term in office until successors are elected.</p>
        <p>(3) The board is responsible for managing the day-to-day business of the association. He has in particular
            following tasks: The executive committee can for the business of the current administration one
            appoint directors. This is entitled to participate in the meetings of the Board of Directors with advisory
            vote to participate.</p>
        <p>(4) Board meetings are held at least twice a year. The invitation to board meetings
            is carried out by a member of the Management Board in writing, observing an invitation period of
            at least 30 days.</p>
        <p>(5) The Board of Directors takes its decisions with a simple majority.</p>
        <p>(6) In the case of urgency, resolutions of the Executive Board can also be passed in writing or by telephone
            if all board members give their consent to this procedure in writing or
            explain by phone. Executive Board resolutions made in writing or by telephone are in writing
            to deposit and sign off.</p>
        <p>(7) The Management Board may receive appropriate remuneration for its work.</p>
        <p><strong>§ 8 General Assembly</strong></p>
        <p>(1) The general meeting is to be convened once a year.</p>
        <p>(2) An extraordinary general meeting is to be convened if it is in the interest of the association
            requires or if the convocation of 20% of the association members in writing and stating the
            purpose and reasons is required.</p>
        <p>(3) The General Assembly is convened in writing by a member of the Executive Board
            while maintaining an invitation period of at least 4 weeks with simultaneous notification of the
            Agenda. The period begins on the day following the sending of the letter of invitation. It
            the date of the postmark applies. The letter of invitation is deemed to have been received by the member when it
            is addressed to the last address given in writing by the member of the association.</p>
        <p>(4) The general assembly as the supreme decision-making body of the association is fundamentally for
            responsible for all duties, except where certain duties under these statutes are assigned to another
            were transferred to the association body.<br> In particular, the annual accounts and the annual report are to them
            to be submitted in writing for the resolution on the approval and the discharge of the Executive Board.
            It appoints two auditors who are neither members of the board nor a body appointed by the board
            belong and may not be employees of the association, including the bookkeeping
            Check the annual accounts and report on the results before the general meeting.<br> The
            General meeting decides z. B. also about<br> a) Exemption from fees,<br> b) Tasks of
            association,<br> c) purchase and sale as well as encumbrance of property,<br> d) participation in
            Companies,<br> e) Taking out loans from EUR 5000,<br> f) Approval of all
            Rules of procedure for the association area,<br> g) membership fees,<br> h) amendments to the articles of association,<br>
            i) Dissolution of the association.</p>
        <p>(5) Every general meeting convened in accordance with the articles of association is recognized as having a quorum without
            Consideration of the number of club members present. Each member has one vote.</p>
        <p>(6) The General Assembly passes its resolutions with a simple majority. In the event of a tie
            an application is considered rejected.</p>
        <p>(7) The general meeting may also be held by means of electronic communication (e.g. as a telephone or video conference) 
        or in a mixed meeting of present and video conference / other media / telephone be conducted. Whether the general meeting
        is held in a meeting or by electronic communication or in a mixed meeting of those present and videoconference / other media / telephone,
        shall be decided by the Board.</p>
        <p>(8) The Board may also obtain resolutions of the General Assembly by written procedure. Resolutions by written procedure are adopted if there is a simple majority.</p>.
            <p><strong>§ 9 Reimbursement of expenses</strong></p>
            <p>(1) Members - insofar as they have been commissioned by the board - and board members have one
                Right to reimbursement of the expenses incurred in the course of their work for the association
                are. This includes in particular travel expenses, additional subsistence expenses, postage and
                communication costs. </p>
            <p>(2) Evidence is provided via corresponding individual receipts and must be submitted no later than 6 weeks after the end of the
                to assert each quarter. </p>
            <p>(3) Insofar as there are tax flat rates and tax-free maximum limits for the reimbursement of expenses,
                a replacement will only be made in this amount.</p>
            <p><strong>§ 10 Amendment to the Articles of Incorporation</strong></p>
            <p>(1) A 2/3 majority of the members of the association present is required for changes to the statutes. For
                Changes to the purpose of the statutes require a majority of 2/3 of the club members present
                necessary. Amendments to the Articles of Association can only be voted on at the General Assembly if:
                reference was made to this item on the agenda in the invitation to the general assembly
                and both the previous text and the planned new text of the articles of association have been attached to the invitation
                were.</p>
            <p></p>
            <p>(2) Amendments to the Articles of Incorporation by supervisory, judicial or financial authorities for formal reasons
                The board of directors can make such changes on its own initiative. These amendments to the Articles of Association must be
                Association members will be informed in writing as soon as possible.</p>
            <p><strong>§ 11 Certification of resolutions</strong></p>
            <p>The resolutions recorded in board meetings and general meetings are in writing
                to be laid down and signed by the board of directors.</p>
            <p></p>
            <p><strong>§ 12 Dissolution of the association and asset commitment</strong></p>
            <p>(1) For the decision to dissolve the association, a 3/4 majority in the general meeting is required
                members present required. The decision can only be made after a timely announcement in the
                invitation to the general meeting.</p>
            <p>(2) If the association is dissolved or abolished or if tax-privileged purposes no longer apply, this shall not apply
                Assets of the association to a legal entity under public law or another
                tax-privileged corporation for use in the promotion of arts and culture. </p>
        `
    },
    aboutUs: {
        title: 'About us - ' + titleBase,
        description: 'The story of the formation of MetalCrew Kultur e.V. and all other incidents. Compact and hard-hitting!',
        timeline: [
            {
                year: '23.09.2023',
                text: 'Fifth Festival',
                description: 'Our fifth Crewsade club festival welcomed a guest from Israel.',
                img: 'https://www.crewsade.de/wp-content/uploads/2023/09/383732758_697335105755298_1251950368504844542_n.jpg'
            },
            {
                year: '08.07.2023',
                text: 'Second Open Air',
                description: 'Our second Open Park PFA\'HOFA Open Air reached 10.000 visitors.',
                img: 'https://www.open-park.de/wp-content/uploads/2023/07/e881f8b6d00e-29-107658105.jpg'
            },
            {
                year: '24.09.2022',
                text: 'Fourth Festival',
                description: 'Our fourth association festival started a second time in Pfaffenhofen a. d. Ilm.',
                img: 'https://www.crewsade.de/wp-content/uploads/2022/10/crewsade_2022-e1665997826296.jpg'
            },
            {
                year: '10.07.2022',
                text: '50 members',
                description: 'We reached 50 association members for the first time in the MetalCrew Kultur e.V.',
                img: 'https://www.metalcrew.de/community/gallery/userImages/a4/1425-a490e7a3-large.jpg'
            },
            {
                year: '09.07.2022',
                text: 'First Open Air',
                description: 'Premiere of the 2 day Open Park outdoor festival in collaboration with the AWO and Oroborus.',
                img: 'https://www.open-park.de/wp-content/uploads/2022/03/1.jpeg'
            },
            {
                year: '25.09.2020',
                text: 'MetalCrew Live',
                description: 'We started our underground concert series in pandemic times.',
                img: 'https://www.metalcrew.de/f/attachment/187-25-26-09-band-announcement-jpg/'
            },
            {
                year: '28.09.2019',
                text: 'Third Festival',
                description: 'The first festival in our hometown Pfaffenhofen a. d. Ilm in cooperation with the City Youth Care.',
                img: 'https://www.crewsade.de/wp-content/uploads/2019/08/Plakat-Balken-476x476.jpg'
            },
            {
                year: '05.05.2018',
                text: 'Second Festival',
                description: 'The continuation of the Crewsade of Metal Festival with 7 bands in cooperation with the City Youth Care Ebersberg.',
                img: 'https://www.crewsade.de/wp-content/uploads/2019/05/28514747_1606525662771812_3732989265661303777_o-1-476x476.jpg'
            },         
            {
                year: '06.05.2017',
                text: 'First Festival',
                description: 'In cooperation with the Catholic Youth Welfare Munich our first charity Crewsade of Metal Festival with 8 bands took place. We achvied huge inclusion of handicapped peoples.',
                img: 'https://www.crewsade.de/wp-content/uploads/2019/05/412-b5801043-476x476.jpg'
            },
            /*{
                year: '> 2018',
                text: 'CV of the association',
                description: 'The story from the founding of the association is still being written and will be published soon.',
                img: 'https://www.crewsade.de/wp-content/uploads/2019/05/20-e1655bc8bc130fc6e074dcf71d964e5e68f1a28f-125x108.jpg'
            },*/
            {
                year: '12.04.2017',
                text: 'Foundation of MetalCrew Kultur e.V.',
                description: 'The MetalCrew Kultur e.V. is brought into being through the founding meeting in Munich. The first board consists of Mick, Miche, Flo and Simon.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/d1/419-d1eb6857-small.jpeg'
            },
            {
                year: '01.12.2016',
                text: 'First Crew Editor',
                description: 'The post of editor will be created and filled by Flo.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/7d/421-7d7fe89c-small.jpeg'
            },
            {
                year: '22.09.2016',
                text: 'Name of the site',
                description: 'The Internet address www.metalcrew.de will be acquired and the forum can be accessed via it.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/18/10-1807d267-small.jpg'
            },
            {
                year: '12.09.2016',
                text: '1. club raffle',
                description: 'For the first time, a raffle will be organized in the MetalCrew. These will be repeated later.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/29/1081-29b0e81c-small.jpeg'
            },
            {
                year: '17.08.2016',
                text: '1. festival camping',
                description: 'On the Summerbreeze, members of the metal crew meet for the first time on a large scale at a festival. Such meetings later become a tradition.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/0c/274-0c3688ec-small.jpg'
            },
            {
                year: '08/14/2016',
                text: 'Event planning team',
                description: 'A separate department for event planning is founded. As her head, Mick is soon appointed to the post of organizer.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/da/591-dacf2d16-small.jpg'
            },
            {
                year: '19.06.2016',
                text: 'Joe joins the team',
                description: 'Chris is stepping down as admin and will be replaced by Joe.',
                img: 'https://www.crewsade.de/wp-content/uploads/2019/05/39-99b650adf62f2db4a66126657ab3be83e7a9db05-125x125.jpg'
            },
            {
                year: '06/11/2016',
                text: '1st crew event',
                description: 'Geocaching is the first joint event in the MetalCrew, led by Mick.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/fb/179-fb0c7dd1-small.jpg'
            },
            {
                year: '05/11/2016',
                text: '1st merchandise product',
                description: 'For the first time, MetalCrew fan shirts are published. More merchandise will follow later.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/7c/414-7ceebd55-small.jpeg'
            },
            {
                year: '07.03.2016',
                text: 'New MC lettering',
                description: 'The lettering of the MetalCrew will be revised and renewed. Also, the first of several MetalCrew wallpapers will be released.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/86/406-864cd395-small.jpg'
            },
            {
                year: '01/14/2016',
                text: 'Team member Marco\'s entry',
                description: 'With the addition of Marco, the admins are complete again.',
                img: 'https://www.metalcrew.de/community/images/avatars/d5/36-d5437fb572e86321cb110c6a4017ae5f2687530d.jpg'
            },
            {
                year: '01/10/2016',
                text: 'Actual implementation of the ruleset',
                description: 'After extensive revision, a second version of the rules will be implemented in the MetalCrew.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/7d/421-7d7fe89c-small.jpeg'
            },
            {
                year: '28.12.2015',
                text: 'Exit Taisto',
                description: 'Due to the confusion surrounding the introduction of the rulebook, Taisto is stepping down.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/d3/366-d3a97cfa-small.png'
            },
            {
                year: '27.12.2015',
                text: 'Creation of the 1st set of rules',
                description: 'Due to the growth of the group, a set of rules is created and put into effect for the MetalCrew. Due to massive upsets within the community, however, this will be temporarily suspended again on the same day.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/7d/421-7d7fe89c-small.jpeg'
            },
            {
                year: '26.12.2015',
                text: 'Who\'s coming?',
                description: 'Chris returns as admin again.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/05/32-057627cc-small.jpg'
            },
            {
                year: '06.12.2015',
                text: 'Exit of Nathi',
                description: 'Nathi resigns as admin.',
                img: 'https://www.metalcrew.de/community/images/avatars/8c/6-8c9d3499f20e175c0c54ddace7e6c9181c94b69f.jpg'
            },
            {
                year: '02.11.2015',
                text: 'Origin of the gnomes',
                description: 'The MetalCrew\'s first Christmas elf campaign has started.It will be held annually later.',
                img: 'https://i.scdn.co/image/ab67706c0000bebbbf82f60e33358592da5d2bf3'
            },
            {
                year: ' 31.10.2015',
                text: 'Windows Phone App Release',
                description: 'The MetalCrew app for Windows Phone is released.',
                img: 'https://www.metalcrew.de/img/touch-icon-iphone-retina.png'
            },
            {
                year: '31.08.2015',
                text: 'Entrance of Taisto',
                description: 'Chris resigns again. His replacement will be Taisto.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/31/43-31f5ba20-small.jpg'
            },
            {
                year: '30.08.2015',
                text: 'Android App Release',
                description: 'The MetalCrew app for Android is released. Windows Phone app is still under development.',
                img: 'https://www.metalcrew.de/img/touch-icon-iphone-retina.png'
            },
            {
                year: '06/17/2015',
                text: 'First online appearance',
                description: 'After lengthy planning, the group goes online as a forum. The term "MetalCrew" is also used here for the first time.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/18/10-1807d267-small.jpg'
            },
            {
                year: '08.06.2015',
                text: 'Entry of Nathi',
                description: 'Manu resigns as admin and is replaced by Nathi.',
                img: 'https://www.metalcrew.de/community/images/avatars/8c/6-8c9d3499f20e175c0c54ddace7e6c9181c94b69f.jpg'
            },
            {
                year: '08.05.2015',
                text: 'First resignation of a founding member',
                description: 'Chris steps down as admin, but rises on 22.5. on again.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/11/42-11fcd66f-small.jpg',
            },
            {
                year: '28.03.2015',
                text: 'Entry of Simon Hauke',
                description: 'Jojo resigns as admin and is replaced by Simon.',
                img: 'https://www.crewsade.de/wp-content/uploads/2019/05/20-e1655bc8bc130fc6e074dcf71d964e5e68f1a28f-125x108.jpg',
            },
            {
                year: '12.03.2015',
                text: 'First extension of executives',
                description: 'The addition of Jojo increases the number of admins to four.',
                img: 'https://www.metalcrew.de/f/gallery/userImages/86/371-861aab87-small.jpg',
            },
            {
                year: '09.03.2015',
                text: 'Creation of the community',
                description: 'The WhatsApp group is founded by Mihe and Chris. Admin is also Manu.',
                img: 'https://www.crewsade.de/wp-content/uploads/2020/08/WhatsApp-Image-2020-05-31-at-20.22.48-e1597698556410.jpeg',
            }
        ]
    },
    support: {
        topDesc: 'You are socially engaged and are looking forward to see behind the scenes?',
        subLine: 'Then apply now as a helper for the next MetalCrew Kultur e.V. events. The MetalCrew is looking forward to your reinforcement!',
        success: 'Your offer of help has been sent. We will get back to you. Thank you!',
        error: 'Your help request could not be sent successfully due to a network error.'
    },
    membership: {
        description: 'Here is the opportunity to become a part of the best non-profit association for alternative music genres.',
        title: 'Membership - ' + titleBase,
        top: 'Membership in the MetalCrew Kultur e.V.',
        topDesc: 'A unique community with true friendships.',
        details: `
        Life is more fun together, especially with same minded peoples.
        Join your new family.
        Become a member and get access to unique events, meetings and scene
        informations. All that for 6,66€ in a quarter.
        `,
        labelName: 'Your first and last name',
        labelMail: 'Your mail address',
        labelCommunity: 'Community nickname',
        labelPolicy: 'I aggree to the',
        success: 'Your membership form got successfully sent. Please check your mails.',
        error: 'The membership request could not be sent successfully due to a network error.',
        statsError: 'Can\'t show statistical data right now',
        facebookError: 'Can\'t show facebook data right now'
    },
    message: {
        error: 'An error happened. Please try again later.',
        networkError: 'The data could not be loaded due to a network error. Please try again later.',
        noNetwork: 'No network connection',
        noSelection: 'Selection not available',
        copyClipboard: 'Successfully copied into clipboard'
    },
    festivals: {
        description: 'All festivals of the MetalCrew Kultur e.V. briefly and compactly listed on one page. We are happy about every visitor!'
    },
    crewsade: {
        fullTextHeadline: 'Crewsade of Metal Festival Pfaffenhofen a. d. Ilm',
        description: '1 day festival feeling with Metal, Rock & Punk music in the September. 12 hours full speed!',
        fullText: `
        <p>Unique metal, rock & punk festival in a family setting: Crewsade of Metal! A free and non-profit festival to promote alternative music genres.</p>
        <p>Lovers of alternative music genres can rejoice, as the "Crewsade of Metal" festival annually offers an irresistible mix of great music, community spirit and unforgettable experiences. The small, but fine festival takes place at the end of September in the picturesque setting of the club home Pfaffenhofen a. d. Ilm and promises visitors an unforgettable weekend full of good music and a great festival atmosphere.</p>
        <p>The Crewsade of Metal Festival, simply Crewsade for short, presents a diverse selection of talented and energetic artists ranging from up-and-coming local greats to well-known scene acts. Visitors can look forward to spectacular live performances, infectious guitar riffs and pulsating drums that make the air vibrate. From classic rock to alternative to modern metal, the festival offers something for every taste and age.</p>
        <p>But the Crewsade is more than just a collection of great bands. The festival places great emphasis on creating a welcoming and family atmosphere that makes every visitor feel welcome and comfortable. There is a variety of food lovingly prepared by hand. Drinks from non-alcoholic to alcoholic are a matter of course. Various merchandise of our artists will be presented and offered. Camping facilities are also available so that guests can fully enjoy the festival experience.</p>
        <p>We involve fellow human beings with handicaps in the organization and also in the concrete on-site support of our festival. The goal of the action is on the one hand to give expression to the community and the ideals just mentioned in the festival, but on the other hand also to give people outside the group an unforgettable day and to create some variety in the local music landscape.</p>
        <p>More information about the events could be found at the festival's own website <a href="https://www.crewsade.de" target="_blank">www.crewsade.de</a>. Merchandise for this event is available in the store above.</p>`
    },
    openPark: {
        description: '2-day summer open air in July with picnic atmosphere! Cooperation with AWO and Oroborus.',
        fullText: `
            <h1>Open Park PFA'HOFA Festival</h1>
            <h3> 🏕️🌱🌲More than an open air! Hot, loud and refreshing: The PFA'HOFA Festival brings rock to the outdoor pool! 🌲🌱🏕️</h3><br>
            <p>Summer will be even hotter with the Open Park PFA'HOFA Festival, a unique rock experience taking place this year in the idyllic Pfaffenhofen outdoor pool. The combination of rousing music, refreshing pool parties and summery flair promises visitors an unforgettable rock festival of a special kind.</p>
            <p>The Open Park Festival presents an impressive line-up of talented artists who will delight audiences with energetic performances and rousing rhythms. From hot insider tips to up-and-coming bands from the region, the festival offers a wide range of genres that make the hearts of all music fans beat faster. Visitors can look forward to unforgettable live performances, pulsating guitar riffs and an atmosphere full of energy and joy.</p>
            <p>But the Open Park isn't just a rocking festival, it's also a summery pool party. The festival area extends around the Pfaffenhofen outdoor pool, so that visitors can enjoy a refreshing dip in the pool between performances. Whether swimming, relaxing on the sun loungers or dancing to the sounds of the bands - the Open Park offers the ultimate summer experience for music and pool lovers alike.</p>
            <p>"We are vocally committed to being adventurous and social with the best background music," says co-organizer Michael. "We want to give people the opportunity to party in a unique environment while cooling off in the waves of the outdoor pool. The Open Park PFA'HOFA promises unforgettable moments and a very special festival atmosphere."</p>
            <p>The goal of the event is to offer a non-denominational and party-politically neutral place with a wide variety of exhibition booths, supra-regional food offerings and a wonderful picnic atmosphere in the beautiful Pfaffenhofen Ilmbad.</p>
            <p>Festival flair and at the same time swimming, sliding and jumping from the 5 meter board. You will get all that here. The only consideration: buy an outdoor pool ticket for a small fee and go!</p>
            <p>A cooperation since 2020 with Arbeiterwohlfahrt (AWO), its youth work and the Oroboros association.</p>
            <p>Further information on the events can be found on the festival's own website <a href="https://www.open-park.de" target="_blank">www.open-park.de</a>. Merchandise for this event is available in the store above.</p>`
    },
    mcLive: {
        description: 'Concert days with local underground metal, rock and punk music at its best all over the year!',
        fullText: `
        <h1>💣 MetalCrew Live 💣</h1>
        <h3>Underground metal, rock & punk at its best: The Local MetalCrew Concert Series</h3>
        <br>
        <p>Music lovers and rock fans watch out: The MetalCrew Live concert series promises unique live concerts in an intimate atmosphere. This small but fine concert series brings local metal, rock & punk bands from Pfaffenhofen a. d. Ilm and the surrounding area on stage and offers visitors the opportunity to experience the community's energetic and intoxicating music scene up close.</p>
        <p>The MetalCrew Live concert series presents a variety of talented bands that bring their music to life with passion and dedication. From powerful guitar riffs to powerful vocal performances, the series features a wide range of genres that reflect the diverse local music scene. Each concert is a unique opportunity to discover and celebrate the authentic and genuine music of the region's emerging talent.</p>
        <p>The MetalCrew Live concert series is our way of supporting the local artist scene and providing unique musical experiences for people in our community. It's about creating a close connection between the artists and the audience, and fostering a passion for music in our own neighborhood. The concerts are intimate, energetic and unforgettable.</p>
        <p>The MetalCrew Live series concerts take place in cozy venues that offer an intimate and familiar atmosphere. This concert series creates an inviting environment in which music lovers and fans of the hard sounds of all ages come together to support and celebrate the local rock music scene. </p>
        <p> the concept was created in the crisis year 2020 in close cooperation with the Musikklub Pfaffenhofen. Small and fine with the best underground metal, rock and punk mix for thick skins. The insider tip for hot newcomers! </p>
        <p> Further information for comming events can be viewed in the <a href="/events/upcoming" target="_blank"> Event area </a> of the MetalCrew website. Merchandise for this event is available in the store above.</p>
        `
    },
    wbbPosts: {
        newComment: 'Create a new comment',
        postDisplay: 'Post display',
        successComment: "Comment was created successfully",
        errorComment: "An error occurred while creating the comment. Please try again!"
    },
    cookie: {
        details: 'This website uses cookies to improve the user experience and to provide certain services and features to users.',
        accept: 'Accept'
    }
}