// Setting.vue

<template>
  <v-container grid-list-xl>
    <v-layout>
      <v-flex>
        <v-card>
          <v-switch v-model="showMessages" :label="$t('settings.notifications')" @change="pushChange()"></v-switch>
          <v-input
            :hint="$t('settings.notificationDesc')"
            persistent-hint
            :messages="messages"
          >{{ $t('general.hint') }}</v-input>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>




<script>
import notifications from "../../notifications.js";

export default {
  data: () => ({
    showMessages: false
  }),
  created() {
    this.showMessages = this.$store.state.isSubscribed;
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setSubscribed") {
        this.showMessages = state.isSubscribed;
      }
    });
  },
  computed: {
    messages() {
      if (!("Notification" in window)) {
        return this.$i18n.t('settings.notificationsNotSupported');
      } else if (Notification.permission === "denied") {
        return this.$i18n.t('settings.notificationsBlocked');
      }
      return this.showMessages
        ? [this.$i18n.t('settings.notificiationsActive')]
        : undefined;
    }
  },

  methods: {
    pushChange() {
      if (this.showMessages) {
        notifications.subscribeUser();
      } else {
        // TODO: Unsubscribe
        console.log("NO SUB");
      }
    }
  }
};
</script>

