<template>
  <v-btn dark :href="href" :disabled="disabled" :max-width="width" color="orange" target="_blank" style="margin-right: 10px;margin-bottom: 10px;">{{ text }} </v-btn>
</template>

<script>
export default {
  name: "GBtnGen",
  props: {
    href: String,
    text: String,
    disabled: Boolean,
    width: String
  }
}
</script>