<template>
  <v-card class="mx-auto">
    <v-toolbar dark>
      <v-toolbar-title>{{ $t('guessGame.highscore') }}</v-toolbar-title>
    </v-toolbar>
    <v-list>
      <v-list-item v-for="(item, index) in items" :key="item.index">
        <v-list-item-content>
          <v-list-item-title>{{ $t('guessGame.place') }} {{ index + 1 }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-avatar>
          <v-img :src="item.avatar"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-text="item.name"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-title>{{ item.points }} {{ $t('guessGame.points') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      items: []
    };
  },
  mounted() {
    axios
      .get("https://" + window.location.hostname + "/app/game.php?highscore&timestamp=" + Date.now())
      .then(response => {
        this.items = [...response.data];
      })
      .catch(() => {
        this.items = [
          {
            name: "Mann",
            points: 10
          },
          {
            name: "Känguru",
            points: 8
          },
          {
            name: "Affe",
            points: 5
          },
                    {
            name: "Elefant",
            points: 3
          }
        ];
        this.$store.commit("showNotification", {
          snackbarColor: "error",
          snackbarTitle:
            "Die Bestenliste konnte aufgrund eines Netzwerkfehler nicht geladen werden. Bitte versuche es später nochmal."
        });
        // Manage the state of the application if the request has failed
        //console.log(err);
      });
  }
};
</script>