import vue from 'vue'
import vueI18n from 'vue-i18n'
import en from './en';
import de from './de';

vue.use(vueI18n)

const i18n = new vueI18n({
  locale: 'de', // set locale
  fallbackLocale: 'de',
  messages: {en, de}
})

// Create vueI18n instance with options
export default i18n;