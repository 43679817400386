<template>
  <v-container fluid>
    <v-card class="mt-4 mx-auto mt-5" style="background-color: #121212; -webkit-box-shadow: none; box-shadow: none;">
      <v-container>
        <v-row style="background-color: black">
          <v-img width="1%" aspect-ratio="0.9" src="@/assets/black_metal_woman.jpg"
            class="black darken-4 d-none d-sm-block"></v-img>
          <v-col align="center" align-self="center" :cols="contCols" class="pa-10">
            <div class="mb-5">
              <h1>We Need Your Support!</h1>
              <h3>{{ $t('donation.topDesc') }}</h3>
              <br>
              <h5>{{ $t('donation.lowerDesc') }}</h5>
              <v-container class="bg-surface-variant">
                <v-row no-gutters>
                  <v-col order="last">
                    <v-sheet class="pa-2 ma-2">
                      <p>[{{ $t('donation.bankConnection') }}]</p>
                      <p>{{ $t('donation.owner') }}: MetalCrew Kultur e.V</p>
                      <p>IBAN: DE26 7216 0818 0000 1511 14</p>
                      <p>BIC: GENO DEF1 INP</p>
                      <p>Bank: Volksbank Raiffeisenbank Bayern Mitte</p>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="bg-surface-variant">
                <v-row no-gutters>
                  <v-col>
                    <v-sheet class="pa-2 ma-2">
                      <a href="https://paypal.me/metalcrew" target="_blank"><v-icon>mdi-paypal</v-icon>{{ $t('donation.payPalDonation') }}</a>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-container>
              <h5>{{ $t('donation.bottomDesc') }}</h5>
              <br>
              <h5>{{ $t('donation.bottomAction') }}</h5>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>
  
<script>
export default {
  data: () => ({
    contCols: 7
  }),
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
      if (this.isMobile === true) this.contCols = 0;
      else this.contCols = 7;
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
};
</script>
  
<style scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}

.stats {
  font-size: 18px;
}
</style>