import store from './store'
import axios from "axios";

const applicationServerPublicKey = 'BCojFQEoYGfP4Sq8-zcaQEYMRqFrdmVksb7Mj2m4hmG8G6u1EAsE6i1edR0vrYkRUr8El5mgUMJ83m06rhPAaA0';
let swRegistration = null;
const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);

var functions = {
    init() {
        callInteralInit();
    },
    subscribeUser() {
        const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey);
        swRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey
        })
            .then(function (subscription) {
                console.log('User is subscribed.');
                updateSubscriptionOnServer(subscription);
                //isSubscribed = true;
                store.commit("setSubscribed", true);
                //updateBtn();
            })
            .catch(function (err) {
                console.log('Failed to subscribe the user: ', err);
                //updateBtn();
            });
    }
}

function callInteralInit() {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
        console.log('Service Worker and Push is supported');
        navigator.serviceWorker.register('sw.js')
            .then(function (swReg) {
                //console.log('Service Worker is registered', swReg);
                swRegistration = swReg;
                // Set the initial subscription value
                swRegistration.pushManager.getSubscription()
                    .then(function (subscription) {
                        //isSubscribed = !(subscription === null);
                        store.commit("setSubscribed", !(subscription === null));
                        //updateSubscriptionOnServer(subscription); // TODO: Is this needed? Wrong login message
                        if (store.state.isSubscribed) {
                            //if (isSubscribed) {
                            console.log('User IS subscribed.');
                        } else {
                            console.log('User is NOT subscribed.');
                        }
                        //updateBtn();
                    });
                swRegistration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: applicationServerKey
                })
                swRegistration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: applicationServerKey
                })
                    .then(function (subscription) {
                        console.log('User is subscribed.');
                        updateSubscriptionOnServer(subscription);
                        //isSubscribed = true;
                        store.commit("setSubscribed", true);
                        //updateBtn();
                    })
                    .catch(function (err) {
                        console.log('Failed to subscribe the user: ', err);
                        //updateBtn();
                    });
            })
            .catch(function (error) {
                console.error('Service Worker Error', error);
            });
    } else {
        console.warn('Push messaging is not supported');
    }
}

function updateSubscriptionOnServer(subscription) {
    const formData = new FormData();
    formData.append("userid", store.state.userid);
    formData.append("subscription", JSON.stringify(subscription));
    axios
        .post(`https://`+ window.location.hostname + `/app/push.php`, formData)
        .then(response => {
            if (response.data === true) {
                console.log("Notification activated")/*
                store.commit("showNotification", {
                    snackbarColor: "success",
                    snackbarTitle:
                        "Die Benachrichtungen wurden erfolgreich aktiviert"
                });*/
            }
        })
        .catch(() => {
            store.commit("showNotification", {
                snackbarColor: "error",
                snackbarTitle:
                    "Es ist ein Fehler beim Aktivieren der Beanachrichtungen aufgetreten"
            });
            // Manage the state of the application if the request has failed
            //console.log(err);
        });
}

function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export default functions