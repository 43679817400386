<template>
  <v-navigation-drawer v-model="drawer" app clipped>
    <v-list dense>
      <!-- Home -->
      <v-list-item :to="{ name: 'internal' }">
        <v-list-item-action>
          <v-icon>dashboard</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- News -->
      <v-list-item :to="{ name: 'association' }">
        <v-list-item-action>
          <v-icon>people</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("general.association") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- News -->
      <v-list-item :to="{ name: 'news' }">
        <v-list-item-action>
          <v-icon>new_releases</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>News</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- Calendar -->
      <v-list-item :to="{ name: 'calendar' }">
        <v-list-item-action>
          <v-icon>calendar_today</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("general.calendar") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- Musiktipps -->
      <v-list-item :to="{ name: 'song-musictip' }">
        <v-list-item-action>
          <v-icon>mdi-music-box</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("general.musictip") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- Reviews -->
      <v-list-item :to="{ name: 'reviews' }">
        <v-list-item-action>
          <v-icon>brush</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Reviews</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- Song of the week -->
      <v-list-group prepend-icon="music_note">
        <!-- no-action -->
        <template v-slot:activator>
          <v-list-item-title>{{
            $t("general.songsOfTheWeek")
          }}</v-list-item-title>
        </template>
        <!-- Heavy Monday -->
        <v-list-item :to="{ name: 'song-monday' }">
          <v-list-item-action></v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Heavy Monday</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Extreme Tuesday -->
        <v-list-item :to="{ name: 'song-tuesday' }">
          <v-list-item-action></v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Extreme Tuesday</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Metal Heart Wednesday -->
        <v-list-item :to="{ name: 'song-wednesday' }">
          <v-list-item-action></v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Metal Heart Wednesday</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Traditional Thursday -->
        <v-list-item :to="{ name: 'song-thursday' }">
          <v-list-item-action></v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Traditional Thursday</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Furioses Freitag -->
        <v-list-item :to="{ name: 'song-friday' }">
          <v-list-item-action></v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Furioser Freitag</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Kotzer der Woche-->
        <v-list-item :to="{ name: 'song-sunday' }">
          <v-list-item-action></v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Kotzer der Woche</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <!-- Games -->
      <v-list-group prepend-icon="games">
        <!-- no-action -->
        <template v-slot:activator>
          <v-list-item-title>{{
            $t("general.entertainment")
          }}</v-list-item-title>
        </template>
        <!-- Lyrics -->
        <v-list-item :to="{ name: 'game' }">
          <v-list-item-action></v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("general.guessGame") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Album -->
        <v-list-item :to="{ name: 'game-highscore' }">
          <v-list-item-action></v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t("general.highscore") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-group prepend-icon="mdi-open-in-new">
        <!-- no-action -->
        <template v-slot:activator>
          <v-list-item-title>{{
            $t("general.externalLinks")
          }}</v-list-item-title>
        </template>
        <!-- Forum -->
        <v-list-item>
          <v-list-item-action>
            <v-icon>forum</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <a href="https://community.metalcrew.de" target="_blank"
                >Community</a
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Crewsade -->
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-sword</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <a href="https://www.crewsade.de" target="_blank">Crewsade</a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Open Park -->
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-swim</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <a href="https://www.open-park.de" target="_blank">Open Park</a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Shop -->
        <v-list-item>
          <v-list-item-action>
            <v-icon>shop</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <a href="https://shop.metalcrew.de/" target="_blank">Shop</a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Support -->
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-face-agent</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <a href="mailto:verein@metalcrew.de" target="_blank">Support</a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Discord -->
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-discord</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <a href="https://discord.com/invite/a3tJ9nwUZh" target="_blank"
                >Discord</a
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Steam -->
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-steam</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <a
                href="https://steamcommunity.com/groups/MetalCrewKultur"
                target="_blank"
                >Steam</a
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Facebook -->
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-facebook</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <a href="https://www.facebook.com/metalcrew.eu/" target="_blank"
                >Facebook</a
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Instagram -->
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-instagram</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <a href="https://www.instagram.com/metalcrew.eu/" target="_blank"
                >Instagram</a
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Twitter -->
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-twitter</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <a href="https://www.twitter.com/metalcrewkultur/" target="_blank"
                >Twitter</a
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Spotify -->
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-spotify</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <a
                href="https://open.spotify.com/user/ujqo9el79aeoknz6ebjav2mqi?si=e7tnnF4RRLiets5xTDjqfw"
                target="_blank"
                >Spotify</a
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Soundcloud -->
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-soundcloud</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <a
                href="https://on.soundcloud.com/QujSWFjNPg5vQVVB7"
                target="_blank"
                >Soundcloud</a
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- YouTube -->
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-youtube</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <a href="https://www.youtube.com/@metalcrew" target="_blank"
                >YouTube</a
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Twitch -->
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-twitch</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <a href="https://www.twitch.tv/metalcrew" target="_blank"
                >Twitch</a
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- Telegram -->
        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-telegram</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              <a href="https://t.me/metalcrew" target="_blank">Telegram</a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <!-- Settings -->
      <v-list-item :to="{ name: 'setting' }">
        <v-list-item-action>
          <v-icon>settings</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $t("general.settings") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="pa-5">
        <!-- hide on screens smaller than lg -->
        <!--  class="d-none d-lg-block" -->
        <v-btn
          block
          color="orange"
          @click="logout()"
          >{{ $t("login.logoutBtn") }}</v-btn
        >
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
      changeBack: false,
    };
  },
  watch: {
    drawer: function (val) {
      if (val == false) {
        this.$store.commit("toggleNavigationBar", {
          navigation: false,
        });
      }
    },
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "toggleNavigationBar") {
        this.drawer = state.navigation;
        this.changeBack == false;
      }
    });
  },
  methods: {
    logout() {
      this.$router.push("login");
      this.$store.commit("logout");
      this.$store.commit("showNotification", {
        snackbarColor: "success",
        snackbarTitle: this.$i18n.t("login.logout"),
      });
    },
  },
};
</script>