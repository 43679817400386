<template>
  <v-container style="margin-bottom: -10px">
    <v-card v-for="(person, n) in leader" :key="n">
      <v-img height="0%" :src="person.bgimg" class="mb-5">
        <v-row align="center" justify="center" class="flexCont">
          <v-col
            cols="2"
            v-if="!person.right"
            class="d-none d-lg-block"
            style="margin-right: -30px"
          >
            <v-avatar class="profile" color="grey" size="164" tile>
              <v-img :src="person.img"></v-img>
            </v-avatar>
          </v-col>
          <v-col justify="center" align="left">
            <v-list-item color="rgba(0, 0, 0, .4)" dark>
              <v-list-item-content>
                <v-list-item-title class="text-h6"
                  >{{ $t("leader.character") }}: {{ person.name }}
                </v-list-item-title>
                <v-list-item-subtitle class="mb-5 orange--text"
                  >👨‍🎓 {{ $t("leader.class") }}: {{ position(n) }} 👊 Level:
                  {{ calculateLevel(person.level) }} 📧
                  <a
                    :href="person.mail"
                    title="Private Nachricht schreiben"
                    style="text-decoration: none"
                    >Mail</a
                  ></v-list-item-subtitle
                >
                {{ description(n) }}
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col
            cols="2"
            v-if="person.right"
            class="d-none d-lg-block"
            align="right"
          >
            <v-avatar class="profile" color="grey" size="164" tile>
              <v-img :src="person.img"></v-img>
            </v-avatar>
          </v-col>
        </v-row>
      </v-img>
    </v-card>
  </v-container>
</template>

<script>
import logo from "@/assets/logo_white.svg";

export default {
  data: () => ({
    logo,
    leader: [
      {
        img: "https://www.crewsade.de/wp-content/uploads/2020/08/WhatsApp-Image-2020-05-31-at-20.22.48-e1597698556410.jpeg",
        //bgimg: 'https://www.crewsade.de/wp-content/uploads/2019/05/cropped-IMG_1763-3.jpg',
        name: "Michael Stiglmayr",
        mail: "mailto:michael@metalcrew.de",
        position: "1. Vorstand",
        level: "09/15/1994",
        description:
          "Das letzte aktive Gründungsmitglied der Community und seit Beginn an im Vorstand. Stets mit vollem Herzen beim Verein und seinen Zielen dabei. Ein Heavy Rocker!",
      },
      {
        img: "https://www.crewsade.de/wp-content/uploads/2021/01/1230-0d3aa4d7-e1612109117510.jpg",
        name: "Vanessa Sachsenhauser",
        mail: "mailto:vanessa@metalcrew.de",
        position: "2. Vorstand",
        level: "11/03/1998",
        description:
          "Seit 2018 dabei und auf den Tag genau 3 Jahre später auch im Vorstand. Wenn sie nicht gerade auf Gigs und Mittelaltermärkten unterwegs ist, ist sie neben dem Verein noch beim SJR Ingolstadt tätig. Vertritt die Core-Fraktion.",
        right: true,
      },
      {
        img: "https://www.crewsade.de/wp-content/uploads/2019/05/20-e1655bc8bc130fc6e074dcf71d964e5e68f1a28f-125x108.jpg",
        name: "Simon Hauke",
        mail: "mailto:simon@metalcrew.de",
        position: "Schriftführer",
        level: "02/06/1997",
        description:
          "Ein Urgestein und Admin der Community. Ebenfalls Gründungs- und bis dato bestehendes Vorstandsmitglied als Schriftführer. Musikalisch gibts nur Trve Metal!",
      },
      {
        img: "https://www.crewsade.de/wp-content/uploads/2019/05/39-99b650adf62f2db4a66126657ab3be83e7a9db05-125x125.jpg",
        name: "Johannes Helbig",
        mail: "mailto:joe@metalcrew.de",
        position: "Kassenwart",
        level: "10/25/1992",
        description:
          "Wenn es ums Geld geht, Joe! Black Metal Liebhaber und Kenner des Undergrounds der Szene. Seit 2019 tätig als Schatzmeister.",
        right: true,
      },
    ],
  }),
  computed: { // Only possible without paramters - {{ description }}
  },
  methods: {
    description(n) {
      return this.$t("leader.description" + (n + 1));
    },
    position(n) {
      return this.$t("leader.class" + (n + 1));
    },
    calculateLevel(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
  },
};
</script>

<style scoped>
.flexCont {
  /*
  width: 70%;
  margin-left: auto;
  margin-right: auto;*/
}

@media screen and (max-width: 800px) {
  .flexCont {
    /*
    width: 100%;
    margin-left: auto;
    margin-right: auto;*/
  }
}
</style>