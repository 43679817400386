import axios from "axios";
import store from './store'
import vueI18n from './translations'

var functions = {
  capitalizeFirstLetter: str => str.charAt(0).toUpperCase() + str.slice(1),
  /** update state to maintain selected item and toggle view **/
  loadPosts(scope, selectedItem) {
    const threadID = selectedItem.threadID;
    const boardID = selectedItem.boardID;
    axios
      .get("../community/app-bridge.php?loadPost&threadID=" + threadID + "&boardID=" + boardID + "&timestamp=" + Date.now())
      .then(response => {
        const open = () => {
          scope.selectedNews = response.data;
          setTimeout(() => (scope.selectedOpen = true), 5);
        };
        if (scope.selectedOpen) {
          scope.selectedOpen = false;
          setTimeout(open, 5);
        } else {
          open();
        }
        //this.items = [...response.data];
      })
      .catch(err => {
        // Manage the state of the application if the request has failed
        store.commit("showNotification", {
          snackbarColor: "error",
          snackbarTitle: vueI18n.t('message.networkError')
        });
        console.log(err);
      });
  },
  loadArticles(scope, selectedItem) {
    const articleID = selectedItem.articleID;
    const categoryID = selectedItem.categoryID;
    axios
      .get("../community/app-bridge.php?loadArticle&articleID=" + articleID + "&categoryID=" + categoryID + "&timestamp=" + Date.now())
      .then(response => {
        const open = () => {
          scope.selectedNews = response.data;
          setTimeout(() => (scope.selectedOpen = true), 5);
        };
        if (scope.selectedOpen) {
          scope.selectedOpen = false;
          setTimeout(open, 5);
        } else {
          open();
        }
        //this.items = [...response.data];
      })
      .catch(err => {
        // Manage the state of the application if the request has failed
        store.commit("showNotification", {
          snackbarColor: "error",
          snackbarTitle: vueI18n.t('message.networkError')
        });
        console.log(err);
      });
  },
  replaceStringWith(string, search, replace) {
    return string.replaceAll(search, replace);
  },
  replaceAWithIMG(htmlCode) {
    // Create a temporary element to parse the HTML code
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlCode;
    // Get the anchor tag from the temporary element
    const anchorTag = tempElement.querySelector('a');
    if (anchorTag === null) return htmlCode;
    // Create a new image tag
    const imgTag = document.createElement('img');
    // Set the image source to the href attribute of the anchor tag
    imgTag.src = anchorTag.href;
    // Replace the anchor tag with the image tag
    anchorTag.parentNode.replaceChild(imgTag, anchorTag);
    // Get the modified HTML code from the temporary element
    const modifiedHtmlCode = tempElement.innerHTML;
    // Output the modified HTML code
    return modifiedHtmlCode;
  },
  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
  },
  copy2Clipboard(str) {
    //window.prompt ("Link zum Teilen auf Social Media Platformen", str);

    navigator.clipboard.writeText(str)
      .then(() => {
        store.commit("showNotification", {
          snackbarColor: "success",
          snackbarTitle: vueI18n.t('message.copyClipboard')
        });
      })
      .catch(err => {
        store.commit("showNotification", {
          snackbarColor: "error",
          snackbarTitle: err
        });
      })
  },
  replaceImgUrl(url, item) {
    /*   const img = new Image();
        img.src = url;
        return new Promise((resolve) => {
          img.onload = () => resolve(true);
          img.onerror = () => resolve(false);
        });*/

    // Saves bandwidth, but not working :/
    //    return fetch(url, {method: 'HEAD'}).then(res => {
    //      return res.headers.get('Content-Type').startsWith('image')
    //    })

    fetch(url, { method: 'HEAD' }).then(res => {
      if (res.headers.get('Content-Type').startsWith('image')) {
        let html = document.querySelector('html').innerHTML;
        console.log(html);
        console.log(item);
        //let newHTML = html.replace(item, '<img src="' + url + '">');
        document.querySelector('html').innerHTML = html;
      }
    })
  },
  extractLinkTags(htmlString) {
    const linkRegex = /<a\b[^>]*>(.*?)<\/a>/g;
    const linkTags = htmlString.match(linkRegex);

    if (linkTags && linkTags.length > 0) {
      return linkTags;
    }

    return []; // No link tags found in the string
  },
  extractURL(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const matches = text.match(urlRegex);

    if (matches && matches.length > 0) {
      return matches[0]; // Returns the first URL match
    }

    return null; // No URL found in the text
  }
}

export default functions