<template>
    <v-container class="mb-5">
        <a href="https://www.crewsade.de" target="_blank"><v-img src="@/assets/crewsade-live.jpg" class="grey darken-4" alt="Crewsade of Metal Festival"></v-img></a>
        <v-row justify="center" class="mb-5">
            <v-btn
                color="orange"
                href="https://www.crewsade.de"
                target="_blank"
            >Crewsade of Metal Website</v-btn>
        </v-row>
        <!--
        <v-row justify="center">
            <a href="https://www.crewsade.de" target="_blank">
                <v-img
                    src="@/assets/event/crewsade.png"
                    contain
                    height="100"
                    title="Crewsade of Metal"
                    alt="Crewsade of Metal"
                />
            </a>
        </v-row>-->
        <v-row class="text-center">
            <v-col>
                <h1>{{ $t('crewsade.fullTextHeadline') }}</h1>
            </v-col>
            <v-col cols="12" v-html="$t('crewsade.fullText')">
            </v-col>
            <!--<v-col>
                <v-container mt-5>
                    <v-layout row wrap justify-center>
                        <v-flex xs12 sm10>
                            <v-card elevation-3>
                                <v-toolbar dark color="grey" class="red darken-4">
                                    <v-toolbar-title class>Zitate und Meinung zum Crewsade</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <div class="mr-3">
                                        <v-btn @click="getQuote">Mehr!</v-btn>
                                    </div>
                                </v-toolbar>
                                <v-card-text>
                                    <div class="quote mb-10">"{{ quote }}"</div>
                                    - {{ author }}
                                </v-card-text>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-col>-->

            <v-container class="grey darken-3">
                <v-row no-gutters>
                    <v-col>
                        <v-img src="@/assets/event/crewsade-18.jpg" class="grey darken-4" alt="Crewsade of Metal 2018"></v-img>
                    </v-col>
                    <v-col>
                        <v-img src="@/assets/event/crewsade-19.jpg" class="grey darken-4" alt="Crewsade of Metal 2019"></v-img>
                    </v-col>
                    <v-col>
                        <v-img src="@/assets/event/crewsade-17.jpg" class="grey darken-4" alt="Crewsade of Metal 2017"></v-img>
                    </v-col>
                </v-row>
            </v-container>


            <v-col>
               
                <!--
                <iframe width="90%" height="550px" style="margin: auto" src="https://www.youtube-nocookie.com/embed/videoseries?list=PL5E1pmOdf84qo3w6ZWZjeMTzwOD56Bggk" title="MetalCrew Crewsade of Metal YouTube Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                -->
            </v-col>
            <!--<GVideo
                :mp4="require(`@/assets/video/crewsade-19.mp4`)"
                width="90%"
                controls="true"
                :poster="require(`@/assets/event/crewsade-video.jpg`)"
            />-->
        </v-row>
    </v-container>
</template>

<script>
//import GVideo from '@/components/global/g-video';

export default {
    /*components: {
        'GVideo': GVideo
    },*/
    data: () => ({
        quote: 'Zitat',
        author: 'Person',
        last: 0,
        quotes: [
            { quote: 'EBERSBERG! Wir waren in der Vergangenheit schon an vielen Flecken, an denen uns kein Schwein kennt. Aber so was wie gestern haben wir noch nicht erlebt…15:00 Uhr, Mittagshitze und volle Bude! Absoluter Wahnsinn was ihr da gestern abgeliefert habt, Hut ab!', author: 'Prior the End (Teilnehmende Band)' },
            { quote: 'Glückwunsch an die MetalCrew. Schöner Tag in Steinhöring.', author: 'Thomas M.' },
            { quote: 'So ein irrwitziger Abend! Horns up and cheer!', author: 'THRASSHOLE (Teilnehmende Band)' },

        ]

    }),
    methods: {
        getQuote: function () {
            var item = this.quotes[this.random(this.quotes.length)];
            this.quote = item.quote;
            this.author = item.author;
        },
        random: function (area) {
            var rand = Math.floor(Math.random() * area);
            if (rand == this.last) {
                if (rand == 0) { rand = rand + 1; }
                else { rand = rand - 1; }
            }
            this.last = rand;
            return rand;
        }
    },
    mounted() {
        this.getQuote();
    }
}
</script>

<style scoped>
.quote {
    font-family: Kaushan Script;
    font-size: 20px;
}

@media screen and (max-width: 750px) {
    iframe {
        max-width: 100% !important;
        width: auto !important;
        height: auto !important;
    }
}
</style>