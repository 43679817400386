<template>
  <v-snackbar
    v-model="show"
    :bottom="y === 'bottom'"
    :color="color"
    :left="x === 'left'"
    :multi-line="mode === 'multi-line'"
    :right="x === 'right'"
    :timeout="timeout"
    :top="y === 'top'"
    :vertical="mode === 'vertical'"
  >
    {{ message }}
    <v-btn dark text @click="show = false">{{ $t('general.close') }}</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      color: "",
      mode: "vertical",
      message: "",
      timeout: 6000,
      x: null,
      y: "top"
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "showNotification") {
        this.message = state.snackbar['content'];
        this.color = state.snackbar['color'];
        this.show = true;
      }
    });
  }
};
</script>