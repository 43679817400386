<template>
  <v-layout fill-height>
    <v-flex>
      <v-sheet height="64">
        <v-toolbar flat color="#424242">
          <v-btn outlined class="mr-4 d-none d-md-block" @click="setToday">
            {{ $t("calendar.today") }}
          </v-btn>
          <v-btn fab text small @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined v-bind="attrs" v-on="on">
                <span>{{ typeToLabel(type) }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>{{ $t("calendar.day") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>{{ $t("calendar.week") }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>{{
                  $t("calendar.month")
                }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>{{
                  $t("calendar.days4")
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="100%">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="orange"
          :weekdays="weekdays"
          :events="store"
          :event-color="getEventColor"
          :event-margin-bottom="3"
          :now="today"
          :type="type"
          @click:more="viewDay"
          @click:date="viewDay"
          @click:event="showEvent"
          @change="updateRange"
        ></v-calendar>
        <!-- DIALOG FELD -->
        <v-dialog
          v-model="selectedOpen"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar dark color="orange">
              <v-btn
                icon
                dark
                @click="selectedOpen = false"
                :aria-label="$t('general.close')"
              >
                <v-icon>close</v-icon>
              </v-btn>
              <v-btn
                  icon
                  :href="makeCalenderLink(selectedEvent.eventID)"
                  :title="$t('calendar.addTo')"
                  >
                  <v-icon>mdi-calendar</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
            </v-toolbar>
            <v-container>
              <div>
                <div v-if="selectedEvent.start_out">
                  <b>{{ $t("calendar.start") }}:</b>
                  <br />
                  <br />
                  {{ selectedEvent.start_out }}
                  <br />
                  <br />
                </div>
                <div v-if="selectedEvent.end_out">
                  <b>{{ $t("calendar.end") }}:</b>
                  <br />
                  <br />
                  {{ selectedEvent.end_out }}
                  <br />
                  <br />
                </div>
                <div v-if="selectedEvent.location">
                  <b>{{ $t("calendar.location") }}:</b>
                  <br />
                  <br />
                  {{ selectedEvent.location }}
                  <br />
                  <br />
                </div>
                <div v-if="selectedEvent.details">
                  <b>{{ $t("calendar.details") }}:</b>
                  <br />
                  <br />
                  <span v-html="selectedEvent.details"></span>
                </div>
              </div>
            </v-container>
          </v-card>
        </v-dialog>
        <!-- DIALOG FELD ENDE -->
      </v-sheet>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ["store"],
  data: () => ({
    today: "2019-01-08",
    focus: "2019-01-08",
    type: "month",
    start: null,
    end: null,
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
  }),
  mounted() {
    // Set today date
    this.today = this.getToday();
    this.focus = this.getToday();
  },
  computed: {
    title() {
      const { start, end } = this;
      if (!start || !end) {
        return "";
      }

      const startMonth = this.monthFormatter(start);
      const endMonth = this.monthFormatter(end);
      const suffixMonth = startMonth === endMonth ? "" : endMonth;

      const startYear = start.year;
      const endYear = end.year;
      const suffixYear = startYear === endYear ? "" : endYear;

      const startDay = start.day;
      const endDay = end.day;

      switch (this.type) {
        case "month":
          return `${startMonth} ${startYear}`;
        case "week":
        case "4day":
          return `${startDay} ${startMonth} ${startYear} - ${endDay} ${suffixMonth} ${suffixYear}`;
        case "day":
          return `${startDay} ${startMonth} ${startYear}`;
      }
      return "";
    },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },
  methods: {
    typeToLabel(type) {
      if (type == "month") {
        return this.$i18n.t("calendar.month");
      } else if (type == "week") {
        return this.$i18n.t("calendar.week");
      } else if (type == "day") {
        return this.$i18n.t("calendar.day");
      } else if (type == "4day") {
        return this.$i18n.t("calendar.days4");
      }
    },
    getToday() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
    },
    getEventColor(event) {
      let upperName = event.name.toUpperCase();
      if (upperName.includes("COMMUNITY")) {
        return "red";
      } else if (upperName.includes("KULTUR")) {
        return "orange";
      } else if (upperName.includes("CREWSADE OF METAL")) {
        return "orange";
      } else if (upperName.includes("ARMAGEDDON")) {
        return "orange";
      } else if (upperName.includes("METALCREW LIVE")) {
        return "orange";
      } else if (upperName.includes("OPEN PARK")) {
        return "orange";
      } else if (upperName.includes("VOLLVERSAMMLUNG")) {
        return "orange";
      } else if (upperName.includes("HORROR NIGHTS")) {
        return "orange";
      } else if (upperName.includes("KINO")) {
        return "orange";
      } else if (
        upperName.includes("GARTEN") ||
        upperName.includes("CAMPING")
      ) {
        return "orange";
      } else if (upperName.includes("ZOMBRÄU")) {
        return "orange";
      } else if (upperName.includes("SAURIER") || upperName.includes("DINO")) {
        return "orange";
      } else return "grey";
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => (this.selectedOpen = true), 5);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 5);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      // You could load events from an outside source (like database) anow that we have the start and end dates on the calendar
      this.start = start;
      this.end = end;
    },
    makeCalenderLink(id) {
      return "https://www.metalcrew.de/community/calendar/event-export/" + id + "/";
    }
  },
};
</script>