<template>
    <v-card
      class="mt-4 mx-auto mt-5"
      style="
        background-color: #121212;
        -webkit-box-shadow: none;
        box-shadow: none;
      "
    >
      <v-container>
        <v-row no-gutters style="background-color: black">
          <v-col :hidden="contCols">
            <v-sheet class="pa-2 ma-2" style="background-color: black">
              <v-img
                src="@/assets/no-extremism.png"
                cover
                aspect-ratio="1"
              ></v-img>
            </v-sheet>
          </v-col>
          <v-col style="vertical-align: middle;" align="center" align-self="center">
            <v-sheet class="pa-2 ma-2" style="background-color: black;" v-html="$t('extremism.fulltext')">
            </v-sheet>
          </v-col>
          <v-responsive width="100%"></v-responsive>
        </v-row>
      </v-container>
    </v-card>
</template>
    
  <script>
export default {
  data: () => ({
    contCols: true,
  }),
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 600;
      if (this.isMobile === true) this.contCols = true;
      else this.contCols = false;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
};
</script>
    
  <style scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}

.stats {
  font-size: 18px;
}
</style>