<template>
  <v-container>
    <v-row>
    <v-col v-for="(event, n) in items" :key="n" class="mx-auto" max-width="344" md="6" sm="5" lg ="4">
      <v-card>
        <router-link :to="event.url">
        <v-img :src="getImage(event.img)" height="500px"></v-img></router-link>
        <v-card-title v-text="event.name"></v-card-title>
        <v-card-subtitle v-text="$t(event.description + '.description')"></v-card-subtitle>
        <v-card-actions>
          <v-btn dark color="orange" :to="event.url">{{ $t('general.toEvent') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    items: [
      { name: 'Crewsade of Metal', description: 'crewsade', img: 'crewsade-19', url: {name: 'crewsade-of-metal'} },
      { name: 'MetalCrew Live', description: 'mcLive',  img: 'mc-live-4', url: {name: 'metalcrew-live'} },
      { name: 'Open Park PFA\'HOFA', description: 'openPark', img: 'open-park-1', url: {name: 'open-park-pfahofa'} }
    ]
  }),
  methods: {
    getImage(img) {
      return require("@/assets/event/" + img + ".jpg");
    }
  },
  mounted() {
  }
}
</script>