<template>
  <v-container fluid v-if="items[0]">
    <v-text-field color="success" loading disabled></v-text-field>
    <h2 v-html="makeLinksToPics(items[0].message)"></h2>
    <v-text-field color="success" loading disabled></v-text-field>
    <v-container class="px-0" fluid>
      {{ gameModeQuestion }}
      <v-radio-group v-model="radios">
        <v-radio v-for="(item, index) in items" :key="index" :label="`${item.value}`" :value="`${item.id}`"></v-radio>
      </v-radio-group>
    </v-container>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on }">
        <v-btn color="orange lighten-2" dark :disabled="!dataAvailable" v-on="on">{{ $t('guessGame.vote') }}</v-btn>
      </template>
      <v-card>
        <v-card-title class="headline purple darken-2" primary-title>{{ gameModeName }}-Quiz</v-card-title>
        <v-card-text>
          <br />
          <span v-html="quizAnswer(radios)"></span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" text @click="quitQuiz()">{{ $t('guessGame.exit') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="orange" text @click="nextQuiz()">{{ $t('guessGame.next') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <br />
    <br />
    <v-footer padless>
      <v-col class="text-center" cols="12">
        <b>{{ $t('guessGame.statistics') }}</b>
        <br />
        {{ $t('guessGame.statsRight') }}: {{ quizRight }} — {{ $t('guessGame.statsWrong') }}: {{ quizWrong }}
        <br />
        {{ $t('guessGame.statsPoints') }}: {{ quizRight - quizWrong }}
      </v-col>
    </v-footer>
  </v-container>
</template>

<script>
import axios from "axios";
import functions from "../../functions.js";

export default {
  data() {
    return {
      items: [],
      send: false,
      dialog: false,
      quizRight: 0,
      quizWrong: 0,
      radios: null
    };
  },
  mounted() {
    axios
      .get("https://" + window.location.hostname + "/app/game.php?game-combined&timestamp=" + Date.now())
      .then(response => {
        this.items = response.data;
      })
      .catch(() => {
        this.items = [
          {
            message: this.$i18n.t('message.noNetwork'),
            value: this.$i18n.t('message.noSelection'),
            mode: "lyrics",
            postID: -1,
            id: "offline"
          }
        ];
        this.$store.commit("showNotification", {
          snackbarColor: "error",
          snackbarTitle:
            this.i18n.t('message.networkError')
        });
      });
  },
  computed: {
    dataAvailable() {
      return this.radios !== null;
    },
    gameModeName() {
      return this.items[0].mode == "cover" ? "Album" : "Lyrics";
    },
    gameModeQuestion() {
      return this.items[0].mode == "cover"
        ? this.$i18n.t('guessGame.questionMode1')
        : this.$i18n.t('guessGame.questionMode2');
    }
  },
  methods: {
    makeLinksToPics(string) {
      return functions.replaceAWithIMG(string);
      //let string2 = functions.replaceStringWith(string, '<a href', '<img style="color:#000 !important" src');
      //let string3 =  functions.replaceStringWith(string2, '</a>', '</img>');
      //return functions.replaceStringWith(string3, '/metalcrew.de/community/attachment/(.*?)/', '');
    },
    quizAnswer(radios) {
      if (this.radios == "offline") {
        return this.i18n.t('message.networkError');
      }
      if (radios == "correct") {
        return this.$i18n.t('guessGame.rightAnswer');
      } else {
        return this.$i18n.t('guessGame.wrongAnswer');
      }
    },
    nextQuiz() {
      // TODO: Verhinderte doppelte Frage
      this.dialog = false;
      axios
        .get(
          "https://" + window.location.hostname + "/app/game.php?game-combined&timestamp=" + Date.now() + "&last=" +
          this.items[0].postID
        )
        .then(response => {
          if (this.radios == "correct") {
            this.quizRight++;
          } else {
            this.quizWrong++;
          }
          this.radios = false;
          this.items = response.data;
        })
        .catch(() => {
          this.$store.commit("showNotification", {
            snackbarColor: "error",
            snackbarTitle:
              this.i18n.t('message.networkError')
          });
        });
    },
    quitQuiz() {
      const formData = new FormData();
      formData.append("username", this.$store.state.username);
      formData.append("quizRight", this.quizRight);
      formData.append("quizWrong", this.quizWrong);
      axios
        .post("https://" + window.location.hostname + `/app/game.php`, formData)
        .then(() => {
          //console.log(response);
          const formData2 = new FormData();
          formData2.append(
            "pushMessageToAll",
            this.$store.state.username +
            " hat eine Punktezahl von " +
            (this.quizRight - this.quizWrong) +
            " im Ratespiel erreicht"
          );
          formData2.append(
            "pushMessageToAllURL",
            "https://www.metalcrew.de/game-highscore"
          );
          axios
            .post("https://" + window.location.hostname + `/app/push.php`, formData2)
            .then(() => { })
            .catch(() => { });
        })
        .catch(() => {
          this.$store.commit("showNotification", {
            snackbarColor: "error",
            snackbarTitle:
              this.i18n.t('message.networkError')
          });
          // Manage the state of the application if the request has failed
          //console.log(err);
        });
      this.dialog = false;
      this.$router.push("game-highscore");
    }
  }
};
</script>
