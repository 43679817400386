<template>
  <v-list two-line>
    <v-list-item-group>
      <!-- Post Liste -->
      <template v-for="(item, index) in store">
        <v-list-item :key="item.articleID" @click="loadArticles(item)">
          <template>
            <v-list-item-content>
              <v-list-item-title v-text="item.topic"></v-list-item-title>
              <v-list-item-subtitle class="text--primary" v-text="item.username"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-action-text v-text="item.date"></v-list-item-action-text>
              <!-- Fav System
                <v-icon
                  v-if="!active"
                  color="grey lighten-1"
                >
                  star_border
                </v-icon>

                <v-icon
                  v-else
                  color="yellow"
                >
                  star
              </v-icon>-->
            </v-list-item-action>
          </template>
        </v-list-item>
        <v-divider v-if="index + 1 < items.length" :key="index"></v-divider>
      </template>
      <!-- Post Ende -->
      <!-- Dialog -->
      <v-dialog v-model="selectedOpen" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-progress-linear :active="this.$store.state.loading" :indeterminate="this.$store.state.loading" absolute bottom
          color="deep-purple accent-4"></v-progress-linear>
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="selectedOpen = false" :aria-label="$t('general.close')">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title v-html="selectedNews[0].subject"></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="generateSocialLink()" :aria-label="$t('general.share')">
              <v-icon>link</v-icon>
            </v-btn>
          </v-toolbar>
          <v-container>
            <span v-html="makeLinksExternal(selectedNews[0].message)"></span>
            <!-- Kommentare -->
            <v-simple-table dark>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{ $t('general.comments') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="post in selectedNews.slice(1)" :key="post.postID">
                    <td>
                      {{ post.username }} {{ $t('general.say') }}:
                      <br />
                      <span v-html="post.message"></span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <v-text-field v-model="comment" :label="$t('wbbPosts.newComment')" hide-details="auto"
                        @keydown.enter="createComment(
                            selectedNews[0].articleID,
                            selectedNews[0].categoryID
                          )
                          "></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
          <!-- Kommentare Ende -->
        </v-card>
      </v-dialog>
      <!-- Dialog Ende -->
    </v-list-item-group>
  </v-list>
</template>

<script>
import axios from "axios";
import functions from "../../functions.js";

export default {
  props: ["store"],
  data() {
    return {
      items: [],
      selectedOpen: false,
      comment: null,
      selectedNews: [
        {
          threadID: null,
          boardID: null,
          subject: this.$i18n.t('wbbPosts.postDisplay'),
          message: this.$i18n.t('general.loading') + "..",
        },
      ],
    };
  },
  methods: {
    loadArticles(item) {
      functions.loadArticles(this, item);
    },
    makeLinksExternal(string) {
      return functions.replaceStringWith(string, '<a', '<a target="_blank"');
    },
    generateSocialLink() {
      var string =
        "https://www.metalcrew.de/community/article/" +
        this.selectedNews[0].articleID;
      functions.copy2Clipboard(string);
    },
    makeImageURLs(str) {
      var links = functions.extractLinkTags(str);
      //console.log(links);
      links.forEach(myFunction);
      function myFunction(item) {
        let url = functions.extractURL(item);
       functions.replaceImgUrl(url, item);
      }
      return str;
    },
    createComment(articleID, categoryID) {
      //console.log("Kommentar soll kommen!");
      //console.log(threadID);
      //console.log("BoardID: " + boardID);
      //console.log(this.comment);
      //console.log(this.$store.state.userid);
      //onsole.log(this.$store.state.username);

      const formData = new FormData();
      formData.append("articleID", articleID);
      formData.append("categoryID", categoryID);
      formData.append("userid", this.$store.state.userid);
      formData.append("username", this.$store.state.username);
      formData.append("comment", this.comment);

      axios
        .post("https://" + window.location.hostname + `/app/post.php`, formData)
        .then((response) => {
          if (response.data === "true") {
            this.$store.commit("showNotification", {
              snackbarColor: "success",
              snackbarTitle: this.$i18n.t('wbbPosts.successComment'),
            });
          } else {
            this.$store.commit("showNotification", {
              snackbarColor: "error",
              snackbarTitle:
                this.$i18n.t('wbbPosts.errorComment'),
            });
          }
        })
        .catch(() => {
          this.$store.commit("showNotification", {
            snackbarColor: "error",
            snackbarTitle:
              this.$i18n.t('wbbPosts.errorComment'),
          });
          // Manage the state of the application if the request has failed
          //console.log(err);
        });
      this.comment = null;
      this.selectedOpen = false;
    }
  },
};
</script>

<style>
img {
  max-width: 100% !important;
  height: auto !important;
}
iframe {
  width: 100% !important;
  height: 70vh !important;
}
</style>