// Home.vue

<template>
  <v-card class="mx-auto">
    <v-list-item>
      <v-list-item-avatar>
        <v-img :src="this.$store.state.avatar"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="display-1"
          >Hellcome {{ this.$store.state.username }}!</v-list-item-title
        >
        <v-list-item-subtitle class="subtitle-1"
          >{{ $t('internal.today') }} {{ getCurrentTime() }}</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <v-img src="@/assets/backstage_sign.png" height="200px" width="424px"></v-img>

    <v-card-text class="subtitle-1" v-html="$t('internal.home')">
    </v-card-text>
    <!--
    <v-card-actions>
      <v-btn
        text
        color="deep-purple accent-4"
      >
        Read
      </v-btn>
      <v-btn
        text
        color="deep-purple accent-4"
      >
        Bookmark
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-share-variant</v-icon>
      </v-btn>
    </v-card-actions>
    -->
  </v-card>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {
    getCurrentTime() {
      var now = new Date();
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var day = now.getDate();
      if (month.toString().length == 1) {
        month = "0" + month;
      }
      if (day.toString().length == 1) {
        day = "0" + day;
      }
      return day + "." + month + "." + year;
    },
  },
};
</script>