<template>
    <v-container class="mb-5">
        <a href="/events/upcoming" target="_blank"><v-img src="@/assets/metalcrew-live.jpg"
                class="grey darken-4"></v-img></a>
        <v-row class="text-center">
            <v-row justify="center" class="mb-5">
                <v-btn color="orange" href="/events/upcoming" target="_blank">MetalCrew
                    Events</v-btn>
            </v-row>
            <v-col cols="12" v-html="$t('mcLive.fullText')"></v-col>
            <v-col>
                <a href="/events/upcoming" target="_blank"><v-img src="@/assets/metalcrew_live_logo.png"
                        class="grey darken-4" alt="MetalCrew Live Konzertreihe"></v-img></a>
            </v-col></v-row><!--
        <v-row class="text-center">
            <v-col>
                <iframe width="90%" height="550px" style="margin: auto" src="https://www.youtube-nocookie.com/embed/videoseries?list=PL5E1pmOdf84pJp8U0MFhgQJZyefFwXhxN" title="MetalCrew Live YouTube Video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <v-img
                    src="https://www.metalcrew.de/f/attachment/187-25-26-09-band-announcement-jpg/"
                    width="70%"
                    @click="overlay = !overlay"
                    contains
                    class="grey darken-4 mx-auto"
                ></v-img>
                <v-dialog v-model="overlay" fullscreen @keydown.esc="dialog = false">
                    <v-img
                        src="https://www.metalcrew.de/f/attachment/187-25-26-09-band-announcement-jpg/"
                        class="grey darken-4 mx-auto"
                    >
                        <div align="right">
                            <v-spacer></v-spacer>
                            <v-btn color="orange" @click="overlay = false">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </div>
                    </v-img>
                </v-dialog>-->
        <!--
                <v-carousel hide-delimiters :show-arrows="false">
                    <v-carousel-item
                        v-for="(item, i) in items"
                        :key="i"
                        :src="item.src"
                        contain
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                    ></v-carousel-item>
                </v-carousel>
            </v-col>
        </v-row>-->
    </v-container>
</template>

<script>
export default {
    data: () => ({
        overlay: false,
        quote: 'Zitat',
        author: 'Person',
        last: 0,
        quotes: [
            { quote: 'EBERSBERG! Wir waren in der Vergangenheit schon an vielen Flecken, an denen uns kein Schwein kennt. Aber so was wie gestern haben wir noch nicht erlebt…15:00 Uhr, Mittagshitze und volle Bude! Absoluter Wahnsinn was ihr da gestern abgeliefert habt, Hut ab!', author: 'Prior the End (Teilnehmende Band)' },
            { quote: 'Glückwunsch an die MetalCrew. Schöner Tag in Steinhöring.', author: 'Thomas M.' },
            { quote: 'So ein irrwitziger Abend! Horns up and cheer!', author: 'THRASSHOLE (Teilnehmende Band)' },

        ],
        items: [
            {
                src: 'https://www.metalcrew.de/f/attachment/187-25-26-09-band-announcement-jpg/',
            }
        ],

    }),
    methods: {
        toggleFullscreen: function () {
            this.fullscreen = !this.fullscreen;
        },
        getQuote: function () {
            var item = this.quotes[this.random(this.quotes.length)];
            this.quote = item.quote;
            this.author = item.author;
        },
        random: function (area) {
            var rand = Math.floor(Math.random() * area);
            if (rand == this.last) {
                if (rand == 0) { rand = rand + 1; }
                else { rand = rand - 1; }
            }
            this.last = rand;
            return rand;
        }
    },
    mounted() {
        this.getQuote();
    }
}
</script>

<style scoped>
.quote {
    font-family: Kaushan Script;
    font-size: 20px;
}

@media screen and (max-width: 750px) {
    iframe {
        max-width: 100% !important;
        width: auto !important;
        height: auto !important;
    }
}
</style>