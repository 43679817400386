import Vue from 'vue';
import Vuetify from 'vuetify/lib';

//import "@mdi/font/css/materialdesignicons.css"; // yarn add @mdi/font -D

Vue.use(Vuetify, {
  iconfont: "mdi"
});

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        background: '#000',
        primary: '#FFA500',
        secondary: '#9c27b0',
        accent: '#673ab7',
        error: '#ff5722',
        warning: '#ff9800',
        info: '#cddc39',
        success: '#8bc34a'
        }
    },
  }
});




      


