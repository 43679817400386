/* ToDO: Translate  */

import vue from 'vue'
import router from 'vue-router'
import store from './store'
import vueI18n from './translations'

import home from '@/components/view/v-home'
import about from '@/components/view/v-about'
import statute from '@/components/view/v-statute'
import donation from '@/components/view/v-donation'
import extremism from '@/components/view/v-extremism'
import member from '@/components/view/v-member'
import leaders from '@/components/view/v-leaders'
import festivals from '@/components/view/v-festivals'
import crewsade from '@/components/view/v-crewsade'
import mcLive from '@/components/view/v-mc-live'
import openPark from '@/components/view/v-openpark'
import events from '@/components/view/v-events'
import eventsOld from '@/components/view/v-eventsOld'
import eventSupport from '@/components/view/v-support'
import login from '@/components/view/v-login'
import internal from '@/components/view/v-internal'
import calendar from '@/components/view/v-calendar'
import wbbArticles from '@/components/view/wbb-articles'
import wbbPosts from '@/components/view/wbb-posts'
import game from '@/components/view/v-game'
import gameHighscore from '@/components/view/v-game-highscore'
import settings from '@/components/view/v-settings'
import raffles from '@/components/view/v-raffles'

vue.use(router)

const titleBase = "MetalCrew Kultur e.V. Pfaffenhofen a. d. Ilm";

const routes = [
    {
        path: '/', name: 'home', sensitive: true, component: home, meta: {
            title: titleBase,
            metaTags: [
                {
                    name: 'description',
                    //lang: 'de',
                    content: "t.home.description"
                },
                {
                    property: 'og:description',
                    //lang: 'de',
                    content: "t.home.description"
                }
            ], requiresAuth: false
        }
    },
    {
        path: '/statute', name: 'statute', sensitive: true, component: statute, meta: {
            title: 't.statute.title',
            metaTags: [
                {
                    name: 'description',
                    content: 't.statute.description'
                },
                {
                    property: 'og:description',
                    content: 't.statute.description'
                }
            ], requiresAuth: false
        }
    },
    {
        path: '/donation', name: 'donation', sensitive: true, component: donation, meta: {
            title: 't.donation.title',
            metaTags: [
                {
                    name: 'description',
                    content: 't.donation.description'
                },
                {
                    property: 'og:description',
                    content: 't.donation.description'
                }
            ], requiresAuth: false
        }
    },
    {
        path: '/extremism', name: 'extremism', sensitive: true, component: extremism, meta: {
            title: 't.extremism.title',
            metaTags: [
                {
                    name: 'description',
                    content: 't.extremism.description'
                },
                {
                    property: 'og:description',
                    content: 't.extremism.description'
                }
            ], requiresAuth: false
        }
    },
    {
        path: '/about-us', name: 'aboutUs', sensitive: true, component: about, meta: {
            title: 't.aboutUs.title',
            metaTags: [
                {
                    name: 'description',
                    content: 't.aboutUs.description'
                },
                {
                    property: 'og:description',
                    content: 't.aboutUs.description'
                }
            ], requiresAuth: false
        }
    },
    {
        path: '/mitgliedschaft', redirect: { name: 'membership' }
    },
    {
        path: '/mitglied-werden', redirect: { name: 'membership' }
    },
    {
        path: '/werde-mitglied', redirect: { name: 'membership' }
    },
    {
        path: '/membership', name: 'membership', sensitive: true, component: member, meta: {
            title: 't.membership.title',
            metaTags: [
                {
                    name: 'description',
                    content: 't.membership.description'
                },
                {
                    property: 'og:description',
                    content: 't.membership.description'
                }
            ], requiresAuth: false
        }
    },
    {
        path: '/leader', redirect: { name: 'leader' }
    },
    {
        path: '/leaders', redirect: { name: 'leader' }
    },
    {
        path: '/executive', name: 'leader', sensitive: true, component: leaders, meta: {
            title: 't.leader.title',
            metaTags: [
                {
                    name: 'description',
                    content: 't.leader.description'
                },
                {
                    property: 'og:description',
                    content: 't.leader.description'
                }
            ], requiresAuth: false
        }
    },
    {
        path: '/festivals', name: 'festivals', sensitive: true, component: festivals, meta: {
            title: 'Festivals - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 't.festivals.description'
                },
                {
                    property: 'og:description',
                    content: 't.festivals.description'
                }
            ], requiresAuth: false
        }
    },
    {
        path: '/crewsade', redirect: { name: 'crewsade-of-metal' }
    },
    {
        path: '/crewsade-of-metal', redirect: { name: 'crewsade-of-metal' }
    },
    {
        path: '/festivals/crewsade-of-metal', name: 'crewsade-of-metal', sensitive: true, component: crewsade, meta: {
            title: 'Crewsade of Metal - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 't.crewsade.description'
                },
                {
                    property: 'og:description',
                    content: 't.crewsade.description'
                }
            ], requiresAuth: false
        }
    },
    {
        path: '/metalcrew-live', redirect: { name: 'metalcrew-live' }
    },
    {
        path: '/festivals/metalcrew-live', name: 'metalcrew-live', sensitive: true, component: mcLive, meta: {
            title: 'MetalCrew Live - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 't.mcLive.description'
                },
                {
                    property: 'og:description',
                    content: 't.mcLive.description'
                }
            ], requiresAuth: false
        }
    },
    {
        path: '/metal-inferno', redirect: { name: 'metalcrew-live' }
    },
    {
        path: '/inferno', redirect: { name: 'metalcrew-live' }
    },
    {
        path: '/open-park', redirect: { name: 'open-park-pfahofa' }
    },
    {
        path: '/open-park-pfahofa', redirect: { name: 'open-park-pfahofa' }
    },
    {
        path: '/festivals/open-park-pfahofa', name: 'open-park-pfahofa', sensitive: true, component: openPark, meta: {
            title: 'Open Park PFA\'HOFA - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 't.openPark.description'
                },
                {
                    property: 'og:description',
                    content: 't.openPark.description'
                }
            ], requiresAuth: false
        }
    },
    {
        path: '/event', redirect: { name: 'event' }
    },
    {
        path: '/events', redirect: { name: 'event' }
    },
    {
        path: '/events/support', name: 'eventSupport', sensitive: true, component: eventSupport, meta: {
            title: 'Support - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 't.event.descriptionSupport'
                },
                {
                    property: 'og:description',
                    content: 't.event.descriptionSupport'
                }
            ], requiresAuth: false
        }
    },
    {
        path: '/event/kommendes', redirect: { name: 'event' }
    },
    {
        path: '/event/vergangenes', redirect: { name: 'oldEvents' }
    },
    {
        path: '/events/upcoming', name: 'event', sensitive: true, component: events, meta: {
            title: 't.event.titleUpcoming',
            metaTags: [
                {
                    name: 'description',
                    content: 't.event.description'
                },
                {
                    property: 'og:description',
                    content: 't.event.description'
                }
            ], requiresAuth: false
        }
    },
    {
        path: '/events/past', name: 'oldEvents', sensitive: true, component: eventsOld, meta: {
            title: 't.event.titlePast',
            metaTags: [
                {
                    name: 'description',
                    content: 't.event.descriptionOld'
                },
                {
                    property: 'og:description',
                    content: 't.event.descriptionOld'
                }
            ], requiresAuth: false
        }
    },
    {
        path: '/events/raffles', name: 'raffles', sensitive: true, component: raffles, meta: {
            title: 't.event.titleRaffles',
            metaTags: [
                {
                    name: 'description',
                    content: 't.event.descriptionRaffles'
                },
                {
                    property: 'og:description',
                    content: 't.event.descriptionRaffles'
                }
            ], requiresAuth: false
        }
    },
    /*{
        path: 'event/:time', name: 'event', sensitive: true, component: event, meta: {
            title: 'Events - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 'Alle Vereinsausflüge und sonstigen Events werden hier angekündigt. Erlebe etwas mit uns und bleibe auf dem Laufenden!'
                },
                {
                    property: 'og:description',
                    content: 'Alle Vereinsausflüge und sonstigen Events werden hier angekündigt. Erlebe etwas mit uns und bleibe auf dem Laufenden!'
                }
            ], requiresAuth: false
        }
    },*/
    {
        path: '/login', name: 'login', sensitive: true, component: login, meta: {
            title: 't.login.title',
            metaTags: [
                {
                    name: 'description',
                    content: 't.login.description'
                },
                {
                    property: 'og:description',
                    content: 't.login.description'
                }
            ], requiresAuth: false
        }
    },
    /* Not translations needed - Its just internal */
    {
        path: '/internal', name: 'internal', sensitive: true, component: internal, meta: {
            title: 'Backstage - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 'Der interne Backstage Bereich, welcher nur für Mitglieder des MetalCrew Kultur e.V. bestimmt ist.'
                },
                {
                    property: 'og:description',
                    content: 'Der interne Backstage Bereich, welcher nur für Mitglieder des MetalCrew Kultur e.V. bestimmt ist.'
                }
            ], requiresAuth: true
        }
    },
    {
        path: '/news', name: 'news', sensitive: true, component: wbbPosts, meta: {
            title: 'Neuigkeiten - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 'Sehe alle Neuigkeiten der MetalCrew an einem gemeinsamen Platz. Der Verein als auch die Commmunity ist hier vertreten.'
                },
                {
                    property: 'og:description',
                    content: 'Sehe alle Neuigkeiten der MetalCrew an einem gemeinsamen Platz. Der Verein als auch die Commmunity ist hier vertreten.'
                }
            ], requiresAuth: true
        }, props: parseStore
    },
    {
        path: '/association', name: 'association', sensitive: true, component: wbbPosts, meta: {
            title: 'Verein - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 'Sehe alle Neuigkeiten des MetalCrew Kultur e.V. an einem gemeinsamen Platz. Dieser Bereich ist nur für Vereinsmitglieder einsehbar.'
                },
                {
                    property: 'og:description',
                    content: 'Sehe alle Neuigkeiten des MetalCrew Kultur e.V. an einem gemeinsamen Platz. Dieser Bereich ist nur für Vereinsmitglieder einsehbar.'
                }
            ], requiresAuth: true
        }, props: parseStore
    },
    {
        path: '/reviews', name: 'reviews', sensitive: true, component: wbbPosts, meta: {
            title: 'Reviews - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 'Sehe alle Reviews der MetalCrew. Kritische Berichte über Alben, Songs, Künstler und Events.'
                },
                {
                    property: 'og:description',
                    content: 'Sehe alle Reviews der MetalCrew. Kritische Berichte über Alben, Songs, Künstler und Events.'
                }
            ], requiresAuth: true
        }, props: parseStore
    },
    {
        path: '/calendar', name: 'calendar', sensitive: true, component: calendar, meta: {
            title: 'Kalendar - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 'Der Kalender mit allen wichtigen Terminen des MetalCrew Kultur e.V. und der Community.'
                },
                {
                    property: 'og:description',
                    content: 'Der Kalender mit allen wichtigen Terminen des MetalCrew Kultur e.V. und der Community.'
                }
            ], requiresAuth: true
        }, props: parseStore
    },
    {
        path: '/song-musictip', name: 'song-musictip', sensitive: true, component: wbbArticles, meta: {
            title: 'Musiktipps - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 'Sehe alle MetalCrew Musiktipps an einem zentralen Platz ein. Zeit die Boxen aufzudrehen.'
                },
                {
                    property: 'og:description',
                    content: 'Sehe alle MetalCrew Musiktipps an einem zentralen Platz ein. Zeit die Boxen aufzudrehen.'
                }
            ], requiresAuth: true
        }, props: parseStore
    },
    {
        path: '/song-monday', name: 'song-monday', sensitive: true, component: wbbPosts, meta: {
            title: 'Heavy Monday - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 'Sehe alle Songs des Heavy Monday an einem zentralen Platz ein. Zeit die Boxen aufzudrehen.'
                },
                {
                    property: 'og:description',
                    content: 'Sehe alle Songs des Heavy Monday an einem zentralen Platz ein. Zeit die Boxen aufzudrehen.'
                }
            ], requiresAuth: true
        }, props: parseStore
    },
    {
        path: '/song-tuesday', name: 'song-tuesday', sensitive: true, component: wbbPosts, meta: {
            title: 'Extreme Tuesday - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 'Sehe alle Songs des Extreme Tuesdays an einem zentralen Platz ein. Zeit die Boxen aufzudrehen.'
                },
                {
                    property: 'og:description',
                    content: 'Sehe alle Songs des Extreme Tuesdays an einem zentralen Platz ein. Zeit die Boxen aufzudrehen.'
                }
            ], requiresAuth: true
        }, props: parseStore
    },
    {
        path: '/song-wednesday', name: 'song-wednesday', sensitive: true, component: wbbPosts, meta: {
            title: 'Metal Heart Wednesday - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 'Sehe alle Songs des Metal Heart Wednesday an einem zentralen Platz ein. Zeit die Boxen aufzudrehen.'
                },
                {
                    property: 'og:description',
                    content: 'Sehe alle Songs des Metal Heart Wednesday an einem zentralen Platz ein. Zeit die Boxen aufzudrehen.'
                }
            ], requiresAuth: true
        }, props: parseStore
    },
    {
        path: '/song-thursday', name: 'song-thursday', sensitive: true, component: wbbPosts, meta: {
            title: 'Traditional Thursday - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 'Sehe alle Songs des Traditional Thursday an einem zentralen Platz ein. Zeit die Boxen aufzudrehen.'
                },
                {
                    property: 'og:description',
                    content: 'Sehe alle Songs des Traditional Thursday an einem zentralen Platz ein. Zeit die Boxen aufzudrehen.'
                }
            ], requiresAuth: true
        }, props: parseStore
    },
    {
        path: '/song-friday', name: 'song-friday', sensitive: true, component: wbbPosts, meta: {
            title: 'Furious Friday - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 'Sehe alle Songs des Furiosen Freitags an einem zentralen Platz ein. Zeit die Boxen aufzudrehen.'
                },
                {
                    property: 'og:description',
                    content: 'Sehe alle Songs des Furiosen Freitags an einem zentralen Platz ein. Zeit die Boxen aufzudrehen.'
                }
            ], requiresAuth: true
        }, props: parseStore
    },
    {
        path: '/song-sunday', name: 'song-sunday', sensitive: true, component: wbbPosts, meta: {
            title: 'Kotzer der Woche - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 'Sehe alle Songs des Kotzers des Woche an einem zentralen Platz ein. Zeit die Boxen aufzudrehen.'
                },
                {
                    property: 'og:description',
                    content: 'Sehe alle Songs des Kotzers des Woche an einem zentralen Platz ein. Zeit die Boxen aufzudrehen.'
                }
            ], requiresAuth: true
        }, props: parseStore
    },
    {
        path: '/game', name: 'game', sensitive: true, component: game, meta: {
            title: 'Quiz - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 'Du meinst ein Metalhead zu sein? Prüfe dein Wissen bei diesem einzig wahren Metal-Quiz.'
                },
                {
                    property: 'og:description',
                    content: 'Du meinst ein Metalhead zu sein? Prüfe dein Wissen bei diesem einzig wahren Metal-Quiz.'
                }
            ], requiresAuth: true
        }
    },
    {
        path: '/game-highscore', name: 'game-highscore', sensitive: true, component: gameHighscore, meta: {
            title: 'Quiz Highscore - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 'Sehe die Bestenliste ein und erkenne alle Mitglieder, mit wirklich starken Metal-Wissen.'
                },
                {
                    property: 'og:description',
                    content: 'Sehe die Bestenliste ein und erkenne alle Mitglieder, mit wirklich starken Metal-Wissen.'
                }
            ], requiresAuth: true
        }
    },
    {
        path: '/settings', name: 'setting', sensitive: true, component: settings, meta: {
            title: 'Einstellungen - ' + titleBase,
            metaTags: [
                {
                    name: 'description',
                    content: 'Hier kannst du die Einstellungen der MetalCrew App ändern. Auch die Benachrichtungen.'
                },
                {
                    property: 'og:description',
                    content: 'Hier kannst du die Einstellungen der MetalCrew App ändern. Auch die Benachrichtungen.'
                }
            ], requiresAuth: true
        }
    },
    { // catch all use case
        path: '*',
        //redirect: '/', // Not possible with prerender.io
        component: home, meta: {
            title: titleBase,
            metaTags: [
                {
                    name: 'description',
                    //lang: 'de',
                    content: "t.home.description"
                },
                {
                    property: 'og:description',
                    //lang: 'de',
                    content: "t.home.description"
                },
                {
                    name: 'prerender-status-code',
                    content: '404'
                    //content: '301' // Moved Permanently
                }/*,
                {
                    property: 'prerender-header',
                    content: 'Location: https://www.metalcrew.de/'
                }*/
            ], requiresAuth: false
        }
    }
];

function parseStore(route) {
    const path = route.name
    var out = []
    if (path == "association") {
        out = store.state.association
    }
    else if (path == "news") {
        out = store.state.news
    }
    else if (path == "reviews") {
        out = store.state.reviews
    }
    else if (path == "song-musictip") {
        out = store.state.songMusictip
    }
    else if (path == "song-monday") {
        out = store.state.songMonday
    }
    else if (path == "song-tuesday") {
        out = store.state.songTuesday
    }
    else if (path == "song-wednesday") {
        out = store.state.songWednesday
    }
    else if (path == "song-thursday") {
        out = store.state.songThursday
    }
    else if (path == "song-friday") {
        out = store.state.songFriday
    }
    else if (path == "song-sunday") {
        out = store.state.songSunday
    }
    else if (path == "calendar") {
        out = store.state.calendar
    }
    return {
        store: out
    }
}

const vueRouter = new router({
    mode: 'history',
    hash: false,
    routes: routes,
    strict: true
})

//import VueI18n from 'vue-i18n'

vueRouter.beforeEach((to, from, next) => {
    // Language stuff
  /*  if (!to.path.startsWith("/de/") && !to.path.startsWith("/en/")) {
        next("/de" + to.path)
    }*/

    let language;// = to.params.lang;
    let win = window.location.href;
    
    //if (to.path.startsWith("/de/")) {
    if(win.startsWith("https://www.metalcrew.de")) {
        language = 'de';
    }
    //else if (to.path.startsWith("/en/")) {
    else if(win.startsWith("https://www.metalcrew.net")) {
        language = 'en';
    }
    else {
        language = 'de';
    }

    // use the language from the routing param or default language
 //   if (!language) {
   //     language = 'de';
   // }
    //language = 'de';
    // set the current language for vuex-i18n. note that translation data
    // for the language might need to be loaded first
    //vue.i18n.set(language);
    //console.log(VueI18n);
    vueI18n.locale = language;
    //vueI18n.locale = 'en'; // Develop switch !!

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        if (nearestWithTitle.meta.title.startsWith("t.")) {
            document.title = vueI18n.t(nearestWithTitle.meta.title.slice(2));
        }
        else document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        if (previousNearestWithMeta.meta.title.startsWith("t.")) {
            document.title = vueI18n.t(previousNearestWithMeta.meta.title.slice(2));
        }
        else document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (nearestWithMeta) {
        // Turn the meta tag definitions into actual elements in the head.
        nearestWithMeta.meta.metaTags.map(tagDef => {
            const tag = document.createElement('meta');

            Object.keys(tagDef).forEach(key => {
                if (key == "content" && tagDef[key].startsWith("t.")) {
                    tag.setAttribute(key, vueI18n.t(tagDef[key].slice(2)));
                }
                else tag.setAttribute(key, tagDef[key]);
            });

            // We use this to track which meta tags we create so we don't interfere with other ones. #Debug
            //tag.setAttribute('data-vue-router-controlled', '');

            return tag;
        })
            // Add the meta tags to the document head.
            .forEach(tag => document.head.appendChild(tag));
    }

    if (to.meta.requiresAuth) {
        const authUser = store.getters.isAuthenticated
        if (authUser) {
            next()
        } else {
            next({ name: 'login' })
        }
    }
    else next()
})

export default vueRouter